<template>
    <div class="version-more">
        <md-menu md-direction="bottom left" md-size="1" :md-align-trigger="true">
            <md-button md-menu-trigger aria-label="More Pages" alt="more">
                Version
            </md-button>
            <md-menu-content>
                <md-menu-item v-for="(version,ix) in versions" :key="ix">
                    <router-link :to="`/admin/versions/detail/${version._id}`">{{version.version}}</router-link>
                </md-menu-item>
            </md-menu-content>
        </md-menu>

    </div>
</template>
<script>
export default {
    data() {
        return {
            versions: []
        };
    },
    methods: {
        async loadVersions() {
            let result = await this.$store.dispatch("crud/get", {
                api: "versions"
            });
            this.versions = result;
        },
        jump(version) {
            this.$router.push(`/admin/versions/detail/${version._id}`);
        }
    },
    created() {
        this.loadVersions();
    }
};
</script>
<style lang="less">
.version-more {
    .md-button {
        padding: 0;
        min-width: inherit;
    }
}
</style>


