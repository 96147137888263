<template>
    <div class="shop-header">
        <router-link class="shop-header__warm" to="/account/vendor/location" v-if="user && user.role == 'vendor' && user.locations.length == 0">Please set service locations</router-link>
        <a class="shop-header__warm" style="cursor:pointer;" @click="handleAddCard" v-if="showAddCard">Please add your card first</a>
        <router-link class="shop-header__warm" to="/account/connect" v-if="showConnect">Configure payment gateway, products will not be visible on the platform until you are connected</router-link>

        <md-toolbar>
            <div class="md-toolbar-container">
                <div class="group logo-group" v-if="!vendorMode">
                    <router-link aria-label="logo" class="logo" to="/">
                        <img alt="logo" src="/static/logo.png" />
                    </router-link>
                </div>

                <div class="group admin-mega-menu-group" v-if="isAdmin">
                    <admin-mega-menu></admin-mega-menu>
                </div>

                <div class="group select-group" v-if="!isAdmin">
                    <location-select v-if="!isAdmin"></location-select>
                    <default-date-select v-if="!isAdmin"></default-date-select>
                </div>

                <div class="group search-group" v-if="!isAdmin">
                    <shop-header-search v-if="showSearchBar"></shop-header-search>
                    <md-button @click="showSearchBar = false" alt="close" aria-label="Close search bar" class="hide-xm md-icon-button" v-if="showSearchBar">
                        <md-icon>close</md-icon>
                    </md-button>
                    <md-button @click="showSearchBar = true" alt="search" aria-label="Search" class="md-icon-button" v-if="!showSearchBar">
                        <md-icon>search</md-icon>
                    </md-button>
                </div>

                <div class="search-group-after hide-sm" style="flex: 1"></div>

                <div class="group" style="flex: 1; justify-content: flex-end">
                    <div :class="{ 'hide-sm': showSearchBar }" style="font-weight: 500; white-space: nowrap" v-if="showTelephone">{{ telephone }}</div>

                    <div :class="{ 'hide-sm': showSearchBar }" class="group cart-group" v-if="!isAdmin">
                        <md-button :disabled="totalCount === 0" @click="toggleCart()" alt="cart" aria-label="cart">
                            <md-icon>shopping_cart</md-icon>
                            <span class="hide-sm">Cart ({{ totalCount }}) - {{ totalAmount | currency }}</span>
                        </md-button>
                    </div>

                    <div class="cart-group-after hide-xm" style="flex: 1"></div>

                    <div :class="{ 'hide-sm': showSearchBar }" class="group more-group" v-if="!isAdmin && !vendorMode">
                        <page-more></page-more>
                    </div>

                    <div class="group user-group" :class="{ 'hide-sm': showSearchBar }">
                        <router-link class="avatar-container" tag="md-button" to="/admin" v-if="user">
                            <md-avatar v-if="user">
                                <img :src="head | googleimage" alt="head" height="75" width="75" />
                            </md-avatar>
                            <span>{{ user.name }}</span>
                        </router-link>
                        <md-button @click="login" alt="login" aria-label="login" v-if="!user">
                            <md-icon class="login-icon">fingerprint</md-icon>
                            <span class="login-text">Log In</span>
                        </md-button>
                        <md-menu md-direction="bottom left" md-offset-y="99" md-size="4" v-if="user" class="more-menu">
                            <md-button alt="more" aria-label="More menu items" class="md-icon-button more" md-menu-trigger>
                                <!-- <md-icon class="three-dots">more_vert</md-icon> -->
                                <md-icon class="hamburger">dehaze</md-icon>
                            </md-button>
                            <md-menu-content class="shopheader-top-menu">
                                <template v-for="menu in menuItems">
                                    <template v-if="menu.alwayShow">
                                        <template v-if="(!menu.role || hasRole(menu.role)) && (!menu.asRole || asRole(menu.asRole))">
                                            <router-link :key="menu.name" :to="menu.url" tag="md-menu-item" v-if="!vendorMode || asRole('user')">
                                                <md-icon>{{ menu.icon }}</md-icon>
                                                <span>{{ menu.name }}</span>
                                            </router-link>
                                        </template>
                                    </template>
                                    <template v-for="(item, iix) in menu.children">
                                        <template v-if="(!item.role || hasRole(item.role)) && (!item.asRole || asRole(item.asRole))">
                                            <router-link :key="item.name" :to="item.url" tag="md-menu-item" v-if="!vendorMode || asRole('user')">
                                                <md-icon>{{ item.icon }}</md-icon>
                                                <span>{{ item.name }}</span>
                                            </router-link>
                                        </template>
                                    </template>
                                </template>
                                <md-menu-item @click="logout" v-if="hasRole('user')">
                                    <md-icon>vpn_key</md-icon>
                                    <span>Logout</span>
                                </md-menu-item>
                            </md-menu-content>
                        </md-menu>
                    </div>
                </div>
            </div>
        </md-toolbar>
    </div>
</template>
<script>
import ShopHeaderSearch from "./ShopHeaderSearch";
import LocationSelect from "@/components/Location/LocationSelect";
import DefaultDateSelect from "@/components/DefaultDateSelect";
import AdminMegaMenu from "@/components/Menu/AdminMegaMenu";
import PageMore from "@/components/Page/PageMore";
import { mapGetters, mapState } from "vuex";
import moment from "moment-timezone";
import md5 from "md5";

import { menuItems } from "@/config";
export default {
    components: {
        ShopHeaderSearch,
        LocationSelect,
        DefaultDateSelect,
        PageMore,
        AdminMegaMenu
    },
    data() {
        return {
            isInit: false,
            showSearchBar: true,
            telephone: "",
            showTelephone: false
        };
    },
    computed: {
        ...mapGetters({
            menuItems: "menuItems",
            user: "user/user",
            head: "user/head",
            hasRole: "user/hasRole",
            asRole: "user/asRole",
            cartItems: "cart/items",
            totalCount: "cart/totalCount",
            totalAmount: "cart/totalAmount",
            vendorMode: "vendorMode",
            paymentSetting: "setting/payment"
        }),
        isAdmin() {
            return /^\/(admin|account)/.test(this.$route.path) && !/account\/signup|forgot-password|login/.test(this.$route.path);
        },
        hasGateWay() {
            return this.paymentSetting.value && (this.paymentSetting.value.use_stripe || this.paymentSetting.value.use_tilled);
        },
        noMasterVendorId() {
            return this.user && this.user.role == "vendor" && !this.user.master_vendor_id;
        },
        showAddCard() {
            if (this.hasGateWay && this.noMasterVendorId) {
                if (this.paymentSetting.value.use_stripe) {
                    return !this.user.stripeId;
                } else if (this.paymentSetting.value.use_tilled) {
                    return !this.user.tilledId;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        showConnect() {
            if (this.hasGateWay && this.noMasterVendorId) {
                if (this.paymentSetting.value.use_stripe) {
                    return !this.user.stripeConnectId;
                } else if (this.paymentSetting.value.use_tilled) {
                    return !this.user.tilledConnectId || this.user.tilledOnBoardingUrl;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }
    },

    methods: {
        handleAddCard() {
            if (this.paymentSetting.value.use_tilled) {
                this.$router.push("/admin/EzTimePay/cards/new");
            } else if (this.paymentSetting.value.use_stripe) {
                this.$router.push("/admin/stripe/cards/new");
            }
        },
        toggleSidebar() {
            this.eventHub.$emit("toggleSidebar", "");
        },
        toggleCart() {
            this.$router.push("/cart");
            // this.eventHub.$emit("toggleCart", "");
        },
        login() {
            this.$router.push("/account/login");
        },
        logout(e) {
            e.preventDefault();
            this.$store.dispatch("user/logout");
        },
        async loadTelephone() {
            let result = await this.$store.dispatch("crud/get", {
                api: "settings/telephone"
            });
            if (result && result.value) {
                this.telephone = result.value.telephone;
                this.showTelephone = result.value.show;
            }
            if (this.vendorMode) {
                this.showTelephone = false;
            }
        }
    },
    mounted() {
        if (document.documentElement.clientWidth < 600) {
            this.showSearchBar = false;
        }
        this.loadTelephone();
    }
};
</script>
<style lang="less">
.shop-header {
    border-bottom: 1px solid #0a0461;
    position: relative;
    z-index: 10;

    .shop-header__warm {
        font-size: 18px;
        text-align: center;
        display: block;
        padding: 8px 16px;
        overflow: hidden;
        border-bottom: 1px solid #fefefe;
        background-color: #ffa401;
        color: white;
    }

    .md-toolbar {
        color: white;
        background: white;
        min-height: inherit;
        background-color: #0a0461;
        .md-toolbar-container {
            flex-wrap: wrap;
            height: auto;
            // justify-content: space-between;
        }
    }

    .hide-xm {
        display: none;
    }
    .group {
        display: flex;
        align-items: center;
        .md-button[disabled] {
            color: rgba(255, 255, 255, 1);
        }
    }
    .search-group {
        flex: 1;
        padding: 5px 0;
    }

    .logo {
        margin-right: 30px;
        img {
            width: 150px;
            max-width: inherit;
        }
    }
    .admin-megamenu {
        box-shadow: none;
        // flex: 1;
    }

    .more-menu {
        display: none;
    }

    .md-icon-button.more {
        .hamburger {
            display: none;
        }
    }
}
@media (max-width: 600px) {
    .shop-header {
        .md-toolbar {
            padding: 0;
        }
        .admin-mega-menu-group {
            overflow: scroll;
            display: none;
        }
        .hide-xm {
            display: inherit;
        }
        .hide-sm {
            display: none;
        }
        .md-toolbar-container {
            flex-wrap: wrap;
            height: auto;
        }
        .logo-group {
            height: 50px;
            margin-left: 10px;
        }
        .search-group {
            flex: inherit;
        }
        .select-group {
            width: 100%;
            flex-wrap: wrap;
        }
        .more-group {
            .md-button {
                width: 50px;
            }
        }
        .md-button {
            width: 25px;
            padding: 0;
            min-width: inherit;
        }
        .cart-detail {
            display: none;
        }
        .avatar-container {
            width: 40px;
        }
        .clearSearchBarField {
            display: none;
        }
        .login-text {
            display: none;
        }
        .searchBarMain {
            width: 300px;
        }

        .more-menu {
            display: inherit;
        }

        .md-icon-button.more {
            margin-right: 10px;
            .hamburger {
                display: inherit;
            }
        }
    }
}

.vendorMode {
    .shop-header {
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
        .md-toolbar {
            color: #38f;
            // color: rgba(93, 93, 93, 0.8);
        }
    }
}
</style>
