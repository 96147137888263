<template>
    <div class="edit-field" v-if="model">
        <template v-if="/\./.test(f.data)">
            <edit-field :f="subField" :model="subModel" :rootModel="model"></edit-field>
        </template>

        <template v-if="!/\./.test(f.data)">
            <!--boolean-->
            <md-switch v-if="ftype === 'boolean'" v-model="model[f.data]">{{ f.heading | titlecase }}</md-switch>

            <!--text-->
            <md-input-container :class="{ 'md-input-invalid': errors.has(f.data) }" v-if="/^link|text$/.test(ftype)">
                <label>{{ f.heading }}</label>
                <md-input :data-vv-as="f.heading" :data-vv-name="f.data" :data-vv-rules="f.validate" :placeholder="f.placeholder || f.heading" v-model="model[f.data]" v-validate></md-input>
                <span class="md-error" v-show="errors.has(f.data)">{{ errors.first(f.data) }}</span>
            </md-input-container>

            <!-- date -->
            <md-input-container :class="{ 'md-input-invalid': errors.has(f.data) }" v-if="ftype == 'date'">
                <label>{{ f.heading }}</label>
                <md-input
                    :data-vv-as="f.heading"
                    :data-vv-name="f.data"
                    :data-vv-rules="f.validate"
                    :placeholder="f.placeholder || f.heading"
                    v-model="model[f.data]"
                    type="date"
                    v-validate
                ></md-input>
                <span class="md-error" v-show="errors.has(f.data)">{{ errors.first(f.data) }}</span>
            </md-input-container>

            <!--password-->
            <md-input-container :class="{ 'md-input-invalid': errors.has(f.data) }" v-if="ftype == 'password'">
                <label>{{ f.heading }}</label>
                <md-input
                    :data-vv-as="f.heading"
                    :data-vv-name="f.data"
                    :data-vv-rules="f.validate"
                    :name="f.data"
                    :placeholder="f.placeholder || f.heading"
                    :ref="f.data"
                    type="password"
                    v-model="model[f.data]"
                    v-validate
                >
                </md-input>
                <span class="md-error" v-show="errors.has(f.data)">{{ errors.first(f.data) }}</span>
            </md-input-container>

            <!--password-confirmed-->
            <md-input-container :class="{ 'md-input-invalid': errors.has('confirm ' + f.data) }" v-if="ftype == 'password' && f.confirm">
                <label>{{ "Confirm " + f.heading }}</label>
                <md-input
                    :data-vv-as="f.data"
                    :data-vv-name="'confirm ' + f.data"
                    :data-vv-rules="f.validate + '|confirmed:' + f.data"
                    :data-vv-delay="100"
                    :name="'confirm ' + f.data"
                    :placeholder="'Confirm ' + f.heading"
                    ref="password"
                    type="password"
                    v-model="model['confirm ' + f.data]"
                    v-validate
                ></md-input>
                <span class="md-error" v-show="errors.has('confirm ' + f.data)">{{ errors.first("confirm " + f.data) }}</span>
            </md-input-container>

            <!--number-->
            <md-input-container :class="{ 'md-input-invalid': errors.has(f.data) }" v-if="ftype == 'number'">
                <label>{{ f.heading }}</label>
                <md-input
                    :data-vv-as="f.heading"
                    :data-vv-name="f.data"
                    :data-vv-rules="f.validate"
                    :placeholder="f.placeholder || f.heading"
                    type="number"
                    v-if="ftype == 'number'"
                    v-model="model[f.data]"
                    step="any"
                    v-validate
                ></md-input>
                <span class="md-error" v-show="errors.has(f.data)">{{ errors.first(f.data) }}</span>
            </md-input-container>

            <!--readonly-->
            <md-input-container :class="{ 'md-input-invalid': errors.has(f.data) }" v-if="ftype == 'readonly'">
                <label>{{ f.heading }}</label>
                <md-input :data-vv-as="f.heading" :data-vv-name="f.data" :data-vv-rules="f.validate" :readonly="true" v-model="model[f.data]" v-validate></md-input>
                <span class="md-error" v-show="errors.has(f.data)">{{ errors.first(f.data) }}</span>
            </md-input-container>

            <!--hidden-->
            <md-input-container :class="{ 'md-input-invalid': errors.has(f.data) }" v-if="ftype == 'hidden'" v-show="false">
                <label>{{ f.heading }}</label>
                <md-input :name="f.data" :placeholder="f.placeholder || f.heading" type="hidden" v-model="model[f.data]" v-show="false"> </md-input>
                <span class="md-error" v-show="errors.has(f.data)">{{ errors.first(f.data) }}</span>
            </md-input-container>

            <!--textarea-->
            <md-input-container :class="{ 'md-input-invalid': errors.has(f.data) }" v-if="ftype == 'textarea'">
                <label>{{ f.heading }}</label>
                <md-textarea :data-vv-as="f.heading" :data-vv-name="f.data" :data-vv-rules="f.validate" rows="10" v-model="model[f.data]" v-validate></md-textarea>
                <span class="md-error" v-show="errors.has(f.data)">{{ errors.first(f.data) }}</span>
            </md-input-container>

            <!--select-->
            <md-input-container :class="{ 'md-input-invalid': errors.has(f.data) }" v-if="ftype == 'select'">
                <label>{{ f.heading }}</label>
                <md-select :data-vv-as="f.heading" :data-vv-name="f.data" :data-vv-rules="f.validate" v-model="model[f.data]" v-validate>
                    <md-option :key="oix" :value="typeof o == 'object' ? o.value : o" class="edit-fields-option" v-for="(o, oix) in f.options">{{ typeof o == "object" ? o.text : o }} </md-option>
                </md-select>
                <span class="md-error" v-show="errors.has(f.data)">{{ errors.first(f.data) }}</span>
            </md-input-container>

            <!--autocomplete-->
            <md-input-container :class="{ 'md-input-invalid': errors.has(f.data) }" v-if="ftype == 'autocomplete'">
                <label>{{ f.heading }}</label>
                <autocomplete
                    :apiGet="f.apiGet"
                    :apiList="f.apiList"
                    :data-vv-as="f.heading"
                    :data-vv-name="f.data"
                    :data-vv-rules="f.validate"
                    :fieldName="f.fieldName"
                    :options="f.options"
                    v-model="model[f.data]"
                    v-validate
                ></autocomplete>
                <span class="md-error" v-show="errors.has(f.data)">{{ errors.first(f.data) }}</span>
            </md-input-container>

            <!--checkbox-->
            <md-input-container :class="{ 'md-input-invalid': errors.has(f.data) }" v-if="ftype == 'checkbox'">
                <md-checkbox :data-vv-as="f.heading" :data-vv-name="f.data" :data-vv-rules="f.validate" @click.native="go(f.to)" v-if="ftype == 'checkbox'" v-model="model[f.data]" v-validate>{{
                    f.heading
                }}</md-checkbox>
                <span class="md-error" v-if="ftype == 'checkbox'" v-show="errors.has(f.data)">{{ errors.first(f.data) }}</span>
            </md-input-container>

            <!--checkboxs-->
            <md-input-container :class="{ 'md-input-invalid': errors.has(f.data) }" v-if="ftype == 'checkboxs'">
                <label>{{ f.heading }}</label>
                <div class="checkboxs">
                    <template v-for="(o, oix) in f.options">
                        <md-checkbox :key="oix" v-if="model[f.data]" v-model="model[f.data][o.data]">{{ o.heading }} </md-checkbox>
                    </template>
                </div>
                <md-input type="hidden"></md-input>
            </md-input-container>

            <!--datetime-->
            <md-input-container :class="{ 'md-input-invalid': errors.has(f.data) }" v-if="ftype == 'datetime'">
                <label>{{ f.heading }}</label>
                <date-time-picker :data-vv-as="f.heading" :data-vv-name="f.data" :data-vv-rules="f.validate" v-model="model[f.data]" v-validate valueFormat="moment"></date-time-picker>
                <span class="md-error" v-show="errors.has(f.data)">{{ errors.first(f.data) }}</span>
            </md-input-container>

            <!--category-->
            <md-input-container :class="{ 'md-input-invalid': errors.has(f.data) }" v-if="ftype == 'category'">
                <label for>{{ f.heading }}</label>
                <category-select :data-vv-as="f.heading" :data-vv-name="f.data" :data-vv-rules="f.validate" v-model="model[f.data]" v-validate></category-select>
                <span class="md-error" v-show="errors.has(f.data)">{{ errors.first(f.data) }}</span>
            </md-input-container>

            <!--editor-->
            <md-input-container :class="{ 'md-input-invalid': errors.has(f.data) }" v-if="ftype == 'editor'">
                <label>{{ f.heading }}</label>
                <email-editor :data-vv-as="f.heading" :data-vv-name="f.data" :data-vv-rules="f.validate" :wordList="f.wordList || []" v-model="model[f.data]" v-validate></email-editor>
                <md-input type="hidden"></md-input>
                <span class="md-error" v-show="errors.has(f.data)">{{ errors.first(f.data) }}</span>
            </md-input-container>

            <!--image-->
            <md-input-container :class="{ 'md-input-invalid': errors.has(f.data) }" v-if="ftype == 'image'">
                <label>{{ f.heading }}</label>
                <image-input :folder="f.folder" v-model="model[f.data]"></image-input>
                <span class="md-error" v-show="errors.has(f.data)">{{ errors.first(f.data) }}</span>
            </md-input-container>
        </template>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import DateTimePicker from "@/components/DateTimePicker";
import EmailEditor from "@/components/EmailEditor";
import ImageInput from "@/components/ImageInput";
import CategorySelect from "@/components/Category/CategorySelect";
import Autocomplete from "@/components/Autocomplete";
export default {
    name: "editField",
    props: {
        f: Object,
        model: Object,
        rootModel: Object
    },
    components: {
        DateTimePicker,
        EmailEditor,
        ImageInput,
        CategorySelect,
        Autocomplete
    },
    // inject: ["$validator"],
    computed: {
        ...mapGetters({
            parseLink: "parseLink"
        }),
        subModel() {
            let key = this.f.data.split(".")[0];
            this.model[key] = this.model[key] || {};
            return this.model[this.f.data.split(".")[0]];
        },
        subField() {
            return { ...this.f, data: this.f.data.replace(/[^.]*./, "") };
        },
        ftype() {
            if (typeof this.f.type == "function") {
                return this.f.type(this.rootModel || this.model);
            }
            return this.f.type;
        }
    },
    methods: {
        go(to) {
            if (to) {
                this.$router.push(to);
            }
        }
    }
};
</script>
<style lang="less"></style>
