import axios from "axios";
import { userRoles, demo } from "@/config";
import router from "@/router";

const defaultHeadImgCache = {};
const genDefaultHead = function(name) {
    var canvas = document.createElement("canvas");
    canvas.width = "300";
    canvas.height = "300";
    var colours = [
        "#1abc9c",
        "#2ecc71",
        "#3498db",
        "#9b59b6",
        "#34495e",
        "#16a085",
        "#27ae60",
        "#2980b9",
        "#8e44ad",
        "#2c3e50",
        "#f1c40f",
        "#e67e22",
        "#e74c3c",
        "#95a5a6",
        "#f39c12",
        "#d35400",
        "#c0392b",
        "#bdc3c7",
        "#7f8c8d"
    ];
    var nameSplit = name.split(" ");
    var initials = nameSplit[0].charAt(0).toUpperCase();
    nameSplit[1] && (initials = initials + nameSplit[1].charAt(0).toUpperCase());
    var charIndex = initials.charCodeAt(0) - 65;
    var colourIndex = charIndex % 19;
    var context = canvas.getContext("2d");
    var canvasWidth = canvas.width;
    var canvasHeight = canvas.height;
    var canvasCssWidth = canvasWidth;
    var canvasCssHeight = canvasHeight;

    context.fillStyle = colours[colourIndex];
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.font = "128px Arial";
    context.textAlign = "center";
    context.fillStyle = "#FFF";
    context.fillText(initials, canvasCssWidth / 2, canvasCssHeight / 1.5);

    return canvas.toDataURL();
};

const state = {
    user: JSON.parse(localStorage.getItem("authUser")),
    vendorSignupToken: ""
};

// getters
const getters = {
    user(state, getters) {
        return state.user;
    },
    hasRole: state => role => {
        return state.user ? userRoles.indexOf(state.user.role) >= userRoles.indexOf(role) : false;
    },
    asRole: state => role => {
        return state.user ? state.user.role == role : false;
    },
    hasPermission: (state, getters) => permission => {
        console.info(123123, permission);
        if (!state.user) {
            return false;
        }
        if (!getters.asRole("staff")) {
            return true;
        }
        if (state.user.permissions && state.user.permissions[permission]) {
            return true;
        }

        return false;
    },

    head(state, getters) {
        return state.user && (state.user.avatar || getters.defaultHead);
    },
    defaultHead(state, getters) {
        let name = state.user && state.user.name;
        if (!name) {
            return "";
        }
        if (defaultHeadImgCache[name]) {
            return defaultHeadImgCache[name];
        }
        let head = genDefaultHead(name);
        defaultHeadImgCache[name] = head;
        return head;
    },
    getDefaultHead: () => name => {
        return genDefaultHead(name);
    },
    vendorSignupToken(state) {
        return state.vendorSignupToken;
    }
};

// actions
const actions = {
    // this is not used
    // async updateProfile({ dispatch, commit, state, rootState }, payload) {
    //     const result = await dispatch("crud/put", {
    //         api: `users/${payload._id}`,
    //         data: {
    //             name: payload.name,
    //             email: payload.email,
    //             phone: payload.phone,
    //             avatar: payload.avatar,
    //             vendor: payload.vendor
    //         }
    //     });
    //     if (result) {
    //         result.token = state.user.token;
    //         commit("setUser", data);
    //         commit("setMessage", "Successfully updated", { root: true });
    //         return data;
    //     }
    // },
    async refreshProfile({ dispatch, commit, state }, payload) {
        const result = await dispatch(
            "crud/get",
            {
                api: "users/me"
            },
            { root: true }
        );
        console.info("refreshProfile");
        result.token = state.user.token;
        commit("setUser", result);
        return result;
    },
    logout({ dispatch, commit }) {
        router.push("/");
        setTimeout(() => {
            commit("setUser", null);
        }, 0);
    }
};

// mutations
const mutations = {
    setUser(state, payload) {
        if (payload) {
            window.localStorage.setItem("authUser", JSON.stringify(payload));
        } else {
            window.localStorage.removeItem("authUser");
        }
        state.user = payload;
    },
    setVendorSignupToken(state, payload) {
        state.vendorSignupToken = payload;
    },
    setRole(state, payload) {
        if (state.user) {
            state.user.role = payload;
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
