<template>
    <div class="image-input">
        <md-file @selected="imageSelected($event)" accept="image/*" v-model="innerValue"></md-file>
        <input @change="photoSelected($event)" ref="image_camera" type="file" id="image" accept="image/*" capture="camera" class="hide" />
        <md-button @click="handleTakePhoto" class="md-icon-button image-input__camera">
            <md-icon>camera</md-icon>
        </md-button>
        <md-button @click="clear" class="md-icon-button">
            <md-icon>clear</md-icon>
        </md-button>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    props: ["value", "folder"],
    data() {
        return {
            initialValue: "",
            innerValue: ""
        };
    },
    watch: {
        value: {
            immediate: true,
            handler() {
                this.innerValue = this.value;
            }
        }
    },
    computed: {
        ...mapGetters({
            supportWebp: "supportWebp"
        })
    },
    methods: {
        handleTakePhoto() {
            this.$refs.image_camera.click();
        },
        getPhotoName(files) {
            if (!files || files.length === 0) {
                return target.value.split("\\").pop();
            }

            if (files.length === 1) {
                return files[0].name;
            }

            return null;
        },
        photoSelected({ target, dataTransfer }) {
            const files = target.files || dataTransfer.files;
            const fileName = this.getPhotoName(files);
            this.innerValue = fileName;
            this.imageSelected(files || target.value);
        },
        zipImage(file, type) {
            return new Promise(resolve => {
                let reader = new FileReader();
                let img = new Image();
                let canvas = document.createElement("canvas");
                let context = canvas.getContext("2d");
                let extMatch = (type || file.type).match(/image\/(.*)/);
                let ext = "";
                if (extMatch && type && file.type != type) {
                    ext = extMatch[1] || "";
                    ext = { jpeg: "jpg" }[ext] || ext;
                    ext = `.${ext}`;
                }
                reader.readAsDataURL(file);
                reader.onload = function(evt) {
                    if (evt.target.readyState == FileReader.DONE) {
                        img.src = evt.target.result;
                        img.onload = async function() {
                            canvas.width = img.width;
                            canvas.height = img.height;
                            context.drawImage(img, 0, 0, img.width, img.height);
                            let base64data = canvas.toDataURL(type || file.type, 0.7);
                            let res = await fetch(base64data);
                            let buf = await res.arrayBuffer();
                            let resultfile = await new File([buf], file.name + ext, { type: type || file.type });
                            resolve(resultfile);
                        };
                    }
                };
            });
        },
        async imageSelected(fileList) {
            const formData = new FormData();
            const fixedFileList = [].concat(
                ...(await Promise.all(
                    [].map.call(fileList, async file => {
                        let result = [];
                        let originZipFile = await this.zipImage(file);
                        result.push(originZipFile);
                        if (this.supportWebp) {
                            let webpZipFile = await this.zipImage(file, "image/webp");
                            result.push(webpZipFile);
                        }
                        return result;
                    })
                ))
            );
            fixedFileList.map(file => {
                formData.append("photos", file, file.name);
            });
            formData.append("folder", this.folder || "default");

            Array.from(Array(fileList.length).keys()).map(x => {});
            // this.$emit("input", "Uploading...");
            try {
                let result = await this.$store.dispatch("crud/post", {
                    api: "media",
                    data: formData
                });
                if (result && result.length) {
                    this.$emit("input", result[0].path);
                    this.$store.commit("setMessage", "Upload Success");
                }
                this.$store.commit("setMessage", "Upload Success");
            } catch (error) {
                this.$emit("input", this.initialValue);
            }
        },
        clear() {
            this.innerValue = "";
            this.$emit("input", "");
        }
    },
    mounted() {}
};
</script>
<style lang="less">
.image-input {
    width: 100%;
    flex: 1;
    display: flex;
}
.md-input-container input.hide {
    width: 0;
    height: 0;
    flex: unset;
    visibility: hidden;
}
.md-table .md-table-cell .md-button.image-input__camera {
    position: absolute;
    right: 56px;
    z-index: 1;
    padding: 0;
    margin: 0;
    cursor: pointer;
    .md-icon {
        position: initial;
        top: unset;
        font-size: 24px;
        width: 24px;
        height: 24px;
        vertical-align: middle;
    }
}
</style>
