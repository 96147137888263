<template>
    <div class="side-menu">
        <md-list>
            <md-list-item :key="i1" class="c1" ref="menuitem" v-for="(c1, i1) in fgCategories">
                <router-link :to="`/category/${c1.slug}?level=${c1.level}`" @click.native="selected(c1)" v-if="link">{{ c1.name }}</router-link>
                <a :class="{ selected: c1 == selectedCategory }" @click.prevent="selected(c1)" href="javascript:void(0)" v-if="!link">{{ c1.name }}</a>
                <md-list-expand v-if="c1.children && c1.children.length">
                    <md-list-item :key="i2" class="c2" md-expand-multiple ref="menuitem2" v-for="(c2, i2) in c1.children">
                        <router-link :to="`/category/${c2.slug}?level=${c2.level}`" @click.native="selected(c2)" v-if="link">{{ c2.name }}</router-link>
                        <a :class="{ selected: c2 == selectedCategory }" @click.prevent="selected(c2)" href="javascript:void(0)" v-if="!link">{{ c2.name }}</a>
                        <md-list-expand v-if="c2.children && c2.children.length">
                            <md-list-item :key="i3" class="c3" ref="menuitem3" v-for="(c3, i3) in c2.children">
                                <router-link :to="`/category/${c3.slug}?level=${c3.level}`" @click.native="selected(c3)" v-if="link">{{ c3.name }}</router-link>
                                <a :class="{ selected: c3 == selectedCategory }" @click.prevent="selected(c3)" href="javascript:void(0)" v-if="!link">{{ c3.name }}</a>
                            </md-list-item>
                        </md-list-expand>
                    </md-list-item>
                </md-list-expand>
            </md-list-item>
        </md-list>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    props: {
        category: {
            type: String //slug or name
        },
        vendorGroupCategories: {
            type: Array
        },
        link: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            selectedCategory: null
        };
    },
    computed: {
        ...mapGetters({
            groupCategories: "category/groupCategories"
        }),
        gCategories() {
            return this.vendorGroupCategories || this.groupCategories;
        },
        fgCategories() {
            return this.gCategories.filter(g => !this.rootCategory || g.slug == this.rootCategory.slug);
        },
        rootCategory() {
            if (this.category) {
                let c1 = this.gCategories.find(
                    g =>
                        g.name == this.category ||
                        g.slug == this.category ||
                        (g.children &&
                            g.children.find(
                                c =>
                                    c.name == this.category ||
                                    c.slug == this.category ||
                                    (c.children &&
                                        //
                                        c.children.find(cc => cc.name == this.category || cc.slug == this.category))
                            ))
                );
                return c1;
            }
        },

        defaultExpandAll() {
            return !!this.category;
        }
    },
    watch: {
        category: {
            immediate: true,
            handler() {
                if (this.defaultExpandAll) {
                    setTimeout(() => {
                        this.expand();
                    }, 0);
                }
            }
        }
    },
    methods: {
        selected(c) {
            console.info("selected");
            if (this.selectedCategory == c) {
                this.selectedCategory = null;
                this.$emit("selected", null);
            } else {
                this.selectedCategory = c;
                this.$emit("selected", c);
            }
        },
        expand() {
            console.info("expand");
            []
                .concat(this.$refs["menuitem"])
                // .concat(this.$refs["menuitem2"])
                .forEach(comp => (comp.active = true));
        }
    },
    mounted() {}
};
</script>
<style lang="less">
.side-menu {
    margin-top: 2px;
    width: 250px;
    border-right: #efefef 1px solid;
    .md-list {
        .md-list-item {
            a {
                color: black;
                z-index: 2;
                &:hover {
                    text-decoration: underline !important;
                }
                &.router-link-active {
                    color: black;
                }
                &.selected {
                    color: #064787;
                }
            }
        }
    }
    .c1 {
    }
    .c2 {
        .md-list-item-container {
            padding-left: 40px;
        }
    }
    .c3 {
        .md-list-item-container {
            padding-left: 80px;
        }
    }
}

.vendorMode {
    .md-list {
        .md-list-item {
            a {
                color: #38f;
                &.router-link-active {
                    color: #38f;
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .side-menu {
        width: auto;
    }
}
</style>
