<template>
  <div class="add-on-items" :class="mode" v-if="addOnItems && addOnItems.length">
    <p>Add-on Items:</p>
    <ul>
      <li v-for="item in addOnItems" :key="item._id" v-if="item.active">
        <p class="name">{{item.name}}</p>
        <p class="price">
          <span>{{item.price | currency}}</span>/{{item.unit}}
        </p>
        <input v-model="item.quantity" :min="getProductAddOn(item._id).minQuantity ||0" :max="getProductAddOn(item._id).maxQuantity" @change="handleChange(item)" type="number" />
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
    props: {
        mode: {
            type: String,
            default: ""
        },
        product: { required: true }
    },
    computed: {
        getProductAddOn() {
            return addOnId => {
                return this.product.addOnItems.find(a => a._id == addOnId);
            };
        },
        addOnItems() {
            const tag = this.timestamp;
            const cartAddOnItems = this.getAddOnItems({ _id: this.product._id });
            const { addOnItems = [] } = this.product;
            if (addOnItems && cartAddOnItems) {
                addOnItems.forEach((item, idx) => {
                    const { _id, active } = item;
                    if (active) {
                        const findedItem = cartAddOnItems.find(i => i._id === _id);
                        if (findedItem) {
                            item.quantity = findedItem.quantity;
                        }
                    }
                });
            }
            return addOnItems;
        },
        ...mapGetters({
            getAddOnItems: "cart/getAddOnItems"
        })
    },
    methods: {
        handleChange(item) {
            if (!item.quantity || isNaN(item.quantity) || item.quantity < 0) {
                item.quantity = 0;
            }
            if (item.quantity > 999) {
                item.quantity = 999;
            }
            this.$emit("change");
            this.timestamp = +new Date();
        }
    }
};
</script>
<style lang="less">
.add-on-items {
    margin: 10px 0;
    width: 100%;
    max-width: 400px;
    white-space: inherit;

    &.cart {
        font-size: 12px;

        // li {
        //     flex-direction: column;
        // }
    }

    ul,
    li,
    .md-checkbox {
        width: 100%;
        margin: 0;
        padding: 0;
        list-style: none;
    }
    ul {
        margin-left: 5px;
    }

    li {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    span {
        font-weight: bold;
        color: rgba(0, 0, 0, 0.87);
    }

    input {
        width: 15%;
        min-width: 40px;
        display: inline-block;
        text-align: center;
    }

    .name {
        width: 45%;
    }

    .price {
        width: 45%;
    }

    p {
        height: auto;
        word-wrap: break-word;
        word-break: break-all;
        white-space: wrap;
        overflow: hidden;
    }
}
</style>