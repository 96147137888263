<template>
    <md-sidenav class="cart md-right" ref="cart">
        <md-toolbar>
            <md-list class="md-transparent">
                <md-list-item>
                    <h4>Cart Items</h4>
                </md-list-item>
            </md-list>
        </md-toolbar>
        <md-list :key="key" class="cart-items" v-for="(items, key) in groupItems" v-if="isVendorItems(items)">
            <h4>Vendor : {{ key }}</h4>
            <md-list-item :key="ix" v-for="(item, ix) in items">
                <md-avatar>
                    <router-link :to="'/' + item.slug + '/' + item._id">
                        <img :src="item.image | googleimage" md-list-avatar width="50" />
                    </router-link>
                </md-avatar>
                <div style="flex: 1">
                    <router-link :to="'/' + item.slug + '/' + item._id" class="name">
                        <span>{{ item.name }}</span>
                        <br />
                        <small v-if="item.vname">{{ item.vname }}</small>
                    </router-link>
                    <div class="price" v-if="item.rental">
                        {{ item.quantity }} @ {{ item.price | currency }} * {{ getRentalPeriod({ _id: item._id, vid: item.vid }) }} {{ item.rentalInterval | intervalName }} + ${{
                            getAddOnItemsPrice({ _id: item._id })
                        }}= {{ ((item.price * getRentalPeriod({ _id: item._id, vid: item.vid }) + getAddOnItemsPrice({ _id: item._id })) * item.quantity) | currency }}
                    </div>
                    <div class="price" v-if="!item.rental">
                        {{ item.quantity }} @ {{ item.price | currency }} + ${{ getAddOnItemsPrice({ _id: item._id }) }} =
                        {{ ((item.price + getAddOnItemsPrice({ _id: item._id })) * item.quantity) | currency }}
                    </div>
                    <cart-date-buttons :product="item" :variant="{ _id: item.vid }" mode="cart"></cart-date-buttons>
                    <product-add-on-items :product="item" @change="handleAddOnItemsChange(ix)" mode="cart"></product-add-on-items>
                    <cart-buttons :product="item" :variant="{ _id: item.vid }" ref="cartButton"></cart-buttons>
                </div>
                <md-button @click="removeFromCart({ product: item, variant: { _id: item.vid } })" alt="delete" class="md-icon-button md-list-action">
                    <md-icon>delete</md-icon>
                </md-button>
                <md-divider></md-divider>
            </md-list-item>
        </md-list>
        <md-list>
            <md-list-item v-if="shipping && false">
                <div class="md-list-text-container" v-if="totalAmount > 0">
                    <p></p>
                    <span v-if="shipping.charge <= 0">Delivery:&nbsp;Free</span>
                    <span v-if="shipping.charge > 0">Delivery:&nbsp;{{ shipping.charge | currency }}</span>
                    <span>
                        <h3>Grand Total: {{ (totalAmount + shipping.charge) | currency }}</h3>
                    </span>
                </div>
            </md-list-item>
            <md-list-item>
                <div class="md-list-text-container">
                    <md-button @click="goCheckout" alt="checkout" class="md-primary md-raised" v-if="cartItems.length"> <md-icon>local_grocery_store</md-icon>Checkout → </md-button>
                    <md-button @click="close" alt="continue" class="md-primary md-raised" v-if="!cartItems.length"> <md-icon>local_grocery_store</md-icon>Continue </md-button>
                </div>
            </md-list-item>
        </md-list>
    </md-sidenav>
</template>
<script>
import CartDateButtons from "@/components/Cart/CartDateButtons";
import CartButtons from "@/components/Cart/CartButtons";
import ProductAddOnItems from "@/components/Product/ProductAddOnItems";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import Vue from "vue";
export default {
    components: {
        CartButtons,
        CartDateButtons,
        ProductAddOnItems,
    },
    data() {
        return {};
    },
    computed: {
        ...mapState({
            shipping: (state) => state.shipping || {},
        }),
        ...mapGetters({
            cartItems: "cart/items",
            groupItems: "cart/groupItems",
            totalAmount: "cart/totalAmount",
            getQuantity: "cart/getQuantity",
            getRentalPeriod: "cart/getRentalPeriod",
            getAddOnItemsPrice: "cart/getAddOnItemsPrice",
            vendorMode: "vendorMode",
        }),
        isVendorItems() {
            return (items) => items.length && (!this.vendorMode || !this.vendorMode.name || items[0].vendor_name == this.vendorMode.name);
        },
    },
    methods: {
        ...mapActions({
            addToCart: "cart/addToCart",
            removeFromCart: "cart/removeFromCart",
        }),
        ...mapMutations({
            cleanCart: "cart/cleanCart",
        }),
        handleAddOnItemsChange(idx) {
            this.$refs["cartButton"][idx].handleAddOnItemsChange();
        },
        goCheckout() {
            this.$refs["cart"].toggle();
            this.$router.push("/checkout");
        },
        close() {
            this.$refs["cart"].close();
            this.$router.push("/");
        },
    },
    created() {
        this.cleanCart();
        this.eventHub.$on("toggleCart", (data) => {
            this.$refs["cart"] && this.$refs["cart"].toggle();
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        });
    },
};
</script>
<style lang="less">
.cart {
    .cart-items {
        h4 {
            padding-left: 30px;
        }

        .name {
            display: block;
        }

        .price {
            font-size: 12px;
        }

        .md-list-item-container {
            width: 100%;
            padding: 0 7px 20px 7px;
            margin-bottom: 20px;
            p {
                margin: 0;
            }
            .md-list-action:nth-child(3) {
                margin: 0;
            }

            .md-avatar {
                margin-right: 7px;
            }
        }
        .md-divider {
            margin: 0 20px;
        }
    }
    .md-list-text-container {
        .md-icon {
            color: white;
        }
    }
}
</style>