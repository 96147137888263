<template>
    <div class="mega-menu-2">
        <div class="banner">
            <ul class="categories-menu">
                <li :key="ix" v-for="(category,ix) in categories">
                    <router-link :to="`/category/${category.slug}?level=${category.level}`">{{category.name}}</router-link>
                </li>
            </ul>

            <ul class="hamburger-menu" style="padding-left:10px">
                <li @click="hamburgerClick">
                    <md-icon>dehaze</md-icon>
                </li>
            </ul>

            <ul style="padding-right:10px">
                <li v-if="!vendorMode">
                    <router-link to="/helpcenter">Help Center</router-link>
                </li>
                <!-- <li v-if="!vendorMode">
                    <router-link to="/get-financing">Get Financing</router-link>
                </li> -->
            </ul>
        </div>
        <md-sidenav class="md-left categories" ref="sideNav">
            <md-toolbar>
                <div class="md-toolbar-container">
                    <h4 class="md-title">Category</h4>
                </div>
            </md-toolbar>
            <side-menu @selected="selected"></side-menu>
        </md-sidenav>
    </div>
</template>
<script>
import SideMenu from "@/components/Menu/SideMenu";
import { mapGetters } from "vuex";
export default {
    components: {
        SideMenu
    },
    computed: {
        ...mapGetters({
            vendorMode: "vendorMode",
            categories: "category/groupCategories"
        })
    },
    methods: {
        hamburgerClick() {
            this.$refs["sideNav"].toggle();
            this.$emit("hamburger");
        },
        selected() {
            this.$refs["sideNav"].close();
        }
    }
};
</script>

<style lang="less">
.mega-menu-2 {
    .banner {
        z-index: 9;
        display: flex;
        height: 50px;
        margin: 0 auto;
        padding: 0;
        list-style: none;
        color: white;
        background: #231aa1;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 1px 1px rgba(0, 0, 0, 0.14), 0 1px 1px -2px rgba(0, 0, 0, 0.12);
        > ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;

            li {
                white-space: nowrap;
            }

            &:not(.md-list) > li + li {
                margin-top: 0;
            }
            a {
                font-size: 1em;
                line-height: 40px;
                display: block;
                height: 40px;
                padding: 0 15px;
                text-decoration: none;
                color: white;
                &:hover {
                    text-decoration: none;
                    color: #f44336;
                }
                &:not(.md-button):not(.md-bottom-bar-item):hover {
                    text-decoration: none;
                }
            }
        }
        .categories-menu {
            display: flex;
        }
        .hamburger-menu {
            display: none;
            cursor: pointer;
        }
    }
    .md-sidenav {
        display: none;
    }
}

.vendorMode {
    .mega-menu-2 {
        .banner {
            background: white;
            > ul {
                a {
                    color: #38f;
                    &:hover {
                        color: #f44336;
                    }
                }
            }
            .hamburger-menu {
                color: #38f;
            }
        }
        .md-sidenav {
            .md-toolbar {
                background: white;
                color: grey;
                border-bottom: 1px solid grey;
            }
        }
    }
}

@media (max-width: 600px) {
    .mega-menu-2 {
        .banner {
            & {
                overflow-x: scroll;
            }

            &::-webkit-scrollbar {
                width: 4px;
                height: 5px;
                cursor: pointer;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 5px;
                background: rgba(0, 0, 0, 0.2);
                cursor: pointer;
            }
            &::-webkit-scrollbar-track {
                border-radius: 0;
                cursor: pointer;
            }

            .categories-menu {
                display: none;
            }
            .hamburger-menu {
                display: flex;
            }
        }
        .md-sidenav {
            display: inherit;
        }
    }
}
</style>
