<template>
    <div class="email-editor">
        <!-- VueQuillEditor -->
        <span ref="_height_tag"/>
        <div :style="{height: `${editorWrapHeight}px`}" class="editor">
            <quill-editor :options="computedOptions" :style="{height: `${editorHeight}px`}" ref="quillEditor" v-if="showEditor" v-model="content"></quill-editor>
        </div>
        <md-input type="hidden" v-model="content"></md-input>

        <!-- <textarea ref="textarea" v-model="content"></textarea> -->
        <p class="tip" v-if="emailPlaceholders.length">place holder words:</p>
        <a :key="ix" @click.prevent="useWord(p)" class="placeholder" href v-for="(p,ix) in emailPlaceholders">{{p}}</a>
    </div>
</template>
<script>
// import { emailPlaceholders } from '@/config';
import { mapGetters } from "vuex";
// import Quill from "quill";
// var Link = Quill.import("formats/link");

// class MyLink extends Link {
//     static create(value) {
//         const node = super.create(value);
//         node.setAttribute("href", this.sanitize(value));
//         //node.setAttribute('target', '_blank');
//         node.removeAttribute("target");
//         return node;
//     }
// }

// Quill.register(MyLink);
export default {
    props: {
        value: String,
        wordList: Array,
        autoFitHeight: Boolean,
        options: {
            type: Object,
            default() {
                return {
                    modules: {
                        toolbar: [
                            ["bold", "italic", "underline", "strike"], // toggled buttons
                            ["blockquote", "code-block"],
                            [{ header: 1 }, { header: 2 }], // custom button values
                            [{ list: "ordered" }, { list: "bullet" }],
                            [{ script: "sub" }, { script: "super" }], // superscript/subscript
                            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                            [{ direction: "rtl" }], // text direction
                            [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                            [{ header: [1, 2, 3, 4, 5, 6, false] }],
                            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                            [{ font: [] }],
                            [{ align: [] }],
                            ["clean"] // remove formatting button
                        ]
                    }
                };
            }
        },
        height: {
            type: [String, Number],
            default: 240
        }
    },
    data() {
        return {
            content: "",
            computedHeight: 0,
            showEditor: false
        };
    },
    computed: {
        ...mapGetters({
            hasRole: "user/hasRole"
        }),
        emailPlaceholders() {
            return this.wordList || [];
        },
        editorWrapHeight() {
            return +this.editorHeight + 65;
        },
        editorHeight() {
            const { computedHeight, height } = this;
            return computedHeight ? computedHeight : height;
        },
        computedOptions() {
            const options = this.options;
            if (this.hasRole("manager")) {
                options.modules.toolbar.push(["link", "image"]);
            }
            return options;
        }
    },
    watch: {
        value() {
            this.content = (this.value || "").replace(/^<p><br><\/p>/, "");
        },
        content() {
            this.$emit("input", this.content);
        }
    },
    methods: {
        useWord(word) {
            var quillEditor = this.$refs["quillEditor"];
            var quill = quillEditor.quill;
            var { index, length } = quill.getSelection(true);
            quill.insertText(index + length, word);
        }
    },
    mounted() {
        if (this.value) {
            this.content = this.value.replace(/^<p><br><\/p>/, "");
        }
        setTimeout(() => {
            this.showEditor = true;
            if (this.autoFitHeight) {
                const { bottom } = this.$refs._height_tag.getBoundingClientRect();
                const { clientHeight } = window.document.body;
                this.computedHeight = clientHeight - bottom - 100;
            }
        }, 500);
    }
};
</script>

<style lang="less">
.email-editor {
    display: flex;
    flex-direction: column;
    flex: 1;
    // overflow: hidden;
    padding-bottom: 1px;
    .placeholder {
        font-size: 12px;
    }

    .ql-picker-label {
        padding-top: 0;
    }

    .tip {
        color: #666;
        font-size: 12px;
        margin: 0;
    }

    textarea {
        width: 100%;
        resize: auto;
        color: rgba(0, 0, 0, 0.78);
        font-size: 16px;
    }
}
@media (max-width: 600px) {
    padding-bottom: 50px;
}
</style>
