let promise = null;
const state = {
    categories: []
};

const getters = {
    categories(state) {
        let rawList = JSON.parse(JSON.stringify(state.categories));
        let level1s = rawList.filter(item => item.level == 1).sort((a, b) => a.rank - b.rank);
        let level2s = rawList.filter(item => item.level == 2).sort((a, b) => a.rank - b.rank);
        let level3s = rawList.filter(item => item.level == 3).sort((a, b) => a.rank - b.rank);
        console.info(level1s, level2s, level3s);
        let result = [];
        level1s.forEach(item1 => {
            result.push(item1);
            let subLevel2s = level2s.filter(item => item.parent && item.parent._id == item1._id);
            subLevel2s.forEach(item2 => {
                result.push(item2);
                let subLevel3s = level3s.filter(item => item.parent && item.parent._id == item2._id);
                result = result.concat(subLevel3s);
            });
        });
        console.info(result);
        return result;
    },
    groupCategories(state) {
        let rawList = JSON.parse(JSON.stringify(state.categories));
        let level1s = rawList.filter(item => item.level == 1).sort((a, b) => a.rank - b.rank);
        let level2s = rawList.filter(item => item.level == 2).sort((a, b) => a.rank - b.rank);
        let level3s = rawList.filter(item => item.level == 3).sort((a, b) => a.rank - b.rank);
        let result = [];
        level1s.forEach(item1 => {
            result.push(item1);
            item1.children = [];
            let subLevel2s = level2s.filter(item => item.parent && item.parent._id == item1._id);
            subLevel2s.forEach(item2 => {
                item1.children.push(item2);
                let subLevel3s = level3s.filter(item => item.parent && item.parent._id == item2._id);
                item2.children = subLevel3s;
            });
        });
        return result;
    },
    getChildrenById: state => id => {
        let rawList = JSON.parse(JSON.stringify(state.categories));
        let children = rawList.filter(item => item.parent && item.parent._id == id);
        let subChildren = [...children.map(child => rawList.filter(item => item.parent && item.parent._id == child._id))];
        return rawList.filter(item => children.find(i => i._id == item._id) || subChildren.find(i => i._id == item._id));
    },
    getParentsById: state => id => {
        let rawList = JSON.parse(JSON.stringify(state.categories));
        let current = rawList.find(item => item._id == id);
        let result = [];

        while (current) {
            let parent = null;
            if (current && current.parent) {
                parent = rawList.find(item => item._id == current.parent._id);
                result.push(parent);
            }
            current = parent;
        }
        return result.reverse();
    },
    getCategoryBySlug: state => (slug, level) => {
        let categories = state.categories.filter(c => c.slug == slug);
        if (categories.length > 1) {
            categories = state.categories.filter(c => c.slug == slug && (!level || c.level == level));
        }
        return categories[0];
    }
};

const actions = {
    async reload({ state, getters, commit, dispatch }) {
        commit("clear");
        await dispatch("loadCategories");
    },
    async loadCategories({ state, getters, rootGetters, commit, dispatch }) {
        let vendorMode = rootGetters.vendorMode;
        let affiliateId = rootGetters.affiliateId;
        if (!state.categories.length) {
            if (promise) {
                return await promise;
            }
            promise = dispatch(
                "crud/get",
                {
                    api: "categories",
                    params: {
                        vendor: vendorMode && vendorMode._id,
                        affiliateId: affiliateId || undefined
                    }
                },
                { root: true }
            );
            let result = await promise;
            let data = result.data || result;
            data = data.filter(c => c.active);
            commit("setCategories", data);
            promise = null;
        }
        return state.categories;
    }
};

const mutations = {
    setCategories(state, categories) {
        state.categories = categories;
    },
    clear(state) {
        state.categories = [];
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
