<template>
    <div class="submit-button">
        <md-button class="md-raised md-primary" :class="className" type="submit" :disabled="loading || disabled">
            <md-spinner :md-size="20" md-indeterminate v-if="!!loading" class="md-accent spinner"></md-spinner>
            <md-icon v-else>{{ icon }}</md-icon>
            <span>{{ text }}</span>
        </md-button>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: ["disabled", "form", "icon", "text", "className"],
    computed: {
        ...mapGetters(["loading"])
    }
};
</script>
<style lang="less">
.submit-button {
    .spinner {
        top: 6px;
    }
}
</style>
