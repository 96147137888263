let promise = null;

const state = {
    locations: [],
    selectedId: localStorage["location"]
};

const getters = {
    location(state) {
        return state.locations.find(l => l._id == state.selectedId);
    },
    locations(state) {
        return state.locations.map(l => ({ name: `${l.country}-${l.state}-${l.county}-${l.city}`, ...l }));
    },
    defaultLocation(state) {
        return state.locations.find(l => l.default);
    }
};

const actions = {
    async reload({ state, getters, commit, dispatch }) {
        commit("clear");
        await dispatch("loadLocations");
    },
    async loadLocations({ state, rootState, getters, rootGetters, commit, dispatch }) {
        let vendorMode = rootGetters.vendorMode;
        if (!state.locations.length) {
            if (promise) {
                return await promise;
            }
            promise = dispatch(
                "crud/get",
                {
                    api: "locations",
                    params: {
                        vendor: vendorMode && vendorMode._id,
                        pageSize: 0,
                        front: true,
                        active: true
                    }
                },
                { root: true }
            );
            const result = await promise;
            const data = result.data || result;

            commit("setLocations", data);

            //default location
            const defaultLocation = data.find(r => r.default);
            if (defaultLocation && !localStorage["location"]) {
                console.info("DEFAULT", defaultLocation);
                dispatch("setLocation", defaultLocation._id);
            }

            promise = null;
        }
        return state.locations;
    },
    async setLocation({ state, getters, dispatch, commit }, id) {
        console.info("set location", id);
        let location = state.locations.find(l => l._id == id);
        if (!location) {
            commit("setMessage", "Location Not Found", { root: true });
            return;
        }
        commit("setSelectedId", id);
        location.timezone && commit("setTZ", location.timezone, { root: true });
        return location;
    }
};

const mutations = {
    setLocations(state, locations) {
        state.locations = locations;
    },
    setSelectedId(state, selectedId) {
        localStorage["location"] = selectedId;
        state.selectedId = selectedId;
    },
    clear(state) {
        state.locations = [];
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
