import { Validator } from "vee-validate";

Validator.extend("secure-password", {
    getMessage(field, params, data) {
        return `the password must be at least 8 characters with numbers and symbols`;
    },
    validate(value) {
        let passowrd = value;
        if (passowrd.length < 8) {
            return false;
        }
        if (!/\d/.test(passowrd)) {
            return false;
        }
        if (!/[^\d]/.test(passowrd)) {
            return false;
        }
        return true;
    }
});

Validator.extend("agree", {
    getMessage(field, params, data) {
        return `please check the agreement`;
    },
    validate(value) {
        return !!value;
    }
});
