<template>
    <div class="loading" v-if="showLoading">
        <div class="loading-content">
            <md-spinner md-indeterminate></md-spinner>
            <span>Please wait...</span>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { loadingTimeout } from "@/config";
export default {
    data() {
        return {
            typingTimer: null,
            showLoading: false
        };
    },
    computed: {
        ...mapGetters(["loading"])
    },
    watch: {
        loading() {
            clearTimeout(this.loadingTimer);
            this.loadingTimer = setTimeout(() => {
                this.showLoading = this.loading;
            }, loadingTimeout);
        }
    }
};
</script>
<style lang="less">
.loading {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.5);
    z-index:9999;
    .loading-content {
        padding: 5px 20px;
        box-shadow: 0 0 1px rgba(150, 150, 150, 0.6);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        color: #666;
        background: white;
        cursor: progress;
        .md-spinner {
            margin-right: 5px;
        }
    }
}
</style>
