import axios from "axios";
const state = {};
const getters = {};
const mutations = {};

const fixApi = function(api) {
    if (/^(\/|http)/.test(api)) {
        return api;
    } else {
        return "/api/" + api;
    }
};

// actions
const actions = {
    async get({ dispatch, commit, state, rootState }, { api, params, token }) {
        token = token || (rootState.user.user && rootState.user.user.token) || rootState.user.vendorSignupToken;
        const response = await axios.get(fixApi(api), {
            headers: token && {
                Authorization: "Bearer " + token
            },
            params: params
        });
        return response.data;
    },
    async post({ dispatch, commit, state, rootState }, { api, data, params, token }) {
        token = token || (rootState.user.user && rootState.user.user.token) || rootState.user.vendorSignupToken;
        const response = await axios.post(fixApi(api), data, {
            headers: token && {
                Authorization: "Bearer " + token
            },
            params
        });
        return response.data;
    },
    async put({ dispatch, commit, state, rootState }, { api, data, token }) {
        token = token || (rootState.user.user && rootState.user.user.token) || rootState.user.vendorSignupToken;
        const response = await axios.put(fixApi(api), data, {
            headers: token && {
                Authorization: "Bearer " + token
            }
        });
        return response.data;
    },
    async delete({ dispatch, commit, state, rootState }, { api, token }) {
        token = token || (rootState.user.user && rootState.user.user.token) || rootState.user.vendorSignupToken;
        const response = await axios.delete(fixApi(api), {
            headers: token && {
                Authorization: "Bearer " + token
            }
        });
        return response.data;
    },
    async download({ dispatch, commit, state, rootState }, { api, params, filename, token }) {
        token = token || (rootState.user.user && rootState.user.user.token) || rootState.user.vendorSignupToken;
        const response = await axios({
            url: fixApi(api),
            method: "GET",
            responseType: "blob",
            params,
            headers: token && {
                Authorization: "Bearer " + token
            }
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
