import { store } from "@/store";

const user = store.getters["user/user"];

export const lang = "en";
export const loadingTimeout = 100; // Used for showing the loading indicator at each page
export const clearCart = true; // Whether to clear the cart after order is placed. Useful while testing
export const demo = false;

export const TZLIST = [
    "America/New_York", //-4
    "America/Cambridge_Bay", //-6
    "America/Chicago", //-5
    "America/Los_Angeles", //-7
    "America/Anchorage", //-8
    "America/Adak", //-9
    "Pacific/Honolulu" //-10
];

export const reviewSettings = {
    enabled: true,
    moderate: true
};
export const menuItems = [
    {
        name: "Booking",
        role: "staff",
        children: [
            //
            { name: "Manage Booking", role: "staff", url: "/admin/orders/manage", icon: "history" },
            { name: "Calendar", role: "staff", url: "/admin/orders/calendar", icon: "history" }
        ]
    },
    { name: "Messages", alwayShow: true, role: "staff", url: "/admin/qas/manage", icon: "chat" },
    { name: "My Orders", role: "user", asRole: "user", url: "/admin/orders/my" },
    {
        name: "Inventory",
        role: "staff",
        children: [
            { name: "Brands", role: "manager", url: "/admin/brands", icon: "wb_auto" },
            { name: "Categories", role: "manager", url: "/admin/categories", icon: "view_comfy" },
            { name: "Referrals", role: "manager", url: "/admin/referrals", icon: "view_comfy" },
            { name: "Products", role: "staff", url: "/admin/products", icon: "store" },
            { name: "Packages", role: "staff", url: "/admin/packages", icon: "store" },
            { name: "Features", role: "manager", url: "/admin/features", icon: "check_circle" },
            { name: "Blackout Dates", role: "vendor", url: "/admin/vacations", icon: "block" },
            { name: "Vendor Site", role: "vendor", url: "/admin/sites/signin", icon: "lock" }
            // { name: "Media Library", role: "vendor", url: "/admin/media", icon: "perm_media" },
        ]
    },
    {
        name: "Manage",
        role: "staff",
        children: [
            { name: "Manage Users", role: "manager", url: "/admin/users", icon: "face" },
            { name: "Manage Staff", role: "vendor", url: "/admin/users/staffs", icon: "face" },
            { name: "Manage Branches", role: "vendor", url: "/admin/users/branches", icon: "face" },
            { name: "Manage Reviews", role: "staff", url: "/admin/reviews/manage", icon: "stars" },
            // { name: "Manage Tickets", role: "staff", url: "/admin/qas/manage", icon: "star_rate" },
            { name: "Manage Locations", role: "manager", url: "/admin/locations", icon: "stars" },
            { name: "Manage Sites", role: "manager", url: "/admin/sites", icon: "starts" },
            { name: "Payment Gateway", role: "vendor", url: "/account/connect", icon: "star_rate" },
            { name: "My AppFee", role: "vendor", url: "/account/vendor/appfee", icon: "star_rate" },
            { name: "Service Locations", role: "vendor", url: "/account/vendor/location", icon: "face" }
            // { name: "Affiliate Signup", role: "vendor", url: "/admin/affiliate/signup", icon: "face" },
            // { name: "Affiliate Commisions", role: "vendor", url: "/admin/affiliate/commissions", icon: "face" }
        ]
    },
    {
        name: "Reports",
        role: "vendor",
        permission: "view_report", //staff only
        url: "/admin/reports/booking-locations",
        children: [
            //
            // { name: "Booking Locations", role: "vendor", url: "/admin/reports/booking-locations", icon: "star_rate" }
        ]
    },
    {
        name: "System",
        role: "manager",
        children: [
            { name: "Coupons", role: "manager", url: "/admin/coupons", icon: "style" },
            { name: "Notifications", role: "admin", url: "/admin/notifications", icon: "message" },
            // { name: "Delivery", role: "manager", url: "/admin/shippings", icon: "local_shipping" },
            { name: "Deliver Fee Settings", role: "manager", url: "/admin/setting/shipping-fee", icon: "local_shipping" },
            { name: "Pages", role: "manager", url: "/admin/pages", icon: "message" },
            { name: "Articles", role: "manager", url: "/admin/articles", icon: "receipt" },
            { name: "Payment Gateways", role: "admin", url: "/admin/setting/payment", icon: "payment" },
            { name: "UseChat Settings", role: "admin", url: "/admin/setting/use-chat", icon: "receipt" },
            { name: "Sendgrid Settings", role: "admin", url: "/admin/setting/sendgrid", icon: "receipt" },
            { name: "Telephone Settings", role: "admin", url: "/admin/setting/telephone", icon: "receipt" },
            { name: "Terms of Service", role: "manager", url: "/admin/setting/terms-of-service", icon: "receipt" },
            { name: "Vendor Terms of Service", role: "manager", url: "/admin/setting/vendor-terms-of-service", icon: "receipt" },
            { name: "Get Financing", role: "manager", url: "/admin/setting/get-financing", icon: "receipt" },
            { name: "Vendor Display", role: "admin", url: "/admin/setting/vendor-display", icon: "receipt" },
            { name: "Version", role: "manager", url: "/admin/versions", icon: "receipt" },
            { name: "Log", role: "admin", url: "/admin/logs", icon: "receipt" }
        ]
    },
    {
        name: "Home Page",
        role: "admin",
        children: [
            { name: "Banner", role: "admin", url: "/admin/setting/banner", icon: "perm_media" },
            { name: "Home Page Editor", role: "admin", url: "/admin/homepages", icon: "perm_media" },
            { name: "Google Analytics", role: "admin", url: "/admin/setting/google-analytics", icon: "perm_media" }
        ]
    },
    {
        name: "Account",
        role: "user",
        children: [
            { name: "My Orders", role: "staff", url: "/admin/orders/my", icon: "watch_later" },
            { name: "My Addresses", role: "user", url: "/admin/addresses", icon: "location_city" },
            { name: "My Wishlist", role: "user", url: "/admin/wishlist", icon: "favorite" },
            { name: "My Reviews", role: "user", url: "/admin/reviews", icon: "star_rate" },
            { name: "My Tickets", role: "user", url: "/admin/qas/my", icon: "star_rate" },
            { name: "Profile", role: "user", url: "/account/profile", icon: "face" },
            { name: "Change Password", role: "user", url: "/account/change-password", icon: "lock" },
            { name: "Update Email", role: "user", url: "/account/update-email", icon: "lock" },
            { name: "Payment Methods", role: "user", url: "/admin/payment/cards", icon: "star_rate" }
            // { name: "Logout", role: "user", url: "", icon: "vpn_key" }
        ]
    }
];

export const userRoles = ["user", "staff", "vendor", "manager", "admin"]; // This should be in ascending order of authority. e.g. In this case guest will not have access to any other role, where as admin will have the role of guest+user+vendor+manager+admin
export const orderStatuses = ["All Active", "Pending", "Booked", "Awaiting Payment", "Rescheduled", "Delivered", "Returned", "Completed", "Cancelled", "Void"];
export const paymentStatuses = ["Pending", "Deposit", "Paid", "Refunded"];
export const vendorTypes = ["Rental Company", "Service Provider", "Ecommerce"];
export const country = {
    name: "United States",
    code: "US"
};
export const states = {
    Alabama: "AL",
    Montana: "MT",
    Alaska: "AK",
    Nebraska: "NE",
    Arizona: "AZ",
    Nevada: "NV",
    Arkansas: "AR",
    "New Hampshire": "NH",
    California: "CA",
    "New Jersey": "NJ",
    Colorado: "CO",
    "New Mexico": "NM",
    Connecticut: "CT",
    "New York": "NY",
    Delaware: "DE",
    "North Carolina": "NC",
    Florida: "FL",
    "North Dakota": "ND",
    Georgia: "GA",
    Ohio: "OH",
    Hawaii: "HI",
    Oklahoma: "OK",
    Idaho: "ID",
    Oregon: "OR",
    Illinois: "IL",
    Pennsylvania: "PA",
    Indiana: "IN",
    "Rhode Island": "RI",
    Iowa: "IA",
    "South Carolina": "SC",
    Kansas: "KS",
    "South Dakota": "SD",
    Kentucky: "KY",
    Tennessee: "TN",
    Louisiana: "LA",
    Texas: "TX",
    Maine: "ME",
    Utah: "UT",
    Maryland: "MD",
    Vermont: "VT",
    Massachusetts: "MA",
    Virginia: "VA",
    Michigan: "MI",
    Washington: "WA",
    Minnesota: "MN",
    "West Virginia": "WV",
    Mississippi: "MS",
    Wisconsin: "WI",
    Missouri: "MO",
    Wyoming: "WY"
};

export const currency = {
    symbol: "$",
    code: "USD", // Shop currency
    paypal: "USD", // Paypal currency code *** Please choose from https://developer.paypal.com/docs/classic/api/currency_codes/
    exchange_rate: "1" // Paypal currency code(USD) / Shop currency (INR) ***  exchange_rate should not be 0 else it will generate divided by 0 error
};

///fileds
export const addressFields = [
    // { heading: "Name", data: "name", type: "text", validate: "required" },
    // { heading: "Email", data: "email", type: "text", validate: "required" },
    { heading: "Phone", data: "phone", type: "text" },
    { heading: "Address", data: "address", type: "text" },
    { heading: "Apart.#", data: "apartNo", type: "text" },
    { heading: "City/Town", data: "city", type: "text" },
    { heading: "State", data: "state", type: "select", options: Object.keys(states).sort((a, b) => (a > b ? 1 : -1)) },
    { heading: "Zip", data: "zip", type: "number" },
    { heading: "Country", data: "country", type: "select", options: [country.name], default: country.name },
    { heading: "Coordinate", data: "coordinate", type: "readonly" }
];
export const couponFields = [
    { heading: "code", type: "text", data: "code" },
    { heading: "amount", type: "number", data: "amount" },
    { heading: "minimumCartValue", type: "number", data: "minimumCartValue" },
    { heading: "info", type: "text", data: "info" },
    { heading: "type", type: "text", data: "type" },
    { heading: "active", type: "boolean", data: "active" }
];
export const featureFields = [
    //
    { heading: "Key", data: "key", type: "text" },
    { heading: "Val", data: "val", type: "text" },
    { heading: "Active", data: "active", type: "boolean" }
];

export const productFields = [
    { heading: "Name", data: "name", type: "link", link: "/:slug/:_id" },
    { heading: "Vendor", data: "vendor_name", type: "text" },

    { heading: "Category", data: "category", type: "category" },
    { heading: "Updated", data: "updatedAt", type: "datetime" },
    { heading: "Approved", data: "approved", type: "boolean", action: true },
    {
        heading: "Variants",
        data: "variants",
        type: "children",
        when: "list",
        children: [
            //
            { heading: "VName", data: "name", type: "text" },
            // { heading: "Image", data: "image", type: "image" },
            { heading: "Price", data: "price", type: "currency" },
            { heading: "Stock", data: "stock", type: "number" },
            { heading: "Size", data: "size", type: "text" }
        ]
    }
];

export const itemFields = [
    { heading: "Name", data: "product.name", type: "link", link: "/:product.slug/:product._id" },
    { heading: "VariantName", data: "name", type: "text" },
    { heading: "Qty", data: "stock", type: "number" },
    { heading: "Price", data: "price", type: "text" },
    { heading: "Vendor", data: "product.vendor_name", type: "text" },
    // { heading: "Category", data: "product.category", type: "category" },
    { heading: "Updated", data: "product.updatedAt", type: "datetime" }
];

export const packageFields = [
    { heading: "Name", data: "name", type: "link", link: "/:slug/:_id" },
    { heading: "Qty", data: "variants.0.stock", type: "number" },
    { heading: "Price", data: "variants.0.price", type: "currency" },
    { heading: "Vendor", data: "vendor_name", type: "text" },
    { heading: "Category", data: "category", type: "category" },
    { heading: "Updated", data: "updatedAt", type: "datetime" },
    { heading: "Approved", data: "approved", type: "boolean", action: true }
];

export const brandFields = [
    //
    { heading: "Name", data: "name", type: "text" },
    { heading: "Active", data: "active", type: "boolean" }
];

export const categoryFields = [
    { heading: "Name", data: "name", type: "text", validate: "required" },
    { heading: "Slug", data: "slug", type: "readonly", when: "list" },
    { heading: "Banner", data: "banner", type: "image", folder: "category" },
    { heading: "Parent", data: "parent.name", type: "readonly", when: "list" },
    { heading: "Parent", data: "parent", type: "select", options: [], when: "edit" },
    { heading: "Level", data: "level", type: "readonly", when: "list" },
    { heading: "Rank", data: "rank", type: "number", default: "0" },
    { heading: "Show Banner", data: "showBanner", type: "boolean", default: true },
    { heading: "Active", data: "active", type: "boolean", default: true }
];

export const locationFields = [
    { heading: "Country", data: "country", type: "select", options: [country.name], default: country.name, validate: "required" },
    { heading: "State", data: "state", type: "select", options: Object.keys(states).sort((a, b) => (a > b ? 1 : -1)), validate: "required" },
    { heading: "County", data: "county", type: "text", validate: "required" },
    { heading: "City", data: "city", type: "text" },
    { heading: "TimeZone", data: "timezone", type: "select", options: TZLIST, validate: "required" },
    { heading: "active", data: "active", type: "boolean" },
    { heading: "Default", data: "default", type: "boolean" }
];

export const reviewFields = [
    { data: "pname", heading: "Product Name", type: "text" },
    { data: "vendor_name", heading: "Vendor", type: "text" },
    { data: "user_name", heading: "Name", type: "text" },
    { data: "user_email", heading: "Email", type: "text" },
    { data: "message", heading: "Message", type: "text" },
    { data: "rating", heading: "Rating", type: "number" },
    { data: "createdAt", heading: "CreatedAt", type: "datetime" },
    { data: "replys.length", heading: "Replys Count", type: "text" },
    { data: "active", heading: "Approval", type: "boolean", action: true },
    { data: "needApproval", heading: "Need Approval", type: "yesno" },
    {
        data: "replys",
        type: "children",
        showDelete: true,
        children: [
            //
            { data: "user_name", heading: "Name", type: "text" },
            { data: "user_email", heading: "Email", type: "text" },
            { data: "message", heading: "Message", type: "text" },
            { data: "createdAt", heading: "CreatedAt", type: "datetime" },
            { data: "active", heading: "Approval", type: "boolean", action: true }
        ]
    }
];

export const wishlistFields = [
    { data: "variant.image", heading: "Image", type: "image" },
    { data: "product.name", heading: "Name", type: "link", link: "/:product.slug/:product._id" },
    { data: "product.vendor_name", heading: "Vendor", type: "text" },
    { data: "variant.price", heading: "Price", type: "text" },
    { data: "variant.size", heading: "Size", type: "number" }
];

export const shippingFields = [
    { heading: "Name", data: "name", type: "text" },
    { heading: "Charge", data: "charge", type: "number" },
    { heading: "CartTotal", data: "cartTotal", type: "number" },
    { heading: "Active", data: "active", type: "boolean" }
];

export const contactFields = [
    { heading: "Your Email", data: "from", type: "text", validate: "required|email" },
    { heading: "Subject", data: "subject", type: "text", default: "Contact from EzTimeRental", validate: "required" },
    { heading: "Message", data: "text", type: "textarea", validate: "required" }
];

export const userSignupFields = [
    { heading: "Name", data: "name", type: "text", validate: "required" },
    { heading: "Email", data: "email", type: "text", validate: "required|email" },
    { heading: "Password", data: "password", type: "password", validate: "required|secure-password", confirm: true },
    { heading: "I have read and agree to the Users Terms and Service Agreement", data: "checkUserTerms", type: "checkbox", validate: "agree|required" }
];

export const userResetPasswordFields = [{ heading: "New Password", data: "password", type: "password", validate: "required|secure-password", confirm: true }];

export const userChangePasswordFields = [
    { heading: "Old Password", data: "oldPassword", type: "password", validate: "required" },
    { heading: "New Password", data: "newPassword", type: "password", validate: "required|secure-password", confirm: true }
];

export const userForgetFields = [
    //
    { heading: "Email", data: "email", type: "text", validate: "required|email" }
];

export const vendorSignupFields = [
    { heading: "Business Name", data: "name", type: "text", validate: "required" },
    { heading: "Business Email", data: "email", type: "text", validate: "required|email" },
    { heading: "Password", data: "password", type: "password", validate: "required|secure-password", confirm: true },
    { heading: "Contact #", data: "vendor.phone", type: "text", validate: "required" },
    { heading: "Vendor Type", data: "vendor.vtype", type: "select", options: vendorTypes, validate: "required", default: vendorTypes[0] },
    { heading: "Role", data: "role", type: "hidden", default: "vendor" },
    { heading: "I have read and agree to the Vendors Terms and Service Agreement", data: "checkVendorTerms", type: "checkbox", validate: "agree|required" },
    { heading: "I have read and agree to the Users Terms and Service Agreement", data: "checkUserTerms", type: "checkbox", validate: "agree|required" }
];

export const vacationFields = [
    { heading: "Vendor", data: "user.name", type: "text" },
    { heading: "Name", data: "name", type: "text", validate: "required" },
    { heading: "Begin", data: "begin", type: "datetime", validate: "required" },
    { heading: "End", data: "end", type: "datetime", validate: "required" },
    { heading: "Active", data: "active", type: "boolean", action: true, default: true }
];

export const pageFields = [
    { heading: "Name", data: "name", type: "link", link: "/pages/:slug/:_id" },
    { heading: "Slug", data: "slug", type: "text" },
    { heading: "Content", data: "content", type: model => (model.type == "Link" ? "text" : "editor") },
    { heading: "Type", data: "type", type: "select", options: ["Link", "Page"], default: "Link", action: true },
    { heading: "Rank", data: "rank", type: "number", default: 0 }
];

export const paymentFields = [
    { heading: "App Fee %", data: "value.amount_percent", type: "number", validate: "required|min_value: 0|max_value: 100" },
    { heading: "Client Id", data: "value.stripe_client_id", type: "text", validate: "required" },
    { heading: "Public Key", data: "value.stripe_public_key", type: "text", validate: "required" },
    { heading: "Secret Key", data: "value.stripe_secret_key", type: "text", validate: "required" },
    { heading: "Webhook Secret", data: "value.stripe_webhook_secret", type: "text", validate: "required" }
];

export const pageBannerFields = [
    { heading: "Banner", data: "banner_url", type: "image", folder: "banner", validate: "required" },
    { heading: "Url", data: "url", type: "text", validate: "required" }
];

export const useChatFields = [{ heading: "Use Chat", data: "value", type: "boolean" }];

export const googleAnalyticsFields = [
    //
    { heading: "Analytics Key", data: "value.analytics_key", type: "text" },
    { heading: "Ad Key", data: "value.ad_key", type: "text" },
    { heading: "Conversion Ad ID", data: "value.conversion_ad_id", type: "text" }
];

export const sendgridFields = [
    { heading: "Api Key", data: "value.api_key", type: "text" },
    { heading: "Template Id", data: "value.template_id", type: "text" }
];

export const termsOfServiceFields = [{ heading: "content", data: "value", type: "editor" }];

export const stripeCardFields = [
    { heading: "Brand", data: "brand", type: "text" },
    { heading: "CardNoLast4", data: "last4", type: "text" },
    { heading: "CardHolder", data: "name", type: "text" },
    { heading: "Funding", data: "funding", type: "text" },
    { heading: "Default", data: "default", type: "boolean" }
];

export const tilledCardFields = [
    { heading: "Brand", data: "card.brand", type: "text" },
    { heading: "CardNoLast4", data: "card.last4", type: "text" },
    { heading: "CardHolder", data: "nick_name", type: "text" },
    { heading: "Funding", data: "card.funding", type: "text" }
    // { heading: "Default", data: "card.default", type: "boolean" }
];

export const logFields = [
    { heading: "Name", data: "name", type: "link", link: "/admin/logs/:_id" },
    { heading: "Size (KiB)", data: "size", type: "text" },
    { heading: "Path", data: "path", type: "text" }
];

export const notificationMassFields = [
    { heading: "Subject", data: "subject", type: "text", validate: "required" },
    {
        heading: "Recipient",
        data: "recipient",
        type: "checkboxs",
        options: [
            { heading: "Admins", data: "admins" },
            { heading: "Managers", data: "managers" },
            { heading: "Vendors", data: "vendors" },
            { heading: "Users", data: "users" }
        ],
        default: {}
    },
    { heading: "Email", data: "template", type: "editor", wordList: ["${RECEIVER_NAME}", "${RECEIVER_EMAIL}"], validate: "required" }
];

export const orderNoteFields = [
    { heading: "Booking Note", data: "note", type: "textarea" },
    { heading: "Booking Comment (Private not visible to customer)", data: "comment", type: "textarea" }
];
export const orderEmailFields = [
    { heading: "To:", data: "to", type: "text", validate: "required" },
    { heading: "From:", data: "from", type: "readonly", validate: "required" },
    { heading: "Reply To:", data: "replyto", type: "readonly", validate: "required" },
    { heading: "Subject:", data: "subject", type: "readonly", validate: "required" },
    { heading: "CC:", data: "cc", type: "text" },
    { heading: "Message:", data: "html", type: "editor" },
    { heading: "Include invoice in e-mail", data: "isIncludeInvoice", type: "checkbox" },
    { heading: "orderId", data: "orderId", type: "hidden" }
];

export const versionFields = [
    { heading: "Version", data: "version", type: "link", link: "/admin/versions/detail/:_id", validate: "required" },
    { heading: "Hash", data: "hash", type: "text" },
    { heading: "Date", data: "date", type: "datetime", validate: "required" },
    { heading: "Description", data: "description", type: "editor" }
];

export const referralFields = [
    { heading: "Category", data: "category", type: "category", validate: "required" },
    { heading: "Referral", data: "referral", type: "category", validate: "required" },
    { heading: "Acitve", data: "active", type: "boolean", default: true }
];

// qa
export const qaListFields = [
    { heading: "Subject", data: "question", type: "link", link: "/admin/qas/detail/:_id" },

    { heading: "Product", data: "product_name", type: "link", link: "/qa_slug/:product_id" },

    { heading: "Order", data: "order_no", type: "link", link: "/admin/orders/editDetail/:order_id" },

    { heading: "User Name", data: "user_name", type: "text" },
    { heading: "User Email", data: "user_email", type: "text" },

    { heading: "Vendor Name", data: "vendor_name", type: "text" },
    { heading: "Vendor Email", data: "vendor_email", type: "text" },

    { heading: "status", data: "status", type: "text" },
    // { heading: "type", data: "type", type: "text" },
    { heading: "private", data: "private", type: "boolean", action: true },

    { heading: "Created At", data: "createdAt", type: "datetime" }
];

export const qaEditFields = [
    { heading: "Subject", data: "question", type: "readonly" },
    { heading: "Status", data: "status", type: "select", options: ["OPEN", "ANSWERED", "CLOSED"] },
    // { heading: "Type", data: "type", type: "select", options: ["VOID", "PRODUCT", "ORDER"] },
    { heading: "Private", data: "private", type: "boolean" }
];

// article
export const articleListFields = [
    { heading: "Title", data: "title", type: "link", link: "/helpcenter/article/:slug" },
    { heading: "Type", data: "category_parent_name", type: "text" },
    { heading: "Category", data: "category_name", type: "text" },
    { heading: "Category Rank", data: "category_rank", type: "text" },
    { heading: "Article Rank", data: "rank", type: "text" },
    { heading: "Author", data: "user_name", type: "text" },
    { heading: "Created At", data: "createdAt", type: "datetime" },
    { heading: "Updated At", data: "updatedAt", type: "datetime" }
];

export const articleEditFields = [
    { heading: "Title", data: "title", type: "text" },
    { heading: "Type", data: "category_parent_name", type: "select", options: ["Help Topics", "Vendor Help Topics"] },
    { heading: "Category", data: "category_name", type: "autocomplete", fieldName: "name", options: [] },
    { heading: "Category Rank", data: "category_rank", type: "number" },
    { heading: "Rank", data: "rank", type: "number" },
    { heading: "Content", data: "content", type: "editor" }
];

// site
export const siteListFields = [
    { heading: "URL", data: "uri", type: "link", link: ":uri" },
    { heading: "Container Memory", data: "containerMemory", type: "text" },

    { heading: "Status", data: "status", type: "text" },
    { heading: "User Name", data: "user_name", type: "text" },
    { heading: "User Email", data: "user_email", type: "email" },
    { heading: "Deployed At", data: "deployedAt", type: "datetime" },
    { heading: "Created At", data: "createdAt", type: "datetime" }
];

export const siteEditFields = [
    // { heading: "User", data: "user_id", type: "autocomplete", apiList: "users?q=:q", apiGet: ":id" },
    // password
    { heading: "Domain", data: "domain", type: "text" },
    { heading: "Package", data: "package", type: "autocomplete", apiList: "s5/packages?q=:q", apiGet: ":id" }
];

export const siteRouteListFields = [
    //
    { heading: "Sub Domain / Domain Name", data: "path", type: "text" },
    { heading: "SslCert", data: "sslCert", type: "text" }
];

export const siteRouteEditFields = [
    //
    { heading: "Sub Domain / Domain Name", data: "path", type: "text", default: "" }
    // { heading: "sslCert", data: "sslCert", type: "textarea" }
];

export const siteSignupFields = [
    { heading: "Org Name", data: "orgName", type: "text" },
    { heading: "Email", data: "email", type: "text", validate: "email|required" },
    { heading: "Password", data: "password", type: "password", validate: "required|secure-password", confirm: true },
    { heading: "Fist Name", data: "firstName", type: "text" },
    { heading: "Last Name", data: "lastName", type: "text" },
    { heading: "Domain", data: "domain", type: "text" }
];

export const emailListFields = [
    //
    { heading: "To", data: "to", type: "text" },
    { heading: "Subject", data: "subject", type: "link", link: "/admin/orders/email-detail/:orderId?emailId=:_id" },
    { heading: "Created At", data: "createdAt", type: "datetime" }
];
