<template>
    <div class="searchBarMain">
        <md-icon class="searchBarSearchIcon noUserSelect">search</md-icon>
        <input @keyup="search(searchInput)" id="searchBarInput" name="header-search" placeholder="Search, discover, explore..." type="text" v-model="searchInput" value />
        <!-- <md-icon class="clearSearchBarField noUserSelect">mic</md-icon> -->
    </div>
</template>
<script>
export default {
    data() {
        return {
            searchInput: "",
            typingTimer: null
        };
    },
    methods: {
        search(item) {
            clearTimeout(this.typingTimer);
            clearTimeout(this.recordingTimer);
            let vm = this;
            this.typingTimer = setTimeout(function() {
                vm.$router.push("/shop?q=" + item);
            }, 800);
        },
        show() {
            this.searchBar = false;
        }
    }
};
</script>
<style lang="less">
.searchBarMain {
    flex: 100;
    display: flex;
    /* margin: 0 20px; */
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.14);
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;

    i.searchBarSearchIcon {
        /*height: 100%;
    line-height: 42px;
    float: left;
    width: 30px;
    text-align: center;
    color: rgba(68, 68, 68, 0.5);
    margin-left: 11px;*/
        position: relative;
        margin: 11px;
        color: rgba(68, 68, 68, 0.5);
    }

    input#searchBarInput {
        flex: 1;
        border: 0;
        box-sizing: border-box;
        background: transparent;
        outline: none;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.76);
        font-weight: 400;
        font-family: "Roboto", sans-serif;
    }

    i.clearSearchBarField {
        position: relative;
        margin: 11px;
        /* right: 2px; */
        /* margin: 12px 0; */
        color: rgba(0, 0, 0, 0.84);
        font-size: 20px;
    }

    i.clearSearchBarField:hover {
        cursor: pointer;
    }
}
</style>