<template>
    <div class="date-time-picker" :class="mode">
        <md-input-container @click="show">
            <date-picker @change="datePickerChange" ref="dp" :date="{time:date}" :option="op" :limit="_limit"></date-picker>
        </md-input-container>
        <md-input-container>
            <md-select v-if="mode=='block'" placeholder="--:--" v-model="hour">
                <md-option value="00:00">AM 0:00 - AM 2:00</md-option>
                <md-option value="01:00">AM 1:00 - AM 3:00</md-option>
                <md-option value="02:00">AM 2:00 - AM 4:00</md-option>
                <md-option value="03:00">AM 3:00 - AM 5:00</md-option>
                <md-option value="04:00">AM 4:00 - AM 6:00</md-option>
                <md-option value="05:00">AM 5:00 - AM 7:00</md-option>
                <md-option value="06:00">AM 6:00 - AM 8:00</md-option>
                <md-option value="07:00">AM 7:00 - AM 9:00</md-option>
                <md-option value="08:00">AM 8:00 - AM 10:00</md-option>
                <md-option value="09:00">AM 9:00 - AM 11:00</md-option>
                <md-option value="10:00">AM 10:00 - PM 12:00</md-option>
                <md-option value="11:00">AM 11:00 - PM 1:00</md-option>
                <md-option value="12:00">PM 12:00 - PM 2:00</md-option>
                <md-option value="13:00">PM 1:00 - PM 3:00</md-option>
                <md-option value="14:00">PM 2:00 - PM 4:00</md-option>
                <md-option value="15:00">PM 3:00 - PM 5:00</md-option>
                <md-option value="16:00">PM 4:00 - PM 6:00</md-option>
                <md-option value="17:00">PM 5:00 - PM 7:00</md-option>
                <md-option value="18:00">PM 6:00 - PM 8:00</md-option>
                <md-option value="19:00">PM 7:00 - PM 9:00</md-option>
                <md-option value="20:00">PM 8:00 - PM 10:00</md-option>
                <md-option value="21:00">PM 9:00 - PM 11:00</md-option>
                <md-option value="22:00">PM 10:00 - AM 0:00</md-option>
                <md-option value="23:00">PM 11:00 - AM 1:00</md-option>
            </md-select>
            <md-select v-else placeholder="--:--" v-model="hour">
                <md-option value="00:00">AM 0:00</md-option>
                <md-option value="01:00">AM 1:00</md-option>
                <md-option value="02:00">AM 2:00</md-option>
                <md-option value="03:00">AM 3:00</md-option>
                <md-option value="04:00">AM 4:00</md-option>
                <md-option value="05:00">AM 5:00</md-option>
                <md-option value="06:00">AM 6:00</md-option>
                <md-option value="07:00">AM 7:00</md-option>
                <md-option value="08:00">AM 8:00</md-option>
                <md-option value="09:00">AM 9:00</md-option>
                <md-option value="10:00">AM 10:00</md-option>
                <md-option value="11:00">AM 11:00</md-option>
                <md-option value="12:00">PM 12:00</md-option>
                <md-option value="13:00">PM 1:00</md-option>
                <md-option value="14:00">PM 2:00</md-option>
                <md-option value="15:00">PM 3:00</md-option>
                <md-option value="16:00">PM 4:00</md-option>
                <md-option value="17:00">PM 5:00</md-option>
                <md-option value="18:00">PM 6:00</md-option>
                <md-option value="19:00">PM 7:00</md-option>
                <md-option value="20:00">PM 8:00</md-option>
                <md-option value="21:00">PM 9:00</md-option>
                <md-option value="22:00">PM 10:00</md-option>
                <md-option value="23:00">PM 11:00</md-option>
            </md-select>
        </md-input-container>
    </div>

</template>
<script>
import moment from "moment-timezone";
export default {
    props: {
        value: Object | String | Date,
        limit: Array,
        mode: String,
        valueFormat: {
            type: String,
            default: "YYYY-MM-DD HH:mm"
        }
    },
    data() {
        return {
            time: null,
            date: "",
            hour: ""
        };
    },
    computed: {
        op() {
            return {
                type: "day",
                placeholder: "YYYY-MM-DD",
                week: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
                month: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                format: "YYYY-MM-DD"
            };
        },
        _limit() {
            var unavailTime = [];
            unavailTime.push({
                type: "fromto",
                from: moment().add(-1, "days")
            });
            return this.limit || unavailTime;
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(n, o) {
                if (moment(n).diff(moment(o))) {
                    this.time = moment(this.value);
                    this.time.startOf("hour");
                    var time = this.time.format("YYYY-MM-DD HH:mm");
                    this.date = time.split(" ")[0];
                    this.hour = time.split(" ")[1];
                    if (this.valueFormat == "moment") {
                        this.$emit("input", this.time);
                    } else {
                        this.$emit("input", this.time.format(this.valueFormat));
                    }
                }
            }
        },
        date() {
            var m = moment(this.date + " " + this.hour);
            if (this.valueFormat == "moment") {
                this.$emit("input", m);
            } else {
                this.$emit("input", m.format(this.valueFormat));
            }
        },
        hour() {
            var m = moment(this.date + " " + this.hour);
            if (this.valueFormat == "moment") {
                this.$emit("input", m);
            } else {
                this.$emit("input", m.format(this.valueFormat));
            }
        }
    },
    methods: {
        datePickerChange(date) {
            this.date = date;
        },
        show() {}
    },
    mounted() {}
};
</script>
<style lang="less">
.date-time-picker {
    display: flex;
    position: relative;
    width: 180px;

    .md-input-container {
        flex: 1;
        overflow: hidden;
    }
    &.block {
        .md-input-container:nth-child(2) {
            flex: 2;
        }
    }

    .md-select {
        .md-select-value {
            font-size: 12px;
            text-align: center;
            padding-right: 0;
        }
        &:after {
            display: none;
        }
    }

    .cov-vue-date input {
        font-size: 12px;
        cursor: pointer;
    }

    .cov-vue-date input::-webkit-input-placeholder {
        font-size: 12px;
    }

    .cov-vue-date .unavailable {
        color: #eee;
    }

    .cov-vue-date .passive-day {
        color: #eee;
        opacity: 0;
        cursor: inherit;
        pointer-events: none;
    }

    input {
        color: rgba(0, 0, 0, 0.87);
    }
}
</style>