import "babel-polyfill";
import "vue-material/dist/vue-material.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "@/theme.less";
import moment from "moment-timezone";
import axios from "axios";
import Vue from "vue";
import VueQuillEditor from "vue-quill-editor";
import VeeValidate from "vee-validate";
import VueSweetAlert from "vue-sweetalert";
import VueMaterial from "vue-material";
import "@/config";
import App from "@/App";
import router from "@/router";
import "@/filters";
import "@/validators";
import { store } from "@/store";

import AlertCmp from "@/components/Alert.vue";
import myDatepicker from "@/components/VueDatePicker";

if (/pro/.test(process.NODE_ENV)) {
    Vue.config.devtools = false;
    Vue.config.debug = false;
    Vue.config.silent = true;
    Vue.config.productionTip = false;
}

// moment.tz.setDefault(store.getters.tz);
window.moment = moment;
Vue.use(VueQuillEditor);
Vue.use(VeeValidate);
Vue.use(VueSweetAlert);
Vue.use(VueMaterial);
Vue.material.setCurrentTheme("none");

Vue.component("app-alert", AlertCmp);
Vue.component("date-picker", myDatepicker);

const resizeEvt = "orientationchange" in window ? "orientationchange" : "resize";
const recalc = function() {
    const { clientWidth } = document.documentElement;
    if (!clientWidth) return;
    store.state.clientWidth = clientWidth;
};
window.addEventListener(resizeEvt, recalc, false);
document.addEventListener("DOMContentLoaded", recalc, false);

axios.interceptors.request.use(
    function(config) {
        if (!navigator.onLine) {
            if (config.method != "get") {
                store.commit("setMessage", "You are offline. Data not saved.", { root: true });
                throw config;
            }
        }
        axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";
        store.commit("setLoading", true, { root: true });
        return config;
    },
    function(error) {
        store.commit("setLoading", false, { root: true });
    }
);

axios.interceptors.response.use(
    function(response) {
        store.commit("setLoading", false, { root: true });
        if (self != top) {
            setTimeout(() => {
                store.commit("postParentMessage", {
                    type: "info",
                    height: document.body.offsetHeight
                });
            }, 50);
        }
        return response;
    },
    function(err) {
        if (!navigator.onLine) {
            console.info(err.message);
            store.commit("setMessage", "You are offline. Please go online to continue", { root: true });
            throw err;
        }
        if (err.response) {
            if (err.response.data) {
                var message = err.response.data.message || err.response.data;
                store.commit("setMessage", message, { root: true });
            }
            if (/Please set service locations/i.test(err.response.data)) {
                router.push("/account/vendor/location");
            }
            if (/Please add your card first/i.test(err.response.data)) {
                router.push("/admin/stripe/cards/new");
            }
            if (err.response.status === 401) {
                store.commit("setMessage", "Please login to continue", { root: true });
                store.dispatch("user/logout");
            } else if (err.response.status === 404) {
                store.commit("setMessage", "Request URI Not Found", { root: true });
            }
        }
        throw err;
    }
);

const eventHub = new Vue();
Vue.mixin({
    data: function() {
        return {
            eventHub
        };
    }
});
new Vue({
    el: "#app",
    router,
    store,
    render: h => h(App)
});

let metatheme = document.querySelector("meta[name='theme-color']");
metatheme.setAttribute("content", "white");

// if (module.hot) {
//     var hotEmitter = require("webpack/hot/emitter");
//     hotEmitter.on("webpackHotUpdate", function(currentHash) {
//         console.info(currentHash);
//         document.querySelectorAll("link[href][rel=stylesheet]").forEach(link => {
//             if (!/googleapis/.test(link.href)) {
//                 var csshash = link.href.match(/\.([^\.]*)\.css/)[1];
//                 var newhash = (currentHash + "").substring(0, 6);
//                 const nextStyleHref = link.href.replace(csshash, newhash);
//                 link.href = nextStyleHref;
//             }
//         });
//     });
// }
