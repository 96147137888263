<template>
    <div class="location-select">
        <md-input-container>
            <div class="label">
                <md-icon>location_on</md-icon>
                <label for>You're shopping</label>
            </div>
            <span @click="openDialog" class="value">{{location ? location.county : 'Select Location'}}</span>
            <input name type="hidden" />
        </md-input-container>

        <md-dialog class="location-modal" ref="location-dialog">
            <md-dialog-title>Select Your Location</md-dialog-title>
            <md-dialog-content>
                <md-input-container>
                    <label for>Country</label>
                    <md-select v-model="country">
                        <md-option :key="c" :value="c" v-for="c in countries">{{c}}</md-option>
                    </md-select>
                </md-input-container>
                <md-input-container>
                    <label for>State</label>
                    <md-select v-model="state">
                        <md-option :key="s" :value="s" v-for="s in states">{{s}}</md-option>
                    </md-select>
                </md-input-container>
                <md-input-container>
                    <label for>County</label>
                    <md-select v-model="county">
                        <md-option :key="c" :value="c" v-for="c in counties">{{c}}</md-option>
                    </md-select>
                </md-input-container>
            </md-dialog-content>
            <md-dialog-actions>
                <md-button @click="closeDialog">Cancel</md-button>
                <md-button @click="submit" class="md-primary">Confirm</md-button>
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { locationFields } from "@/config";
export default {
    data() {
        return {
            country: "",
            state: "",
            county: ""
        };
    },
    computed: {
        ...mapGetters({
            location: "location/location",
            locations: "location/locations",
            vendorMode: "vendorMode"
        }),
        countries() {
            return Array.from(new Set(this.locations.map(l => l.country))).sort(function(a, b) {
                return a > b ? 1 : -1;
            });
        },
        states() {
            return Array.from(new Set(this.locations.filter(l => l.country == this.country).map(l => l.state))).sort(function(a, b) {
                return a > b ? 1 : -1;
            });
        },
        counties() {
            let counties = Array.from(new Set(this.locations.filter(l => l.state == this.state).map(l => l.county))).sort(function(a, b) {
                return a > b ? 1 : -1;
            });
            return counties;
        }
    },
    watch: {
        country() {
            this.state = "";
        },
        state() {
            this.county = "";
        }
    },
    methods: {
        ...mapActions({
            setLocation: "location/setLocation"
        }),
        setSelect() {
            if (this.location) {
                this.country = this.location.country;
                setTimeout(() => {
                    this.state = this.location.state;
                    setTimeout(() => {
                        this.county = this.location.county;
                    }, 0);
                }, 0);
            }
        },
        submit() {
            if (!this.county) {
                this.$store.commit("setMessage", "Please set your county");
            }

            let location = this.locations.find(l => l.country == this.country && l.state == this.state && l.county == this.county);
            if (!location) {
                return this.$store.commit("setMessage", "Please Select the County");
            }
            this.setLocation(location._id);
            this.closeDialog();
            this.$store.commit("setMessage", "Change Location Success");
        },
        openDialog() {
            this.$refs["location-dialog"].open();
        },
        closeDialog() {
            this.$refs["location-dialog"].close();
        }
    },
    async mounted() {
        await this.$store.dispatch("location/loadLocations");

        this.setSelect();
    }
};
</script>
<style lang="less">
.location-select {
    margin-right: 20px;
    padding-left: 4px;
    text-align: center;
    // .md-icon {
    //     color: orange !important;
    // }
    .label {
        white-space: nowrap;
    }
    label {
        position: relative;
        top: 0 !important;
        left: 0 !important;
        line-height: 32px;
        // color: orange !important;
        white-space: nowrap;
    }
    .md-select {
        margin-left: 4px;
        min-width: 20px;
        &:after {
            top: 45%;
        }
    }
    .md-select-value {
        // color: #5d5d5d;
        font-size: 12px;
        padding-right: 12px;
    }
    .md-input-container {
        .value {
            font-weight: bold;
            display: inline-block;
            min-width: 10px;
            margin-left: 4px;
            margin-right: 10px;
            line-height: 34px;
            // color: #5d5d5d;
            font-size: 12px;
            white-space: nowrap;
            cursor: pointer;
        }
    }
}
.location-modal {
    .md-dialog {
        min-width: 600px;
    }
    @media (max-width: 600px) {
        .md-dialog {
            min-width: 320px;
        }
    }
}

.vendorMode {
    .location-select {
        .md-icon {
            color: #38f !important;
        }
        label {
            color: #38f !important;
        }
    }
}

@media (max-width: 600px) {
    .location-select {
        margin-right: 0;
        label {
            font-size: 12px;
        }
    }

    .location-modal {
        .md-dialog {
            min-width: 320px;
        }
    }
}
</style>


