<template>
  <div>
    <section v-if="!checkCart({id:product._id, vid:variant._id})">
      <md-button class="md-primary md-raised" @click="editCart('add')" alt="book">
        <md-icon>shopping_cart</md-icon>Book Now
      </md-button>
    </section>
    <section v-if="checkCart({id:product._id, vid:variant._id})">
      <md-button class="md-primary md-icon-button" @click="editCart('reduce')" alt="remove">
        <md-icon style="margin:auto">remove</md-icon>
      </md-button>
      <input class="cart-number-input" type="number" :value="quantity" @change="handleCartNumChange">
      <md-button class="md-primary md-icon-button" @click="editCart('add')" alt="add">
        <md-icon style="margin:auto">add</md-icon>
      </md-button>
    </section>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
import moment from "moment-timezone";
export default {
    props: ["product", "variant"],
    components: {},
    data() {
        return {
            timestamp: +new Date()
        };
    },
    methods: {
        ...mapActions({
            addToCart: "cart/addToCart"
        }),
        async handleAddOnItemsChange() {
            await this.addToCart({ product: this.product, variant: this.variant, quantity: this.quantity, rentalStartDate: this.startDate, rentalEndDate: this.endDate });
            this.timestamp = +new Date();
        },
        handleCartNumChange(e) {
            const { value } = e.currentTarget;
            this.editCart(+value);
        },
        async editCart(type) {
            let quantity = isNaN(this.quantity) ? 0 : this.quantity;
            switch (type) {
                case "add":
                    quantity++;
                    break;
                case "reduce":
                    quantity--;
                    break;
                default:
                    break;
            }
            if (!isNaN(+type)) {
                quantity = type;
            }
            await this.addToCart({ product: this.product, variant: this.variant, quantity, rentalStartDate: this.startDate, rentalEndDate: this.endDate });
            this.timestamp = +new Date();
        }
    },
    computed: {
        quantity() {
            let tag = this.timestamp;
            return this.getQuantity({ _id: this.product._id, vid: this.variant._id });
        },
        startDate() {
            var item = this.cartItems.find(item => item._id == this.product._id && item.vid == this.variant._id);
            return item && item.rentalStartDate;
        },
        endDate() {
            var item = this.cartItems.find(item => item._id == this.product._id && item.vid == this.variant._id);
            return item && item.rentalEndDate;
        },
        ...mapState({
            cartItems: state => state.cart.items
        }),
        ...mapGetters({
            cartProducts: "cartProducts",
            itemsQuantity: "itemsQuantity",
            total: "total",
            checkCart: "cart/checkCart",
            getQuantity: "cart/getQuantity"
        })
    }
};
</script>
<style lang="less">
.cart-number-input {
    display: inline-block;
    width: 60px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    padding: 0;
    margin-top: 5px;
}
</style>
