<template>
    <div class="category-select">
        <md-select @selected="categorySelected" class="category-select-item" v-model="selectedL1">
            <md-option :key="c._id" :value="c._id" v-for="c in l1Categories">{{ c.name }}</md-option>
        </md-select>
        <md-select @selected="categorySelected" class="category-select-item" v-if="l2Categories.length" v-model="selectedL2">
            <md-option :key="c._id" :value="c._id" v-for="c in l2Categories">{{ c.name }}</md-option>
        </md-select>
        <md-select @selected="categorySelected" class="category-select-item" v-if="l3Categories.length" v-model="selectedL3">
            <md-option :key="c._id" :value="c._id" v-for="c in l3Categories">{{ c.name }}</md-option>
        </md-select>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    props: ["value"],
    data() {
        return {
            selectedL1: "",
            selectedL2: "",
            selectedL3: ""
        };
    },
    watch: {
        value(n, o) {
            if (!o && n) {
                this.initSelectedCategories();
            }
        }
    },
    computed: {
        ...mapGetters({
            categories: "category/categories",
            groupCategories: "category/groupCategories"
        }),
        l1Categories() {
            let result = [{}].concat(this.groupCategories || []);
            return result;
        },
        l2Categories() {
            let l1Category = this.l1Categories.find(c => c._id == this.selectedL1);
            let result = l1Category && l1Category._id ? l1Category.children : [];
            return result;
        },
        l3Categories() {
            let l2Category = this.l2Categories.find(c => c._id == this.selectedL2);
            let result = l2Category ? l2Category.children : [];
            return result;
        }
    },
    methods: {
        async initSelectedCategories() {
            let category = this.categories.find(c => c._id == this.value);
            if (category) {
                let ls = {
                    l1: "",
                    l2: "",
                    l3: ""
                };
                ls[`l${category.level}`] = category;
                ls[`l${category.level - 1}`] = this.categories.find(c => category.parent && c._id == category.parent._id);
                ls[`l${category.level - 2}`] = this.categories.find(c => ls[`l${category.level - 1}`] && ls[`l${category.level - 1}`].parent && c._id == ls[`l${category.level - 1}`].parent._id);
                this.selectedL1 = ls["l1"] && ls["l1"]._id;
                this.selectedL2 = ls["l2"] && ls["l2"]._id;
                this.selectedL3 = ls["l3"] && ls["l3"]._id;
            }
        },
        async categorySelected(val) {
            let category = this.categories.find(c => c._id == val);
            if (!category) {
                this.$emit("input", null);
                return;
            }

            if (category.level == 1) {
                this.selectedL2 = "";
                this.selectedL3 = "";
            }

            if (category.level == 2) {
                this.selectedL3 = "";
            }

            //when selecting a no parent category
            this.$emit("input", val);
            // if (!this.categories.find(c => c.parent && c.parent._id == val)) {
            //     this.$emit("input", val);
            // } else {
            //     this.$emit("input", null);
            // }
        }
    },
    async created() {
        await this.$store.dispatch("category/loadCategories");
        this.initSelectedCategories();
    }
};
</script>

<style lang="less">
.category-select {
    display: flex;
    .category-select-item {
        width: 150px;
        margin-right: 10px;
    }
}
</style>
