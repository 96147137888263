<template>
    <div class="account-login">
        <edit :fs="fs" @saved="saved" apiSave="/auth/local" customClassName="none" title="Login">
            <template slot="submit" slot-scope="slotProps">
                <div class="submit-container">
                    <md-button @click="goForget">Forgot Password?</md-button>
                    <md-button @click="go('/account/signup')">Signup</md-button>
                    <!-- <router-link tag="md-button" to="/account/vendor/signup">Vendor Signup</router-link> -->
                    <submit :loading="slotProps.loading" icon="fingerprint" text="Login"></submit>
                </div>
            </template>
        </edit>
    </div>
</template>

<script>
import Edit from "@/components/Edit";
import Submit from "@/components/Submit";
import { mapActions, mapGetters } from "vuex";

export default {
    props: ["referrer", "inModal"],
    components: {
        Edit,
        Submit
    },
    data() {
        return {
            from: ""
        };
    },

    computed: {
        ...mapGetters({
            vendorMode: "vendorMode"
        }),
        fs() {
            let isDev = /dev/.test(process.env.NODE_ENV);
            return [
                //
                { heading: "Email", data: "email", type: "text", validate: "required|email", default: isDev ? "471060995@qq.com" : "" },
                { heading: "Password", data: "password", type: "password", validate: "required", default: isDev ? "s1c2r3d4" : "" }
            ];
        }
    },
    methods: {
        go(uri) {
            if (this.inModal) {
                this.eventHub.$emit("closeLogin");
            }

            this.$router.push(uri);
        },
        goForget() {
            if (this.inModal) {
                this.$emit("goForget");
            } else {
                this.go("/account/forgot-password");
            }
        },
        async saved(result) {
            if (result) {
                result.user.token = result.token;
                if (this.inModal) {
                    this.eventHub.$emit("loginSuccess", result);
                    this.eventHub.$emit("closeLogin");
                }

                this.$store.commit("user/setUser", result.user);
                this.$store.commit("setMessage", "Login Success");

                let user = result.user;

                this.paymentSetting = await this.$store.dispatch("setting/refreshPayment");

                // check required steps
                // all vendors
                if (user.role == "vendor") {
                    if (user.locations.length == 0) {
                        this.$router.push("/account/vendor/location");
                        this.$store.commit("setMessage", "Please set service locations first");
                        return;
                    }
                }

                // master vendor
                if (user.role == "vendor" && !user.master_vendor_id) {
                    if (this.paymentSetting.value.use_tilled) {
                        if (!user.tilledId) {
                            this.$router.push("/admin/EzTimePay/cards/new");
                            this.$store.commit("setMessage", "Please add your card first");
                            return;
                        }
                        if (!user.tilledConnectId) {
                            this.$router.push("/account/profile");
                            this.$store.commit("setMessage", "Please connect EzTimePay account");
                            return;
                        }
                    } else if (this.paymentSetting.value.use_stripe) {
                        if (!user.stripeId) {
                            this.$router.push("/admin/stripe/cards/new");
                            this.$store.commit("setMessage", "Please add your card first");
                            return;
                        }
                        if (!user.stripeConnectId) {
                            this.$router.push("/account/profile");
                            this.$store.commit("setMessage", "Please connect stripe account");
                            return;
                        }
                    }
                }

                if (this.$route.path == "/account/login") {
                    this.$router.push(this.from || "/");
                }
            }
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.from = from;
        });
    }
};
</script>
<style lang="less">
.account-login {
    margin: auto;
    max-width: 1000px;
    min-height: 700px;
    .md-card {
        max-width: 1000px;
        margin: auto;

        .md-card-header {
            margin: 0;
        }
    }
    .submit-container {
        display: flex;
        justify-content: flex-end;
        // flex-wrap: wrap;
        padding: 5px;
        .md-button {
            padding: 0 5px;
            min-width: 60px;
        }
        .submit-button {
            display: inline-block;
            .md-button {
                min-width: 88px;
            }
        }
    }
}
</style>
