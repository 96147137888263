<template>
    <div class="shop-footer">
        <ul>
            <li>
                <a aria-label="facebook" class="facebook icon" href="https://www.facebook.com/eztimerental" rel="noopener" target="_blank"></a>
            </li>
            <!-- <li>
                <a aria-label="google" class="google icon" href="http://google.com/+Eztimerental" rel="noopener" target="_blank"></a>
            </li> -->
            <li>
                <a aria-label="instagram" class="instagram icon" href="https://www.instagram.com/eztimerental/" rel="noopener" target="_blank"></a>
            </li>
            <li>
                <a aria-label="twitter" class="twitter icon" href="http://twitter.com/eztimerental" rel="noopener" target="_blank"></a>
            </li>
            <li>
                <a aria-label="yelp" class="yelp icon" href="https://www.yelp.com/biz/ez-time-rental-west-hempstead" rel="noopener" target="_blank"></a>
            </li>
        </ul>
        <ul>
            <li>
                <a alt="eztimerental" href="#">EzTimeRental</a>
            </li>
            <li>|</li>
            <li>
                <router-link to="/terms-of-service">Terms of Service</router-link>
            </li>
            <li>|</li>
            <li>
                <a @click="openQAQuestionModal()" href="#">Contact Us</a>
            </li>
        </ul>
        <ul>
            <li>Copyright © {{ year }} Ez Time Rental, LLC</li>
        </ul>
        <ul>
            <li>All Rights Reserved</li>
        </ul>
        <!-- <contact ref="contact"></contact> -->
        <q-a-question-modal ref="qaQuestionModal" title="Contact Us"></q-a-question-modal>
    </div>
</template>
<script>
import QAQuestionModal from "@/components/QA/QAQuestionModal.vue";
// import Contact from "@/components/modal/Contact";
export default {
    data() {
        return {
            year: new Date().getFullYear(),
        };
    },
    components: {
        // Contact,
        QAQuestionModal,
    },
    methods: {
        contactUs() {
            this.$refs.contact.open();
        },
        openQAQuestionModal() {
            this.$refs["qaQuestionModal"].open();
        },
    },
};
</script>

<style lang="less">
.shop-footer {
    box-shadow: 0 1px 11px 0 rgba(0, 0, 0, 0.1);
    padding: 10px 0;

    .icon {
        display: block;
        width: 32px;
        height: 32px;
        &.facebook {
            background: url("/static/foot-nav/f.png") no-repeat center center / 100% auto;
        }
        &.google {
            background: url("/static/foot-nav/g.png") no-repeat center center / 100% auto;
        }
        &.instagram {
            background: url("/static/foot-nav/instagram.png") no-repeat center center / 100% auto;
        }
        &.twitter {
            background: url("/static/foot-nav/twitter.png") no-repeat center center / 100% auto;
        }
        &.yelp {
            background: url("/static/foot-nav/yelp.png") no-repeat center center / 100% auto;
        }
    }

    ul {
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #999;

        li {
            list-style: none;
            margin: 0 5px !important;
            a {
                opacity: 0.8;

                transition: opacity 0.3s ease;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }

    // .sb {
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     margin-left: -35px;
    //     color: #999;
    // }

    // li {
    //     margin: 0 5px !important;
    // }

    // li a {
    //     color: #999;
    //     text-decoration: underline;
    // }

    // li {

    //     margin-top: 0;
    // }
}
</style>