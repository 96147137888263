<template>
    <div>
        <md-snackbar md-position="bottom center" ref="snackbar" :md-duration="duration">
            <pre>{{text}}</pre>
            <md-button class="md-accent" @click="$refs.snackbar.close()">{{buttonText}}</md-button>
        </md-snackbar>
    </div>
</template>

<script>
export default {
  data: () => ({
    text: "Error.",
    buttonText: "OK",
    duration: 4000
  }),
  methods: {
    open(text, buttonText, duration) {
      this.text = text || this.text;
      this.buttonText = buttonText || this.buttonText;
      this.duration = duration || this.duration;
      this.$refs.snackbar.open();
    },
    close(ref) {
      this.$refs[ref].close();
    }
  }
};
</script>