const state = {
    headline1: "",
    headline2: "",
    description: "",
    keywords: []
};
const getters = {};
const actions = {
    set({ state, commit }, payload) {
        commit("set", payload);
    },
    setDefault({ state, commit }, payload) {
        commit("set");
    }
};
const mutations = {
    set(state, payload) {
        payload = Object.assign(
            {
                headline1: "Your Party Rental Source",
                headline2: "EzTimeRental",
                description: "Tents, Tables, Chairs, Inflatables. Services And More! Choose Products Or Services From Multiple Vendors To Fulfill Your Next Event Today!",
                keywords: ["Party Rental"]
            },
            payload
        );
        if (payload.keywords != undefined) {
            state.keywords = payload.keywords;
            let meta = document.querySelector("meta[name=keywords]");
            meta && meta.setAttribute("content", state.keywords.join(","));
        }
        if (payload.description != undefined) {
            state.description = payload.description;
            let meta = document.querySelector("meta[name=description]");
            meta && meta.setAttribute("content", state.description);
        }
        if (payload.headline1 != undefined) {
            state.headline1 = payload.headline1;
            document.title = `${state.headline1} | ${state.headline2}`;
        }
        if (payload.headline2 != undefined) {
            state.headline2 = payload.headline2;
            document.title = `${state.headline1} | ${state.headline2}`;
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
