import Vue from "vue";
import Router from "vue-router";
import { store } from "@/store";

const Home = () => import("@/components/Home");
const Checkout = () => import("@/components/Checkout");
const Success = () => import("@/components/Success");
const NotFound = () => import("@/components/NotFound");
const AdminIndex = () => import("@/components/AdminIndex");

const AddressIndex = () => import("@/components/Address/AddressIndex");

const ProductDetail = () => import("@/components/Product/ProductDetail");
const ProductIndex = () => import("@/components/Product/ProductIndex");

const PackageIndex = () => import("@/components/Product/PackageIndex");

const ReferralIndex = () => import("@/components/Referral/ReferralIndex");

const BrandList = () => import("@/components/Brand/BrandList");
const BrandEdit = () => import("@/components/Brand/BrandEdit");

const FeatureList = () => import("@/components/Feature/FeatureList");
const FeatureEdit = () => import("@/components/Feature/FeatureEdit");

const CategoryIndex = () => import("@/components/Category/CategoryIndex");

const LocationIndex = () => import("@/components/Location/LocationIndex");
const LocationVendorSetting = () => import("@/components/Location/LocationVendorSetting");

const CouponList = () => import("@/components/Coupon/CouponList");
const CouponEdit = () => import("@/components/Coupon/CouponEdit");

const OrderIndex = () => import("@/components/Order/OrderIndex");
const OrderMy = () => import("@/components/Order/OrderMy");
const OrderEditDetail = () => import("@/components/Order/OrderEditDetail");
const OrderPDFDetail = () => import("@/components/Order/OrderPDFDetail");
const OrderDetail = () => import("@/components/Order/OrderDetail");

const ReviewMy = () => import("@/components/Review/ReviewMy");
const ReviewList = () => import("@/components/Review/ReviewList");

const ShippingList = () => import("@/components/Shipping/ShippingList");
const ShippingEdit = () => import("@/components/Shipping/ShippingEdit");

const WishList = () => import("@/components/Wish/WishList");

const UserList = () => import("@/components/User/UserList");
const UserEdit = () => import("@/components/User/UserEdit");
const UserBranchIndex = () => import("@/components/User/UserBranchIndex");

const PageIndex = () => import("@/components/Page/PageIndex");
const Page = () => import("@/components/Page/Page");

const LogIndex = () => import("@/components/Log/LogIndex");
const VersionIndex = () => import("@/components/Version/VersionIndex");
const VersionDetail = () => import("@/components/Version/VersionDetail");

const NotificationIndex = () => import("@/components/Notification/NotificationIndex");

const AccountLogin = () => import("@/components/Account/AccountLogin");
const AccountSignup = () => import("@/components/Account/AccountSignup");
const AccountForget = () => import("@/components/Account/AccountForget");
const AccountChangePassword = () => import("@/components/Account/AccountChangePassword");
const AccountReset = () => import("@/components/Account/AccountReset");
const AccountProfile = () => import("@/components/Account/AccountProfile");
const AccountConnect = () => import("@/components/Account/AccountConnect");
const AccountUpdateEmail = () => import("@/components/Account/AccountUpdateEmail");

const CartPage = () => import("@/components/Cart/CartPage");

const VendorProfile = () => import("@/components/Vendor/VendorProfile");
const VendorSignup = () => import("@/components/Vendor/VendorSignup");
const VendorVacationIndex = () => import("@/components/Vendor/VendorVacationIndex");
const VendorAppFee = () => import("@/components/Vendor/VendorAppFee");
const VendorProfileIndex = () => import("@/components/Vendor/VendorProfileIndex");

const PaymentCardIndex = () => import("@/components/Payment/PaymentCardIndex");
const StripeCardIndex = () => import("@/components/Stripe/StripeCardIndex");
const TilledCardIndex = () => import("@/components/Tilled/TilledCardIndex");

const SiteIndex = () => import("@/components/Site/SiteIndex");
const SiteSignup = () => import("@/components/Site/SiteSignup");
const SiteSignin = () => import("@/components/Site/SiteSignin");

const TermsOfService = () => import("@/components/TermsOfService");
const SettingTermsOfService = () => import("@/components/Setting/SettingTermsOfService");

const VendorTermsOfService = () => import("@/components/Vendor/VendorTermsOfService");
const SettingVendorTermsOfService = () => import("@/components/Setting/SettingVendorTermsOfService");

const GetFinancing = () => import("@/components/GetFinancing");
const SettingGetFinancing = () => import("@/components/Setting/SettingGetFinancing");
const SettingPayment = () => import("@/components/Setting/SettingPayment");
const SettingUseChat = () => import("@/components/Setting/SettingUseChat");
const SettingBanner = () => import("@/components/Setting/SettingBanner");
const SettingGoogleAnalytics = () => import("@/components/Setting/SettingGoogleAnalytics");
const SettingSendgrid = () => import("@/components/Setting/SettingSendgrid");
const SettingTelephone = () => import("@/components/Setting/SettingTelephone");
const SettingShippingFee = () => import("@/components/Setting/SettingShippingFee");
const SettingVendorDisplay = () => import("@/components/Setting/SettingVendorDisplay");

const QAIndex = () => import("@/components/QA/QAIndex");
const QAMyIndex = () => import("@/components/QA/QAMyIndex");

const ArticleHome = () => import("@/components/Article/ArticleHome");
const ArticleIndex = () => import("@/components/Article/ArticleIndex");

const HomepageIndex = () => import("@/components/Homepage/HomepageIndex");

const Test = () => import("@/components/Test");

const ReportIndex = () => import("@/components/Report/ReportIndex");
const ReportBookingLocations = () => import("@/components/Report/ReportBookingLocations");
const ReportMonthlyBookings = () => import("@/components/Report/ReportMonthlyBookings");
const ReportProductSales = () => import("@/components/Report/ReportProductSales");
const ReportTest = () => import("@/components/Report/ReportTest");
const ReportSearchWords = () => import("@/components/Report/ReportSearchWords");
const ReportSearchWordsTable = () => import("@/components/Report/ReportSearchWordsTable");
const ReportSearchWordsChart = () => import("@/components/Report/ReportSearchWordsChart");
const ReportVendorBookingTotals = () => import("@/components/Report/ReportVendorBookingTotals");

const AffiliateSignup = () => import("@/components/Affiliate/AffiliateSignup");
const AffiliateCommisions = () => import("@/components/Affiliate/AffiliateCommisions");

// qa todo
// qa mylist
// qa adminlist
// qa adminedit
// qa detail

Vue.use(Router);
const routes = [
    { path: "/", name: "home", meta: {}, component: Home },
    { path: "/test", name: "test", component: Test },
    { path: "/admin", name: "dashboard", meta: { role: "user", title: "Dashboard" }, component: AdminIndex },

    { path: "/admin/products", name: "products", meta: { role: "staff", title: "All products" }, component: ProductIndex },
    { path: "/admin/products/items", name: "products-items", meta: { role: "staff", title: "All products variants" }, component: ProductIndex, props: route => ({ id: "items" }) },
    { path: "/admin/products/new", name: "add-product", meta: { role: "staff", title: "Add products" }, component: ProductIndex, props: route => ({ id: "new" }) },
    { path: "/admin/products/:id", name: "edit-product", meta: { role: "staff", title: "Edit Product" }, component: ProductIndex },

    { path: "/admin/packages", name: "packages", meta: { role: "staff", title: "All Packages" }, component: PackageIndex },
    { path: "/admin/packages/new", name: "add-package", meta: { role: "staff", title: "Add Package" }, component: PackageIndex, props: route => ({ id: "new" }) },
    { path: "/admin/packages/:id", name: "edit-package", meta: { role: "staff", title: "Edit Package" }, component: PackageIndex },

    { path: "/admin/referrals", name: "referrals", meta: { role: "manager", title: "Referrals" }, component: ReferralIndex },
    { path: "/admin/referrals/new", name: "referral-add", meta: { role: "manager", title: "Add Referral" }, component: ReferralIndex, props: route => ({ id: "new" }) },
    { path: "/admin/referrals/:id", name: "referral-edit", meta: { role: "manager", title: "Edit Referral" }, component: ReferralIndex },

    { path: "/admin/brands", name: "brands", meta: { title: "Setup Allowed Brands", role: "manager" }, component: BrandList },
    { path: "/admin/brands/:id", name: "edit-brand", meta: { role: "manager", title: "Edit a Brand" }, component: BrandEdit },

    { path: "/admin/shippings", name: "shipping", meta: { role: "manager", title: "Setup Deliverys" }, component: ShippingList },
    { path: "/admin/shippings/:id", name: "edit-shipping", meta: { role: "manager", title: "Edit a single Delivery" }, component: ShippingEdit },

    { path: "/admin/features", name: "features", meta: { role: "manager", title: "Product Features" }, component: FeatureList },
    { path: "/admin/features/:id", name: "edit-feature", meta: { role: "manager", title: "Edit Product Feature" }, component: FeatureEdit },

    { path: "/admin/coupons", name: "coupons", meta: { role: "manager", title: "All Shop Coupons" }, component: CouponList },
    { path: "/admin/coupons/:id", name: "edit-coupon", meta: { role: "manager", title: "Create a new Coupon" }, component: CouponEdit },

    { path: "/admin/addresses", name: "address", meta: { role: "user", title: "Address List" }, component: AddressIndex },
    { path: "/admin/addresses/new", name: "add-address", meta: { role: "user", title: "Add Address" }, component: AddressIndex, props: route => ({ id: "new" }) },
    { path: "/admin/addresses/:id", name: "edit-address", meta: { role: "user", title: "Edit Address" }, component: AddressIndex },

    { path: "/admin/orders/my", name: "orders", meta: { role: "user", title: "My Orders" }, component: OrderMy, props: route => ({ id: "my" }) },
    { path: "/admin/orders/manage", name: "manage-orders", meta: { role: "staff", title: "Manage Bookings" }, component: OrderIndex, props: route => ({ id: "manage" }) },
    { path: "/admin/orders/calendar", name: "manage-orders-calendar", meta: { role: "staff", title: "Manage Bookings" }, component: OrderIndex, props: route => ({ id: "calendar" }) },
    { path: "/admin/orders/new", name: "add-order", meta: { role: "staff", title: "Add Order" }, component: OrderIndex, props: route => ({ id: "new" }) },
    { path: "/admin/orders/detail/:id", name: "order-detail", meta: { role: "user", title: "Order Detail" }, component: OrderDetail },
    { path: "/admin/orders/editDetail/:id", name: "order-edit-detail", meta: { role: "staff", title: "Order Detail" }, component: OrderEditDetail },
    { path: "/admin/orders/edit/:id", name: "order-edit", meta: { role: "staff", title: "Order Detail" }, component: OrderEditDetail },
    { path: "/admin/orders/email/:id", name: "order-email", meta: { role: "staff", title: "Order Detail" }, component: OrderEditDetail },
    { path: "/admin/orders/email-list/:id", name: "order-email-list", meta: { role: "staff", title: "Order Detail" }, component: OrderEditDetail },
    { path: "/admin/orders/email-detail/:id", name: "order-email-detail", meta: { role: "staff", title: "Order Detail" }, component: OrderEditDetail },
    { path: "/admin/orders/payment/:id", name: "order-payment", meta: { role: "staff", title: "Order Detail" }, component: OrderEditDetail },
    { path: "/admin/orders/transaction/:id", name: "order-transaction", meta: { role: "staff", title: "Order Detail" }, component: OrderEditDetail },
    { path: "/admin/orders/refund/:id", name: "order-refund", meta: { role: "staff", title: "Order Detail" }, component: OrderEditDetail },
    { path: "/admin/orders/note/:id", name: "order-note", meta: { role: "staff", title: "Order Note" }, component: OrderEditDetail },
    { path: "/admin/orders/test/:id", name: "order-test", meta: { role: "staff", title: "Order Note" }, component: OrderEditDetail },

    { path: "/admin/reviews", name: "all-reviews", meta: { role: "user", title: "My Reviews" }, component: ReviewMy },
    { path: "/admin/reviews/manage", name: "manage-reviews", meta: { role: "staff", title: "Manage Reviews" }, component: ReviewList },

    { path: "/admin/qas/my", name: "my-qa", component: QAMyIndex, meta: { role: "user", title: "My QA" }, props: route => ({ page: "my-list" }) },
    { path: "/admin/qas/my/detail/:id", name: "my-qa-detail", component: QAMyIndex, meta: { role: "user", title: "My QA" }, props: route => ({ page: "detail" }) },
    { path: "/admin/qas/manage", name: "manage-qa", component: QAIndex, meta: { role: "staff", title: "Manage Tickets" }, props: route => ({ page: "list" }) },
    { path: "/admin/qas/edit/:id", name: "manage-qa-edit", component: QAIndex, meta: { role: "staff", title: "Edit Ticket" }, props: route => ({ page: "edit" }) },
    { path: "/admin/qas/detail/:id", name: "manage-qa-detail", component: QAIndex, meta: { title: "Ticket Detail" }, props: route => ({ page: "detail" }) },
    { path: "/admin/qas/conversation", name: "conversation", component: QAIndex, meta: { role: "manager", title: "Conversation" }, props: route => ({ page: "conversation" }) },
    // { path: "/admin/qas/conversation/:id", name: "conversation", component: QAIndex, meta: { role: "manager", title: "Conversation" }, props: route => ({ page: "conversation", route }) },

    { path: "/admin/sites", name: "sites", meta: { role: "manager", title: "All Sites" }, component: SiteIndex, props: route => ({ page: "list" }) },

    { path: "/admin/sites/signin", name: "site-signin", meta: { role: "user", title: "Signin Site" }, component: SiteSignin, props: route => ({ page: "signin" }) },
    { path: "/admin/sites/signup", name: "site-signup", meta: { role: "user", title: "Signup Site" }, component: SiteSignup, props: route => ({ page: "signup" }) },
    { path: "/admin/sites/detail/:id", name: "site-detail", role: "manager", title: "Site Detail", component: SiteIndex, props: route => ({ page: "detail" }) },
    { path: "/admin/sites/routes/:id", name: "site-routes", role: "manager", title: "Site Routes", component: SiteIndex, props: route => ({ page: "routes" }) },
    { path: "/admin/sites/new", name: "add-site", meta: { role: "manager", title: "Add New Site" }, component: SiteIndex, props: route => ({ page: "add" }) },
    { path: "/admin/sites/:id", name: "edit-site", meta: { role: "manager", title: "Edit Site" }, component: SiteIndex, props: route => ({ page: "edit" }) },

    { path: "/admin/categories", name: "categories", meta: { role: "admin", title: "All Categories" }, component: CategoryIndex },
    { path: "/admin/categories/new", name: "add-category", meta: { role: "admin", title: "Add Category" }, component: CategoryIndex, props: route => ({ id: "new" }) },
    { path: "/admin/categories/:id", name: "edit-category", meta: { role: "admin", title: "Edit Category" }, component: CategoryIndex },

    { path: "/admin/locations", name: "locations", meta: { role: "manager", title: "All Locations" }, component: LocationIndex },
    { path: "/admin/locations/new", name: "add-location", meta: { role: "manager", title: "Add Location" }, component: LocationIndex, props: route => ({ id: "new" }) },
    { path: "/admin/locations/:id", name: "edit-location", meta: { role: "manager", title: "Edit Location" }, component: LocationIndex },

    { path: "/admin/wishlist", name: "wishlist", meta: { role: "user", title: "My Wishlist" }, component: WishList },

    { path: "/admin/users", name: "users", meta: { role: "vendor", title: "All Shop Users" }, component: UserList },
    { path: "/admin/users/admins", name: "admins", meta: { role: "vendor", title: "All Shop Admins" }, component: UserList, props: route => ({ id: "admins" }) },
    { path: "/admin/users/vendors", name: "vendors", meta: { role: "vendor", title: "All Shop Vendors" }, component: UserList, props: route => ({ id: "vendors" }) },
    { path: "/admin/users/staffs", name: "staff", meta: { role: "vendor", title: "All Shop Staffs" }, component: UserList, props: route => ({ id: "staffs" }) },
    { path: "/admin/users/customers", name: "customers", meta: { role: "vendor", title: "All Shop Customers" }, component: UserList, props: route => ({ id: "customers" }) },
    { path: "/admin/users/branches", name: "branches", meta: { role: "vendor", title: "All Shop Branches" }, component: UserBranchIndex, props: route => ({ id: "branches" }) },
    { path: "/admin/users/new-staff", name: "add-user", meta: { role: "vendor", title: "Add User" }, component: UserList, props: route => ({ id: "new-staff" }) },
    { path: "/admin/users/new-branch", name: "add-user", meta: { role: "vendor", title: "Add User" }, component: UserBranchIndex, props: route => ({ id: "new-branch" }) },
    { path: "/admin/users/:id", name: "edit-user", meta: { role: "vendor", title: "Edit User" }, component: UserEdit },

    { path: "/admin/notifications", name: "notifications", meta: { role: "manager", title: "Notifications" }, component: NotificationIndex },
    { path: "/admin/notifications/new", name: "notifications-add", meta: { role: "manager", title: "Add Notifications" }, component: NotificationIndex, props: route => ({ id: "new" }) },
    { path: "/admin/notifications/mass", name: "notifications-mass", meta: { role: "manager", title: "Mass Notifications" }, component: NotificationIndex, props: route => ({ id: "mass" }) },
    { path: "/admin/notifications/:id", name: "notifications-edit", meta: { role: "manager", title: "Edit Notifications" }, component: NotificationIndex },

    { path: "/admin/vacations", name: "vacations", meta: { role: "vendor", title: "Vacations" }, component: VendorVacationIndex },
    { path: "/admin/vacations/:id", name: "vacation-edit", meta: { role: "vendor", title: "Vacation Edit" }, component: VendorVacationIndex },

    { path: "/admin/payment/cards", name: "payment-cards", meta: { role: "user", title: "Cards" }, component: PaymentCardIndex },

    { path: "/admin/stripe/cards", name: "stripe-cards", meta: { role: "user", title: "Cards" }, component: StripeCardIndex },
    { path: "/admin/stripe/cards/new", name: "add-stripe-card", meta: { role: "user", title: "Add Card" }, component: StripeCardIndex, props: route => ({ id: "new" }) },

    { path: "/admin/EzTimePay/cards", name: "tilled-cards", meta: { role: "user", title: "Cards" }, component: TilledCardIndex },
    { path: "/admin/EzTimePay/cards/new", name: "add-tilled-card", meta: { role: "user", title: "Add Card" }, component: TilledCardIndex, props: route => ({ id: "new" }) },
    { path: "/admin/EzTimePay/cards/:id", name: "edit-tilled-card", meta: { role: "user", title: "Edit Card" }, component: TilledCardIndex },

    { path: "/admin/pages", name: "pages", meta: { role: "manager", title: "Pages" }, component: PageIndex },
    { path: "/admin/pages/new", name: "page-new", meta: { role: "manager", title: "New Page" }, component: PageIndex, props: route => ({ id: "new" }) },
    { path: "/admin/pages/:id", name: "page-edit", meta: { role: "manager", title: "Edit Page" }, component: PageIndex },

    { path: "/admin/logs", name: "logs", meta: { title: "Logs" }, component: LogIndex },
    { path: "/admin/logs/:id", name: "log-detail", meta: { role: "admin", title: "Log" }, component: LogIndex },

    { path: "/admin/versions", name: "versions", meta: { title: "Versions" }, component: VersionIndex },
    { path: "/admin/versions/new", name: "version-new", meta: { role: "admin", title: "Add Version" }, component: VersionIndex, props: route => ({ id: "new" }) },
    { path: "/admin/versions/:id", name: "version-edit", meta: { role: "admin", title: "Edit Version" }, component: VersionIndex },
    { path: "/admin/versions/detail/:id", name: "version-detail", meta: { title: "Version Detail" }, component: VersionDetail },

    { path: "/admin/articles", name: "articles", meta: { role: "manager", title: "Article" }, component: ArticleIndex, props: route => ({ page: "list" }) },
    { path: "/admin/articles/categories", name: "article-categories", meta: { role: "manager", title: "Article Category Setting" }, component: ArticleIndex, props: route => ({ page: "category" }) },
    { path: "/admin/articles/new", name: "article-new", meta: { role: "manager", title: "New Article" }, component: ArticleIndex, props: route => ({ id: "new", page: "new" }) },
    { path: "/admin/articles/:id", name: "article-edit", meta: { role: "manager", title: "Edit Article" }, component: ArticleIndex, props: route => ({ page: "edit" }) },

    { path: "/admin/setting/payment", name: "payment-setting", meta: { role: "admin", title: "Payment Setting" }, component: SettingPayment },
    { path: "/admin/setting/get-financing", name: "get-financing-edit", meta: { role: "manager", title: "Financing Edit" }, component: SettingGetFinancing },
    { path: "/admin/setting/use-chat", name: "setting-usechat", meta: { role: "admin", title: "Setting Use Chat" }, component: SettingUseChat },
    { path: "/admin/setting/banner", name: "setting-banner", meta: { title: "Page Banner Setting", role: "admin" }, component: SettingBanner },
    { path: "/admin/setting/google-analytics", name: "setting-google-analytics", meta: { title: "Google Analytics Setting", role: "admin" }, component: SettingGoogleAnalytics },
    { path: "/admin/setting/sendgrid", name: "setting-sendgrid", meta: { title: "SendGrid Setting", role: "admin" }, component: SettingSendgrid },
    { path: "/admin/setting/telephone", name: "setting-telephone", meta: { title: "Telephone Setting", role: "admin" }, component: SettingTelephone },
    { path: "/admin/setting/terms-of-service", name: "terms-of-service-edit", meta: { role: "manager", title: "Terms of Service Edit" }, component: SettingTermsOfService },
    { path: "/admin/setting/vendor-terms-of-service", name: "vendor-terms-of-service-edit", meta: { role: "manager", title: "Vendor Terms of Service Edit" }, component: SettingVendorTermsOfService },
    { path: "/admin/setting/shipping-fee", name: "setting-shipping-fee", meta: { role: "manager", title: "Setting Shipping Fee" }, component: SettingShippingFee },
    { path: "/admin/setting/vendor-display", name: "vendor-display", meta: { role: "admin", title: "Setting Vendor Display" }, component: SettingVendorDisplay },

    { path: "/admin/homepages", name: "homepages", meta: { role: "manager", title: "Homepages" }, component: HomepageIndex, props: route => ({ page: "list" }) },
    { path: "/admin/homepages/new", name: "homepage-new", meta: { role: "manager", title: "New Homepage" }, component: HomepageIndex, props: route => ({ id: "new", page: "new" }) },
    { path: "/admin/homepages/:id", name: "homepage-edit", meta: { role: "manager", title: "Edit Homepage" }, component: HomepageIndex, props: route => ({ page: "edit" }) },

    {
        path: "/admin/reports",
        name: "report-index",
        component: ReportIndex,
        children: [
            //
            { path: "booking-locations", name: "report-booking-locations", meta: { title: "Report Booking Locations", role: "vendor" }, component: ReportBookingLocations },
            { path: "monthly-bookings", name: "report-monthly-bookings", meta: { title: "Report Monthly Bookings", role: "vendor" }, component: ReportMonthlyBookings },
            { path: "product-sales", name: "report-product-sales", meta: { title: "Report Product Sales", role: "vendor" }, component: ReportProductSales },
            { path: "test", name: "report-test", meta: { title: "Report Test", role: "vendor" }, component: ReportTest },
            { path: "search-words", name: "report-search-words", meta: { title: "Report Search Results" }, component: ReportSearchWords, redirect: "search-words/table" },
            { path: "search-words/:id", name: "report-search-words", meta: { title: "Report Search Results" }, component: ReportSearchWords, props: true },
            { path: "vendor-booking-totals", name: "report-product-sales", meta: { title: "Report Vendor Booking Totals", role: "vendor" }, component: ReportVendorBookingTotals }
        ]
    },

    { path: "/account/profile", name: "edit-profile", meta: { role: "user", title: "Edit Profile" }, component: AccountProfile },
    { path: "/account/change-password", name: "change-password", meta: { role: "user", title: "Change Password" }, component: AccountChangePassword },
    { path: "/account/signup", name: "signup", meta: { title: "Signup for EzTimeRental" }, component: AccountSignup },
    { path: "/account/login", name: "login", meta: { title: "Login to the Awesome EzTimeRental" }, component: AccountLogin },
    { path: "/account/forgot-password", name: "forgot-password", meta: { title: "Forgot Passsword" }, component: AccountForget },
    { path: "/account/reset-password/:id", name: "reset-password", meta: { title: "Reset Password" }, component: AccountReset },
    { path: "/account/update-email", name: "update-email", meta: { title: "Update Email" }, component: AccountUpdateEmail },
    { path: "/account/update-email/:token", name: "update-email", meta: { title: "Update Email" }, component: AccountUpdateEmail },
    { path: "/account/vendor/signup", name: "vendor-signup", meta: { title: "Signup Vendor for EzTimeRental" }, component: VendorSignup },
    { path: "/account/vendor/location", name: "vendor-location", meta: { title: "Vendor Service Location Setting" }, component: LocationVendorSetting },
    { path: "/account/vendor/appfee", name: "vendor-appfee", meta: { title: "Vendor App Fee" }, component: VendorAppFee },
    { path: "/account/connect", name: "account-connect", meta: { title: "Account Stripe Connect" }, component: AccountConnect },

    { path: "/cart", name: "cart-items", meta: { title: "Cart Items" }, component: CartPage },

    { path: "/helpcenter", name: "article-home", meta: { title: "Help Center" }, component: ArticleHome },
    { path: "/helpcenter/category/:category", name: "article-home-category", meta: { title: "Help Center" }, component: ArticleHome },
    { path: "/helpcenter/category/:parentcategory/:category", name: "article-home-category2", meta: { title: "Help Center" }, component: ArticleHome },
    { path: "/helpcenter/article/:article", name: "article-detail", meta: { title: "Help Center" }, component: ArticleHome },

    { path: "/admin/affiliate/signup", name: "affiliate-signup", meta: { title: "Affiliate Singup" }, component: AffiliateSignup },
    { path: "/admin/affiliate/commissions", name: "affiliate-commisions", meta: { title: "Affiliate Commisions" }, component: AffiliateCommisions },

    { path: "/shop", name: "shop", meta: { title: "EasyTimeRental" }, component: Home, props: route => route.query },
    { path: "/checkout", name: "checkout", component: Checkout, meta: { role: "user", title: "Checkout and complete payment" } },
    { path: "/success", name: "success", component: Success, meta: { title: "Order Placed Successfully" } },
    { path: "/orders/pdf/:id", name: "order-pdf", meta: { title: "Booking" }, component: OrderPDFDetail },
    { path: "/category/:category", name: "product-category", meta: { title: "Product Category" }, component: Home },
    { path: "/get-financing", name: "get-financing", meta: { title: "Financing" }, component: GetFinancing },
    { path: "/terms-of-service", name: "terms-of-service", meta: { title: "Terms of Service" }, component: TermsOfService },
    { path: "/vendor-terms-of-service", name: "vendor-terms-of-service", meta: { title: "Vendor Terms of Service" }, component: VendorTermsOfService },
    { path: "/pages/:slug/:id", name: "page", meta: { title: "Page" }, component: Page },
    { path: "/pages/:slug", name: "page", meta: { title: "Page" }, component: Page },
    { path: "/:vendor_name", name: "vendor-profile", meta: { title: "Vendor Profile" }, component: VendorProfileIndex },
    { path: "/:vendor_name/test", name: "vendor-profile-test", meta: { title: "Vendor Profile" }, component: VendorProfileIndex },
    { path: "/:vendor_name/:page(about)", name: "vendor-profile-about", meta: { title: "Vendor Profile" }, component: VendorProfileIndex },
    { path: "/:vendor_name/:page(products)", name: "vendor-profile-products", meta: { title: "Vendor Profile" }, component: VendorProfileIndex },
    { path: "/:vendor_name/:page(reviews)", name: "vendor-profile-reviews", meta: { title: "Vendor Profile" }, component: VendorProfileIndex },

    { path: "/:slug/:id([^/]{20,})", name: "product-detail", meta: { title: "Product Detail" }, component: ProductDetail },
    { path: "**", meta: { title: "The Requested Page not Found" }, component: NotFound }
];

routes.map(r => {
    r.props = r.props || (route => ({}));
    if (typeof r.props == "function") {
        let bak = r.props;
        r.props = route => ({ ...bak(route), ...route.query, ...route.params });
    }
    return r;
});
const router = new Router({ mode: "history", routes });

let routeTimes = 0;
router.beforeResolve((to, from, next) => {
    routeTimes++;
    if (to.meta.role) {
        if (store.getters["user/hasRole"](to.meta.role)) {
            next();
        } else {
            console.info("router beforehook role validate triggered");
            store.commit("setMessage", "Please login to continue", { root: true });
            if (routeTimes === 1) {
                // next("/account/login?referrer=" + to.path);
                next("/");
            }
        }
    } else {
        next();
    }
});
router.afterEach((to, from) => {
    // document.title = to.meta.title;
    if (to.meta && to.meta.title) {
        store.dispatch("seo/set", { headline1: to.meta.title });
    } else {
        store.dispatch("seo/setDefault");
    }
    if (self != top) {
        setTimeout(() => {
            store.commit("postParentMessage", {
                type: "info",
                height: document.body.offsetHeight
            });
        }, 50);
    }
});

export default router;
