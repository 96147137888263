<template>
    <div class="page-more">
        <md-menu :md-align-trigger="true" md-direction="bottom left" md-size="4">
            <md-button alt="more" aria-label="More Pages" md-menu-trigger>more</md-button>
            <md-menu-content>
                <md-menu-item :key="ix" @click="jump(page)" v-for="(page,ix) in pages">{{page.name}}</md-menu-item>
            </md-menu-content>
        </md-menu>
    </div>
</template>
<script>
export default {
    data() {
        return {
            pages: []
        };
    },
    methods: {
        async loadPages() {
            let result = await this.$store.dispatch("crud/get", {
                api: "pages"
            });
            this.pages = result;
        },
        jump(page) {
            if (page.type == "Link") {
                location.href = page.content;
            } else {
                this.$router.push(`/pages/${page.slug}`);
            }
        }
    },
    created() {
        this.loadPages();
    }
};
</script>
<style lang="less">
.page-more {
    .md-button {
        padding: 0;
        min-width: inherit;
    }
}
</style>


