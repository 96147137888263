<template>
    <div class="autocomplete">
        <md-autocomplete :debounce="300" :filter-list="filter||defaultFilter" :list="convertedOptions" :print-attribute="printAattribute" v-if="!isFetch && convertedOptions.length" v-model="innerValue"></md-autocomplete>
        <md-input v-else-if="!isFetch && !convertedOptions.length" v-model="innerValue"></md-input>
        <md-autocomplete :fetch="fetch" v-else-if="isFetch" v-model="innerValue"></md-autocomplete>
    </div>
</template>
<script>
//todo make support other field value
export default {
    props: {
        value: {
            type: String
        },
        options: {
            type: Array,
            default: () => []
        },
        filter: {
            type: Function,
            default: undefined
        },
        apiGet: {
            type: String,
            default: undefined
        },
        apiList: {
            type: String,
            default: undefined
        },
        printAattribute: {
            type: String,
            default: "name"
        },
        fieldName: {
            type: String,
            default: "_id" //name _id
        }
    },
    data() {
        return {
            fetchRecords: [],
            innerValue: undefined
        };
    },
    computed: {
        convertedOptions() {
            if (this.options) {
                if (this.options.length) {
                    if (typeof this.options[0] != "object") {
                        return this.options.map(s => ({ name: s }));
                    } else {
                        return this.options;
                    }
                }
            }
            return [];
        },
        isFetch() {
            return !this.convertedOptions.length && this.apiList;
        }
    },
    watch: {
        innerValue: {
            handler() {
                if (this.innerValue) {
                    console.info("autocomplete", this.innerValue);
                    let records = this.fetchRecords.length ? this.fetchRecords : this.convertedOptions;
                    let record = records.find(r => r.name == this.innerValue);
                    if (record) {
                        this.$emit("input", record[this.fieldName]);
                    } else {
                        this.$emit("input", this.innerValue || undefined);
                    }
                }
            }
        },
        value: {
            immediate: true,
            async handler() {
                // if (this.value) {
                console.info("autocomplete value changed", this.value);
                if (this.value) {
                    console.info(this.convertedOptions);
                    let records = this.fetchRecords.length ? this.fetchRecords : this.convertedOptions;
                    if (records.length) {
                        let record = records.find(r => r[this.fieldName] == this.value);
                        this.innerValue = (record && record.name) || this.innerValue;
                    } else if (this.isFetch) {
                        let record = await this.getRecord();
                        this.innerValue = (record && record.name) || this.innerValue;
                    } else {
                        this.innerValue = value;
                    }
                }

                // this.innerValue = this.value;
                // }
            }
        },
        options: {
            deep: true,
            handler() {
                //fix options changed bug

                if (this.value) {
                    let records = this.fetchRecords.length ? this.fetchRecords : this.convertedOptions;
                    if (records.length) {
                        let record = records.find(r => r[this.fieldName] == this.value);
                        console.info("autocomplete record", record);
                        this.innerValue = (record && record.name) || this.innerValue;
                    }
                }

                let bak = this.innerValue;
                this.innerValue = "";
                setTimeout(() => {
                    console.info("bbbb", bak);
                    this.innerValue = bak;
                }, 0);
            }
        }
    },
    methods: {
        defaultFilter(list, query) {
            if (query) {
                let reg = new RegExp(query, "i");
                return list.filter(i => reg.test(i.name));
            }
            return list;
        },
        async fetch(cond) {
            let q = (cond && cond.q) || "";
            let apiList = this.apiList.replace(/:q/g, q);
            let result = await this.$store.dispatch("crud/get", {
                api: apiList
            });
            this.fetchRecords = result.data || result;
            return this.fetchRecords;
        },
        async getRecord() {
            if (this.value) {
                let apiGet = this.apiGet.replace(new RegExp(":" + this.fieldName, "g"), this.value);
                let result = await this.$sotre.dispatch("crud/get", {
                    api: apiGet
                });
                return result;
            }
            return null;
        }
    }
};
</script>
<style lang="less">
.autocomplete {
    display: flex;
    flex: 1;
}
</style>
