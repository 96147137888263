import { clearCart } from "@/config";
import router from "@/router";
import moment from "moment-timezone";
const state = {
    items: JSON.parse(localStorage.getItem("EZTIMERENTAL_ITEMS")) || []
};

// getters
const getters = {
    items: (state, getters, rootGetters) => {
        let vendorMode = rootGetters.vendorMode;
        console.info("cart", vendorMode);
        return state.items.filter(i => i.quantity > 0).filter(i => !vendorMode || i.vendor_name == vendorMode.name) || [];
    },
    groupItems: (state, getters) => {
        const cartItems = getters.items;
        const groupItems = {};
        for (let i in cartItems) {
            const { vendor_name } = cartItems[i];
            groupItems[vendor_name] = groupItems[vendor_name] || [];
            groupItems[vendor_name].push(cartItems[i]);
        }
        return groupItems;
    },
    getItem: (state, getters) => ({ _id, vid }) => {
        return state.items.find(i => i._id == _id && i.vid == vid);
    },
    totalCount: (state, getters) => {
        return getters.items.length;
    },
    totalAmount: (state, getters) => {
        return getters.items.reduce((acc, item) => {
            let subAmount = item.quantity * item.price;
            if (item.rental) {
                let rentalPeriod = getters.getRentalPeriod({ _id: item._id, vid: item.vid });
                subAmount = subAmount * rentalPeriod;
            }
            const addOnItemsPrice = getters.getAddOnItemsPrice({ _id: item._id });
            return acc + subAmount + addOnItemsPrice;
        }, 0);
    },
    getQuantity: getters => ({ _id, vid }) => {
        for (let i in state.items) {
            if (state.items[i]._id === _id && state.items[i].vid === vid) {
                return state.items[i].quantity;
            }
        }
    },
    getAddOnItems: getters => ({ _id }) => {
        for (let i in state.items) {
            if (state.items[i]._id === _id) {
                return state.items[i].addOnItems;
            }
        }
    },
    getAddOnItemsPrice: state => ({ _id }) => {
        const item = state.items.find(i => i._id == _id);
        const { addOnItems = [] } = item || {};
        return addOnItems.reduce((acc, addOnItem) => {
            const { active, quantity, price } = addOnItem;
            if (active && quantity) {
                acc += price * quantity * item.quantity;
            }
            return acc;
        }, 0);
    },
    getRentalPeriod: state => ({ _id, vid }) => {
        let item = state.items.find(i => i._id == _id && i.vid == vid);
        if (item) {
            let { rentalInterval, rentalStartDate, rentalEndDate } = item;
            let rentalHours = moment(rentalEndDate)
                .startOf("hour")
                .diff(moment(rentalStartDate).startOf("hour"), "hour");
            if (rentalInterval == "hourly") {
                return rentalHours;
            }
            if (rentalInterval == "daily") {
                return Math.ceil(rentalHours / 24);
            }
        }
        return 0;
    },
    checkCart: (state, getters) => ({ id, vid }) => {
        return getters.items.find(i => i._id == id && i.vid == vid);
    }
};

// actions
const actions = {
    async refreshCart({ commit, state, dispatch, getters }) {
        console.info("cart items", getters.items);
        for (let i in getters.items) {
            let item = getters.items[i];
            dispatch("addToCart", {
                product: { _id: item._id, addOnItems: item.addOnItems },
                variant: { _id: item.vid },
                quantity: item.quantity,
                rentalStartDate: item.rentalStartDate,
                rentalEndDate: item.rentalEndDate
            });
        }
    },

    async removeFromCart({ commit, state, dispatch, getters }, { product, variant }) {
        commit("removeFromCart", { product, variant });
        return;
    },

    async addToCart({ commit, state, dispatch, getters }, { product, variant, quantity, rentalStartDate, rentalEndDate }) {
        //only set the dates
        if (quantity == 0) {
            commit("addToCart", { product, variant, quantity, rentalStartDate, rentalEndDate });
            return;
        }

        //get the latest product
        const addOnItems = product.addOnItems || [];
        product = await dispatch("crud/get", { api: `products/${product._id}` }, { root: true });
        if (product.addOnItems) {
            product.addOnItems.forEach((item, idx) => {
                const { _id, active } = item;
                if (active) {
                    const findedItem = addOnItems.find(i => i._id === _id);
                    item.quantity = findedItem.quantity;
                }
            });
        }
        variant = product && product.variants.find(v => v._id == variant._id);

        //check limit
        var limitCheck = await dispatch("checkAddToCart", { product, variant, quantity, rentalStartDate, rentalEndDate, type: product.type });
        if (!limitCheck) {
            return;
        }

        // check subItems
        if (variant.items) {
            //get the latest subItems
            variant.items = await Promise.all(
                variant.items.map(async item => {
                    var p = await dispatch("crud/get", { api: `products/${item.pid}` }, { root: true });
                    var v = p && p.variants.find(v => v._id == item.vid);
                    if (!p || !v) {
                        commit("setMessage", `product ${item.name} ${item.vname} not found`, { root: true });
                        throw "not found";
                    }
                    return { product: p, variant: v, quantity: item.quantity };
                })
            );

            let checks = await Promise.all(
                variant.items.map(async item => {
                    return await dispatch("checkAddToCart", {
                        product: item.product,
                        variant: item.variant,
                        quantity: item.quantity * quantity,
                        rentalStartDate,
                        rentalEndDate,
                        type: "packageitem"
                    });
                })
            );

            if (checks.findIndex(check => !check) > -1) {
                return;
            }
        }

        commit("addToCart", { product, variant, quantity, rentalStartDate, rentalEndDate });
    },

    async checkAddToCart({ commit, state, dispatch, getters }, { product, variant, quantity, rentalStartDate, rentalEndDate, type = "product" }) {
        if (!product || !variant) {
            commit("setMessage", `${type} not found`, { root: true });
            return false;
        }

        let item = state.items.find(i => i._id == product._id && i.vid == variant._id);
        let startDate = rentalStartDate || (item && item.rentalStartDate);
        let endDate = rentalEndDate || (item && item.rentalEndDate);
        let smoment = moment(startDate);
        let emoment = moment(endDate);

        //rental limit
        if (product.rental) {
            //date exist limit
            if (!startDate || !endDate) {
                commit("setMessage", "please SET the start date and end date", { root: true });
                return false;
            }
            //enddate > startdate limit
            if (emoment.diff(smoment) <= 0) {
                commit("setMessage", "end date must be bigger then start date", { root: true });
                return false;
            }
            //daily rental min limit
            if (product.rentalMinDays && product.rentalInterval == "daily") {
                if (emoment.startOf("day").diff(smoment.startOf("day"), "day") + 1 < product.rentalMinDays) {
                    commit("setMessage", `${product.rentalMinDays} Days Minimum Booking Required`, { root: true });
                    return false;
                }
            }
            //hourly rental min limit
            if (product.rentalMinDays && product.rentalInterval == "hourly") {
                if (emoment.startOf("hour").diff(smoment.startOf("hour"), "hour") < product.rentalMinDays) {
                    commit("setMessage", `${product.rentalMinDays} Hours Minimum Booking Required`, { root: true });
                    return false;
                }
            }
        }

        //sale limit
        if (!product.rental) {
            //date exist limit
            if (!startDate) {
                commit("setMessage", "please SET the delivery date ", { root: true });
                return false;
            }
        }

        //preparedays limit
        // if (product.prepareDays) {
        if (
            moment(startDate)
                .startOf("day")
                .diff(moment().startOf("day"), "day") <
            (product.prepareDays || 0) + 1
        ) {
            commit("setMessage", `This item requires a min prepare ${product.prepareDays || 0} days`, { root: true });
            return false;
        }
        // }

        //stock limit
        if (!product.unlimited && quantity > variant.stock) {
            commit("setMessage", `The requested ${product.name} amount exceeded the limit, stock available is ${variant.stock}`, { root: true });
            return false;
        }

        //vacation limit
        let vacations = await dispatch("crud/get", { api: `vacations/product/${product._id}` }, { root: true });
        let vacation = vacations.find(vacation => smoment.isBetween(moment(vacation.begin), moment(vacation.end)));
        if (product.rental && !vacation) {
            vacation = vacations.find(vacation => emoment.isBetween(moment(vacation.begin), moment(vacation.end)));
        }
        if (vacation) {
            let vacationBeginFormat = moment(vacation.begin).format("ddd MMM Do YYYY hh:mm A");
            let vacationEndFormat = moment(vacation.end).format("ddd MMM Do YYYY hh:mm A");
            commit("setMessage", `Vendor ${product.vendor_name} is on vacation: \n\r${vacationBeginFormat} - ${vacationEndFormat} `, { root: true });
            return false;
        }

        //avail rental resouce
        if (product.rental) {
            let result = await dispatch(
                "crud/get",
                {
                    api: `products/rentalAvailStock/${product._id}/${variant._id}`,
                    params: {
                        rentalStartDate: smoment.utc().format(),
                        rentalEndDate: emoment.utc().format()
                    }
                },
                { root: true }
            );
            let avail = result.avail;
            console.info(`${product.name} rental avail:${avail}`);
            if (!product.unlimited && quantity > avail) {
                commit("setMessage", `The requested ${product.name} for the date specified only has (${avail}) available`, { root: true });
                return false;
            }
        }

        return true;
    },

    async checkout({ commit, dispatch, state, rootState, rootGetters, getters }, { address, pay = false, card = undefined, pickups = {}, gateway = "stripe" }) {
        let affiliateId = rootGetters.affiliateId;
        let items = JSON.parse(JSON.stringify(getters.items));
        items.forEach(i => {
            i.rentalStartDate = moment(i.rentalStartDate);
            i.rentalEndDate = moment(i.rentalEndDate);
        });
        let orderDetails = {
            address: address,
            items: items,
            affiliateId: affiliateId,
            pickups: pickups
        };

        //create orders
        let result = await dispatch(
            "crud/post",
            {
                api: "orders",
                data: orderDetails
            },
            { root: true }
        );

        // google ad
        window.gtagEventConversion && window.gtagEventConversion();

        //pay orders
        if (pay) {
            let orders = result;
            let allPaySuccess = true;
            await Promise.all(
                orders.map(async order => {
                    try {
                        let r = await dispatch(
                            "crud/post",
                            {
                                api: `pays/${gateway}/pay/${order._id}`,
                                data: { card: card }
                            },
                            { root: true }
                        );
                    } catch (error) {
                        allPaySuccess = false;
                        await dispatch(
                            "crud/delete",
                            {
                                api: `orders/my/${order._id}`
                            },
                            { root: true }
                        );
                    }
                })
            );
            if (allPaySuccess) {
                commit("setMessage", `Payment success`, { root: true });
            } else {
                throw new Error("Payment Fail");
            }
        }
        if (clearCart) {
            commit("clearCart");
        }
        router.push("/admin/orders/my");
    }
};

// mutations
const mutations = {
    saveCart(state) {
        localStorage["EZTIMERENTAL_ITEMS"] = JSON.stringify(state.items);
    },
    addToCart(state, { product, variant, quantity, rentalStartDate, rentalEndDate }) {
        rentalStartDate = moment(rentalStartDate).format("YYYY-MM-DD HH:mm");
        rentalEndDate = moment(rentalEndDate).format("YYYY-MM-DD HH:mm");
        let item = state.items.find(i => i._id == product._id && i.vid == variant._id);
        let newItem = {
            _id: product._id,
            name: product.name,
            slug: product.slug,
            price: variant.price,
            quantity: quantity,
            image: variant.image,
            category: product.category,
            size: variant.size,
            vid: variant._id,
            addOnItems: product.addOnItems || [],
            vname: variant.name,
            rental: product.rental,
            rentalInterval: product.rentalInterval,
            rentalDeposit: product.rentalDeposit,
            rentalStartDate,
            rentalEndDate,
            vendor_id: product.vendor_id,
            vendor_name: product.vendor_name,
            vendor_email: product.vendor_email,
            type: product.type,
            pickup: product.pickup
        };

        if (item) {
            Object.assign(item, newItem);
        } else {
            state.items.push(newItem);
        }
        localStorage["EZTIMERENTAL_ITEMS"] = JSON.stringify(state.items);
    },
    removeFromCart(state, { product, variant }) {
        let item = state.items.find(i => i._id == product._id && i.vid == variant._id);
        state.items = state.items.filter(i => i != item);
        localStorage["EZTIMERENTAL_ITEMS"] = JSON.stringify(state.items);
    },
    cleanCart(state) {
        console.info(state.items);
        state.items = state.items.filter(
            i =>
                moment()
                    .startOf("day")
                    .diff(moment(i.rentalStartDate).startOf("day"), "day") <= 0
        );
        localStorage["EZTIMERENTAL_ITEMS"] = JSON.stringify(state.items);
    },
    clearCart(state) {
        state.items = [];
        localStorage["EZTIMERENTAL_ITEMS"] = JSON.stringify(state.items);
    }
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
