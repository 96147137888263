const state = {
    vendorDisplay: false,
    payment: {}
};

const getters = {
    vendorDisplay(state, getters) {
        return state.vendorDisplay
    },
    payment(state, getters) {
        return state.payment;
    }
};

const actions = {
    async loadVendorDisplay({ dispatch, commit }, payload) {
        const vendorDisplaySetting = await dispatch(
            "crud/get",
            {
                api: "settings/vendor-display"
            },
            { root: true }
        );
        commit("setVendorDisplay", vendorDisplaySetting);
        return vendorDisplaySetting;
    },
    async refreshPayment({ dispatch, commit }, payload) {
        const paymentSetting = await dispatch(
            "crud/get",
            {
                api: "settings/payment"
            },
            { root: true }
        );
        commit("setPayment", paymentSetting);
        return paymentSetting;
    }
};

const mutations = {
    setVendorDisplay(state, payload) {
        state.vendorDisplay = payload
    },
    setPayment(state, payload) {
        state.payment = payload;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
