<template>
    <div class="rocketchat-noconflict"></div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters({
            user: "user/user"
        }),
        mode() {
            if (/admin|account/.test(this.$route.path)) {
                return "admin";
            }
        }
    },
    watch: {},
    methods: {
        async loadSetting() {
            const result = await this.$store.dispatch("crud/get", {
                api: "settings/use-chat"
            });
            const { value } = result;
            if (value) {
                this.loadScript();
            }
        },
        async loadScript() {
            (function(w, d, s, u) {
                w.RocketChat = function(c) {
                    w.RocketChat._.push(c);
                };
                w.RocketChat._ = [];
                w.RocketChat.url = u;
                var h = d.getElementsByTagName(s)[0],
                    j = d.createElement(s);
                j.async = true;
                j.src = "https://chat.eztimerental.com/livechat/rocketchat-livechat.min.js?_=201903270000";
                h.parentNode.insertBefore(j, h);
            })(window, document, "script", "https://chat.eztimerental.com/livechat");
            // let user = this.user;
            // if (user) {
            //     window.Tawk_API = window.Tawk_API || {};
            //     window.Tawk_LoadStart = new Date();
            // }
            // if (user) {
            //     window.Tawk_API.visitor = {
            //         name: user.name,
            //         email: user.email,
            //         hash: user.tawkHash
            //     };
            //     window.Tawk_API.onLoad = function() {
            //         window.Tawk_API.setAttributes({
            //             email: user.email,
            //             name: user.name,
            //             hash: user.tawkHash
            //         });
            //         console.info(`Tawk visitor name:${user.name}, email:${user.email}, hash:${user.tawkHash}`);
            //     };
            // }
            // const script = `https://embed.tawk.to/${(window.INJECT_ENV && window.INJECT_ENV.TAWK_ID) || "5ad7d73a227d3d7edc2416ad"}/default`;
            // let scriptEle = document.createElement("script");
            // scriptEle.async = true;
            // scriptEle.src = script;
            // document.head.appendChild(scriptEle);
            // await require(url);
        }
    },
    created() {
        this.loadSetting();
    }
};
</script>
<style lang="less">
.rocketchat-noconflict {
    display: none;
}
</style>
