<template>
    <div>
        <md-dialog-confirm :md-title="title" :md-content-html="content" @open="onOpen" @close="close" ref="confirm"></md-dialog-confirm>
    </div>
</template>

<script>
export default {
    data: () => ({
        data: {},
        title: "Delete",
        content: "Are you sure to delete this ?"
    }),
    methods: {
        buildDTD() {
            let dtd = {
                promise: null,
                resolve: null,
                reject: null
            };

            dtd.promise = new Promise((resolve, reject) => {
                dtd.resolve = resolve;
                dtd.reject = reject;
            });
            return dtd;
        },
        open(title, content, data) {
            this.title = title;
            this.content = content;
            this.data = data;
            this.$refs["confirm"].open();
        },
        onOpen() {
            this.$emit("open", "opened");
        },
        close(type) {
            console.info(type);
            if (type === "ok") {
                this.$emit("close", type, this.data);
                this.$emit("ok", type, this.data);
                this.dtd && this.dtd.resolve({ action: "confirm" });
            }
            if (type == "cancel") {
                this.$emit("cancel", type, this.data);
                this.dtd && this.dtd.resolve({ action: "cancel" });
            }
        },
        async process(title, content, data) {
            this.dtd = this.buildDTD();

            this.title = title;
            this.content = content;
            this.data = data;
            this.$refs["confirm"].open();
            let result = await this.dtd.promise;
            // this.$refs["dialog"].close();
            return result;
        }
    }
};
</script>
