<template>
    <div class="account-forget">
        <edit :fs="userForgetFields" @saved="saved" apiSave="users/forgot" customClassName="none" title="Forgot Password">
            <template slot="submit" slot-scope="slotProps">
                <md-button @click="goLogin">Login</md-button>
                <submit :loading="slotProps.loading" icon="mail" text="Send Reset Password Email"></submit>
            </template>
        </edit>
    </div>
</template>

<script>
import Edit from "@/components/Edit";
import { userForgetFields } from "@/config";
import Submit from "@/components/Submit";

export default {
    props: {
        inModal: {}
    },
    components: {
        Edit,
        Submit
    },
    data() {
        return {
            email: ""
        };
    },
    computed: {
        userForgetFields() {
            return userForgetFields;
        }
    },
    methods: {
        saved(result) {
            this.$store.commit("setMessage", result);
        },
        go(uri) {
            this.$router.push(uri);
        },
        goLogin() {
            if (this.inModal) {
                this.$emit("goLogin");
            } else {
                this.go("/account/signup");
            }
        }
    },
    created() {
        this.email = this.$route.query.email;
    }
};
</script>
<style lang="less">
.account-forget {
    margin: auto;
    max-width: 1000px;
    min-height: 700px;
    .md-card {
        max-width: 1000px;
        margin: auto;
    }
}
</style>
