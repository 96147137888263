<template>
    <div class="google-analytics-noconflict"></div>
</template>
<script>
export default {
    data() {
        return {
            setting: {}
        };
    },
    methods: {
        async loadKey() {
            const result = await this.$store.dispatch("crud/get", {
                api: "settings/google-analytics"
            });
            if (result.value) {
                this.setting = result.value;
            }
        },
        async loadScript() {
            const analytics_key = this.setting.analytics_key || "UA-96165799-3";
            const ad_key = this.setting.ad_key || "AW-857908412";
            const conversion_ad_id = this.setting.conversion_ad_id || "oBMLCNiJ2Y8BELzJipkD";
            const script = `https://www.googletagmanager.com/gtag/js?id=${analytics_key}`;
            window.dataLayer = window.dataLayer || [];
            window.gtag = function() {
                console.info("[gtag]", arguments);
                dataLayer.push(arguments);
            };
            window.gtagEventConversion = function(params = {}) {
                gtag("event", "conversion", {
                    send_to: `${ad_key}/${conversion_ad_id}`,
                    transaction_id: params.transaction_id
                });
            };
            gtag("js", new Date());
            gtag("config", analytics_key);
            gtag("config", ad_key);
            let scriptEle = document.createElement("script");
            scriptEle.async = true;
            scriptEle.src = script;
            document.head.appendChild(scriptEle);
        }
    },
    async created() {
        await this.loadKey();
        await this.loadScript();
    }
};
</script>
<style lang="less">
.google-analytics-noconflict {
    display: none;
}
</style>

