<template>
    <md-dialog class="login-modal" ref="login">
        <div class="left" v-if="!vendorMode ">
            <img alt src="/static/icons/icon-512x512.png">
        </div>
        <div class="right">
            <account-login :inModal="true" @goForget="show='forget'" v-if="show=='login'"></account-login>
            <account-forget :inModal="true" @goLogin="show='login'" v-if="show=='forget'"></account-forget>
        </div>
    </md-dialog>
</template>

<script>
import AccountLogin from "@/components/Account/AccountLogin";
import AccountForget from "@/components/Account/AccountForget";
import Submit from "./../Submit";
import { mapGetters } from "vuex";
export default {
    components: {
        AccountLogin,
        AccountForget
    },
    data() {
        return {
            show: "login" // login forget
        };
    },
    computed: {
        ...mapGetters({
            vendorMode: "vendorMode"
        })
    },
    methods: {
        open() {
            this.$refs["login"].open();
        },
        close(type) {
            this.$refs["login"].close();
        }
    },
    created() {
        console.info("loginModal created");
        this.eventHub.$on("openLogin", data => {
            console.info("open login fired");
            this.show = "login";
            this.open();
        });
        this.eventHub.$on("closeLogin", data => {
            this.close();
        });
    }
};
</script>
<style lang="less">
.login-modal {
    .md-dialog {
        max-width: 90%;
        display: flex;
        flex-direction: row;
        padding: 20px;

        .left {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                max-width: 300px;
                min-width: 150px;
            }
        }
        .right {
            flex: 1;
        }
        .md-card {
            box-shadow: none;
        }
    }
    .account-login {
        min-height: auto;
        .md-card-header {
            padding-bottom: 0;
        }
    }
    .account-forget {
        min-height: auto;
        .md-card-header {
            padding-bottom: 0;
        }
    }
}

@media (max-width: 600px) {
    .login-modal {
        .md-dialog {
            padding: 0;
            box-shadow: none;
            .left {
                display: none;
            }
            .md-card {
                box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
            }
        }
    }
}
</style>
