<template>
    <div class="qa-question-modal">
        <md-dialog md-close-to="#custom" md-open-from="#custom" ref="QACreateQ">
            <form @submit.prevent action>
                <md-dialog-title>{{ title }}</md-dialog-title>

                <md-dialog-content>
                    <md-input-container :class="{ 'md-input-invalid': errors.has('email') }" v-if="!productId && !orderId && !user">
                        <label for>Email</label>
                        <md-input :readonly="!!user" name="email" v-model="email" v-validate="'required|email'"></md-input>
                        <span class="md-error" v-show="errors.has('email')">{{ errors.first("email") }}</span>
                    </md-input-container>
                    <md-input-container :class="{ 'md-input-invalid': errors.has('question') }">
                        <label>Subject</label>
                        <md-input name="question" v-model="question" v-validate="'required|max:125'"></md-input>
                        <span class="md-error" v-show="errors.has('question')">{{ errors.first("question") }}</span>
                    </md-input-container>
                    <md-input-container v-if="!productId">
                        <label>Message</label>
                        <md-textarea name="content" v-model="content"></md-textarea>
                    </md-input-container>
                    <md-checkbox name="private" v-if="productId" v-model="isPrivate">Private Question?</md-checkbox>
                </md-dialog-content>
                <md-dialog-actions>
                    <md-button @click="cancel()" class="md-primary">Cancel</md-button>
                    <md-button @click="close()" class="md-primary md-raised" type="submit">Submit</md-button>
                </md-dialog-actions>
            </form>
        </md-dialog>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
    props: {
        title: {
            type: String,
            default: "Open A Ticket",
        },
        productId: {
            type: String,
        },
        orderId: {
            type: String,
        },
    },
    data: function () {
        return {
            isPrivate: false,
            email: "",
            question: "",
            content: "",
        };
    },
    computed: {
        ...mapGetters({
            user: "user/user",
        }),
    },
    methods: {
        // post question
        async postQuestion(api, data) {
            if (!(await this.$validator.validateAll())) {
                throw "validate error";
            }
            let result = await this.$store.dispatch("crud/post", {
                api: `/api/qas/`,
                data: {
                    question: this.question,
                    content: this.content,
                    private: this.isPrivate,
                    product_id: this.productId,
                    order_id: this.orderId,
                    user_email: this.email && this.email,
                },
            });
        },
        async onClose(type) {
            if (type === "ok") {
                await this.postQuestion();
                await this.$store.commit("setMessage", "Post Success");
                this.question = "";
                this.$emit("success");
            }
        },
        open() {
            if (this.user) {
                this.$refs["QACreateQ"].open();
            } else {
                // this.$store.commit("setMessage", "Please Login First");
                this.$router.push("/account/login");
            }
        },
        cancel() {
            this.$refs["QACreateQ"].close();
            this.isPrivate = false;
            this.question = "";
        },
        async close() {
            await this.onClose("ok");
            this.$refs["QACreateQ"].close();
        },
    },
    created() {
        if (this.user) {
            this.email = this.user.email;
        }
    },
};
</script>


