<template>
    <div :class="{ vendorMode: vendorMode }" class="app-viewport" id="app" v-if="vendorModeLoaded">
        <sw-register></sw-register>
        <cart v-if="!isAdmin"></cart>
        <shop-header></shop-header>
        <mega-menu-2 v-if="!isAdmin"></mega-menu-2>
        <router-view :class="{ admin: isAdmin }" class="main-router-container"></router-view>
        <shop-footer v-if="!vendorMode && !isAdmin"></shop-footer>
        <toast ref="toastRef"></toast>
        <login-modal></login-modal>
        <loading></loading>
        <RocketChat v-if="!vendorMode"></RocketChat>
        <!-- <tawk v-if="!vendorMode"></tawk> -->
        <google-analytics></google-analytics>
    </div>
</template>

<script>
import SwRegister from "@/components/SwRegister";
import ShopHeader from "@/components/ShopHeader";
import ShopFooter from "@/components/ShopFooter";
import Cart from "@/components/Cart/Cart";
import Toast from "@/components/Toast";
import Loading from "@/components/Loading";
import LoginModal from "@/components/Account/AccountLoginModal";
import MegaMenu2 from "@/components/Menu/MegaMenu2";
import AdminMegaMenu from "@/components/Menu/AdminMegaMenu";
// import Tawk from "@/components/Tawk";
import GoogleAnalytics from "@/components/GoogleAnalytics";
import RocketChat from "@/components/RocketChat";
import { mapGetters } from "vuex";
import { lang } from "@/config";
export default {
    name: "app",
    components: {
        SwRegister,
        ShopHeader,
        Cart,
        Toast,
        Loading,
        ShopFooter,
        LoginModal,
        MegaMenu2,
        AdminMegaMenu,
        // Tawk,
        RocketChat,
        GoogleAnalytics
    },
    data() {
        return {
            snackbar: false,
            showLogin: false
        };
    },
    computed: {
        ...mapGetters({
            error: "error",
            loading: "loading",
            vendorMode: "vendorMode",
            vendorModeLoaded: "vendorModeLoaded",
            user: "user/user"
        }),
        isAdmin() {
            return /^\/(admin|account)/.test(this.$route.path) && !/account\/signup|forgot-password|login/.test(this.$route.path);
        }
    },
    watch: {
        $route(to, from) {
            let adminStylePages = [
                //
                "/",
                "/terms-of-service"
            ];
            if (adminStylePages.find(p => p == to.path) || this.isAdmin) {
                document.querySelector("body").classList.add("admin-style");
            } else {
                document.querySelector("body").classList.remove("admin-style");
            }
        }
    },
    methods: {
        initFacebookMessenger() {
            window.fbAsyncInit = function() {
                FB.init({
                    xfbml: true,
                    version: "v15.0"
                });
            };

            (function(d, s, id) {
                var js,
                    fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s);
                js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js";
                fjs.parentNode.insertBefore(js, fjs);
            })(document, "script", "facebook-jssdk");
        }
    },
    mounted() {
        this.$store.watch(
            state => state.isError,
            err => {
                const { path } = this.$route;
                if (err) {
                    if (this.error === "Please login to continue" && path != "/account/login") {
                        this.$router.push("/account/login");
                    }
                    this.$refs["toastRef"] && this.$refs["toastRef"].open(this.error);
                }
            }
        );
    },
    async created() {
        if (this.user) {
            await this.$store.dispatch("user/refreshProfile");
            await this.$store.dispatch("setting/refreshPayment");
        }
        // this.$store.dispatch("cart/refreshCart");
        await this.$store.dispatch("loadVendorMode");
        await this.$store.dispatch("loadAffiliate");
        await this.$store.dispatch("category/loadCategories");
        await this.$store.dispatch("location/loadLocations");
        await this.$store.dispatch("setting/loadVendorDisplay");
        localStorage["location"] && (await this.$store.dispatch("location/setLocation", localStorage["location"]));
        if (!this.vendorMode) {
            this.initFacebookMessenger();
        }
    }
};
</script>
<style lang="less" src="./App.less"></style>
