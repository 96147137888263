<template>
    <div class="default-date-select">
        <div class="label">
            <md-icon @click="showDp" style="cursor:pointer;margin-right:2px">date_range</md-icon>
            <label @click="showDp" for="" style="cursor:pointer">Select Start Date</label>
        </div>
        <md-input-container>
            <date-picker ref="dp" :date="{time:defaultStartDate}" :option="op" :limit="limit" @change="setDate"></date-picker>
        </md-input-container>
    </div>
</template>
<script>
import moment from "moment-timezone";

export default {
    props: [],
    data() {
        return {
            defaultStartDate: ""
        };
    },
    watch: {
        defaultStartDate() {
            if (this.defaultStartDate) {
                localStorage["DEFAULT_START_DATE"] = this.defaultStartDate;
            }
        }
    },
    computed: {
        op() {
            return {
                type: "day",
                placeholder: "YYYY-MM-DD",
                week: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
                month: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                format: "YYYY-MM-DD"
            };
        },
        limit() {
            var unavailTime = [];
            unavailTime.push({
                type: "fromto",
                from: moment().add(0, "days")
            });
            return unavailTime;
        }
    },
    methods: {
        setDate(date) {
            this.defaultStartDate = date;
        },
        showDp() {
            this.$refs.dp.showCheck();
        }
    },
    created() {
        let defaultStartDate = localStorage["DEFAULT_START_DATE"];
        if (!moment(defaultStartDate).date() || moment(defaultStartDate) < moment()) {
            localStorage["DEFAULT_START_DATE"] = moment()
                .add(1, "day")
                .format("YYYY-MM-DD");
        }
        this.defaultStartDate = localStorage["DEFAULT_START_DATE"];
    }
};
</script>
<style lang="less">
.default-date-select {
    display: flex;
    align-items: center;
    margin-right: 20px;
    padding-left: 4px;
    .label {
        white-space: nowrap;
    }
    label {
        white-space: nowrap;
    }
    .md-input-container {
        margin-left: 12px;
        width: 65px;
    }
    .datepickbox input {
        font-weight: bold;
        font-size: 12px;
        cursor: pointer;
        // color: #5d5d5d;
        color: white;
        border-bottom: 0;
    }
}
@media (max-width: 600px) {
    .default-date-select {
        margin-right: 0;
        label {
            font-size: 12px;
        }
    }
}
.vendorMode {
    .datepickbox input {
        // color: #38f;
    }
}
</style>
