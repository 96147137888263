import Vue from "vue";
import Vuex from "vuex";
import moment from "moment-timezone";
import cart from "./cart";
import crud from "./crud";
import user from "./user";
import setting from "./setting";
import category from "./category";
import location from "./location";
import conversation from "./conversation";
import seo from "./seo";
import router from "@/router";
import { menuItems } from "@/config";

Vue.use(Vuex);

function checkWebp() {
    return true;

    let elem = document.createElement("canvas");
    if (!!(elem.getContext && elem.getContext("2d"))) {
        return elem.toDataURL("image/webp").indexOf("data:image/webp") == 0;
    } else {
        return false;
    }
}
const checkWebpResult = checkWebp();

export const store = new Vuex.Store({
    state: {
        loading: false,
        error: null,
        isError: null, // Only required to inform App.vue that an error is there hence initiate the snackbar
        clientWidth: 0,
        tz: localStorage["TZ"],
        vendorMode: "", // vendor mode,
        vendorModeLoaded: false,
        affiliateId: ""
    },
    actions: {
        async loadVendorMode({ state, getters, commit, dispatch }) {
            let vendor = window.location.href.match(/[?&]vendor=([^&#]*)/);
            vendor = vendor && vendor[1];
            if (vendor) {
                let result = await dispatch("crud/get", {
                    api: "users/vendor",
                    params: {
                        name: vendor
                    }
                });
                if (result) {
                    commit("setVendorMode", result);
                    if (result.locations && result.locations.length) {
                        await dispatch("location/loadLocations");
                        console.info("setvendor", result.locations[0]);
                        await dispatch("location/setLocation", result.locations[0]);
                    }
                } else {
                    commit("setVendorMode", "");
                    setTimeout(() => {
                        commit("setMessage", "Vendor Not Found");
                    }, 0);
                }
            } else {
                commit("setVendorMode", "");
            }
        },
        async loadAffiliate({ state, getters, commit, dispatch }) {
            let affiliateId = window.location.href.match(/[?&]affiliateId=([^&#]*)/);
            affiliateId = affiliateId && affiliateId[1];
            commit("setAffiliateId", affiliateId);
        }
    },
    getters: {
        loading(state) {
            return state.loading;
        },
        error(state) {
            return state.error;
        },
        getModel: () => (item, data) => {
            if (!data) {
                return null;
            }
            let result = item;
            if (typeof data === "function") {
                result = data(item);
            } else {
                let keyArr = data.split(".");
                while (keyArr.length) {
                    let key = keyArr.shift();
                    result = result && result[key];
                }
            }
            return result;
        },
        setModel: state => (item, data, value) => {
            let keyArr = data.split(".");
            let target = item;
            while (keyArr.length) {
                let key = keyArr.shift();
                if (keyArr.length == 0) {
                    // target[key] = value;
                    Vue.set(target, key, value);
                } else {
                    // target[key] = target[key] || {};
                    Vue.set(target, key, target[key] || {});
                    target = target[key];
                }
            }
        },
        parseLink: (state, getters) => (item, link) => {
            return link.replace(/\:([^:\/\?]*)/g, function (m, $1) {
                return getters.getModel(item, $1);
            });
        },
        supportWebp(state) {
            return checkWebpResult;
        },
        tz(state, getter) {
            return state.tz || "America/New_York";
        },
        menuItems(state, getters) {
            // let user = getters["user/user"];
            const cards = JSON.parse(JSON.stringify(menuItems));

            return cards;
        },
        vendorMode(state, getters) {
            if (state.affiliateId) {
                return state.affiliateId;
            }
            return state.vendorMode;
        },
        vendorModeLoaded(state) {
            return state.vendorModeLoaded;
        },
        affiliateId(state, getters) {
            return state.affiliateId;
        },
        env() {
            return process.env.NODE_ENV;
        }
    },
    mutations: {
        setLoading(state, payload) {
            state.loading = payload;
        },
        setSuccess(state, err) {
            state.isError = Date.now();
            state.error = err.message;
        },
        setError(state, err) {
            state.isError = Date.now();
            if (!err) return;
            // if (!navigator.onLine) {
            //   state.error = 'You are offline. Could not communicate to server'
            //   return
            // }
            state.loading = false;
            err = err.response || err;
            if (err.status === 403) {
                state.error = "Please login to continue";
                window.localStorage.removeItem("authUser");
            } else if (err.status === 404) {
                state.error = "The requested resource not found";
            } else if (err.status > 499) {
                state.error = "Server connection refused";
            } else if (err.statusText) {
                state.error = err.statusText;
            }
        },
        errWithMessage(state, err) {
            state.isError = Date.now();
            if (err) {
                err = err.message;
                state.loading = false;
            }
        },
        setMessage(state, msg) {
            state.isError = Date.now();
            state.loading = false;
            state.error = msg;
            console.log(msg);
        },
        clearError(state) {
            state.error = null;
        },
        setVendorMode(state, vendorMode) {
            // document.body.classList[vendorMode ? "add" : "remove"]("vendorMode");
            state.vendorMode = vendorMode;
            state.vendorModeLoaded = true;
        },
        setTZ(state, tz) {
            localStorage["TZ"] = tz;
            moment.tz.setDefault(tz);
            state.tz = tz;
        },
        postParentMessage(state, msg) {
            if (window.parent) {
                window.parent.postMessage(msg, "*");
            }
        },
        setAffiliateId(state, affiliateId) {
            state.affiliateId = affiliateId;
        }
    },
    modules: {
        cart,
        crud,
        user,
        category,
        seo,
        location,
        setting,
        conversation
    }
});

window.store = store;
