<template>
    <div class="cart-date-buttons" :class="mode">
        <div class="contain" v-if="!product.rental">
            <label for="">Delivery Date</label>
            <date-time-picker v-model="startDate" :limit="limit" mode="block"></date-time-picker>
        </div>
        <div class="contain" v-if="product.rental">
            <label for="">Start Date</label>
            <date-time-picker v-model="startDate" :limit="limit"></date-time-picker>
        </div>
        <div class="contain" v-if="product.rental">
            <label for="">End Date</label>
            <date-time-picker v-model="endDate" :limit="limit"></date-time-picker>
        </div>
        <Confirm ref="offHourConfirm"></Confirm>
    </div>
</template>

<script>
// hints :  date must be storage in string, becasue moment()!=moment(), it will raise infinite loop
// hints :  date must be YYYY-MM-DD HH:mm,  date-time-picker for detail
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
import DateTimePicker from "@/components/DateTimePicker";
import moment from "moment-timezone";
import Confirm from "@/components/modal/Confirm.vue";

export default {
    props: {
        product: { required: true },
        variant: { required: true },
        mode: { type: String, default: "product" }
    },
    components: {
        DateTimePicker,
        Confirm
    },
    data() {
        return {
            startDate: null,
            endDate: null,
            loaded: false
        };
    },
    watch: {
        variant(n, o) {
            if (n && o && n._id == o._id) {
                //not changed
            } else {
                this.setToCart();
            }
        },
        startDate(to, from) {
            this.setToCart();
        },
        endDate(to, from) {
            this.setToCart();
        }
        // itemStartDate() {
        //     this.startDate = this.itemStartDate;
        // },
        // itemEndDate() {
        //     this.endDate = this.itemEndDate;
        // }
    },
    computed: {
        ...mapGetters({
            getItem: "cart/getItem"
        }),
        // stored matched cart item
        item() {
            var item = this.getItem({ _id: this.product._id, vid: this.variant._id });
            return item;
        },
        // stored cart item start date
        itemStartDate() {
            return this.item && moment(this.item.rentalStartDate).format("YYYY-MM-DD HH:mm");
        },
        // stored cart item end date
        itemEndDate() {
            return this.item && moment(this.item.rentalEndDate).format("YYYY-MM-DD HH:mm");
        },

        // can not select future date + preparedays
        limit() {
            var unavailTime = [];
            unavailTime.push({
                type: "fromto",
                from: moment()
                    .startOf("day")
                    .add(1, "day")
                    .add(this.product.prepareDays || 0, "days")
            });
            return unavailTime;
        }
    },
    methods: {
        ...mapActions({
            addToCart: "cart/addToCart"
        }),
        async setToCart() {
            if (this.startDate && this.endDate) {
                //nothing change
                if (this.item && moment(this.item.rentalStartDate).diff(moment(this.startDate), "hour") == 0 && moment(this.item.rentalEndDate).diff(moment(this.endDate), "hour") == 0) {
                    return;
                }

                // show off hour confirm alert
                let showAlert = false;
                if (this.product.rental) {
                    let deliveryTime = +moment(this.startDate).format("HHmmss");
                    if (deliveryTime < 90000 || deliveryTime > 210000) {
                        showAlert = true;
                    }
                    let pickupTime = +moment(this.endDate).format("HHmmss");
                    if (pickupTime < 90000 || pickupTime > 210000) {
                        showAlert = true;
                    }
                }
                if (!this.product.rental) {
                    let deliveryTime = +moment(this.startDate).format("HHmmss");
                    if (deliveryTime < 90000 || deliveryTime > 210000) {
                        showAlert = true;
                    }
                }
                if (showAlert) {
                    this.$refs.offHourConfirm.process("Confirm", "By Selecting a time frame before 9am or after 9pm an additional off hour fee will apply.");
                }

                // add to cart
                console.info(this.mode, this.item, "setToCart", this.startDate, this.endDate);
                let quantity = this.item ? this.item.quantity : 0;
                let item = await this.addToCart({
                    product: this.product,
                    variant: this.variant,
                    quantity: quantity,
                    rentalStartDate: this.startDate,
                    rentalEndDate: this.endDate
                });
            }
        },
        setDefaultDate() {
            // limited date
            let limit = moment((this.limit[0] && this.limit[0].from) || undefined);
            // selected start date
            let selectedStartDate = moment(localStorage["DEFAULT_START_DATE"] || undefined);

            let startDate = moment
                .max([limit, selectedStartDate])
                .clone()
                .startOf("hour")
                .hour(9);
            let endDate = moment
                .max([limit, selectedStartDate])
                .clone()
                .startOf("hour")
                .hour(9);
            if (this.product.rentalInterval == "daily") {
                endDate.add(1, "day");
            }
            // let endDate = moment.max(limit, selectedStartDate).startOf("hour").add(1, this.product.rentalInterval == "hourly" ? "hour" : "day");;

            if (this.item) {
                if (moment(this.itemStartDate).diff(moment(startDate), "day") >= 0) {
                    startDate = this.itemStartDate;
                    endDate = this.itemEndDate;
                    console.info("using stored cart date");
                }
            }

            this.startDate = moment(startDate).format("YYYY-MM-DD HH:mm");
            this.endDate = moment(endDate).format("YYYY-MM-DD HH:mm");
            setTimeout(() => {
                this.loaded = true;
            }, 0);
        }
    },
    created() {
        // // set default date
        this.setDefaultDate();
        // // update expired cart date
        // this.item && this.setToCart();
    }
};
</script>
<style lang="less">
.cart-date-buttons {
    // padding:10px;
    max-width: 100%;
    margin-bottom: 10px;
    display: flex;
    .contain {
        flex: 1;
    }
    .date-time-picker {
        margin-right: 20px;
    }
    .md-input-container {
        min-height: 18px;
        margin: 0;
        padding: 0;
    }
}

.cart-date-buttons.cart {
    flex-wrap: wrap;
    margin-bottom: 0;
    label {
        color: #666;
        font-size: 12px;
    }
    .contain {
        width: 100%;
        margin-bottom: 7px;
    }
    .md-input-container {
        min-height: 19px;
        input {
            height: 16px;
        }
        .md-select {
            height: 16px;
        }
        .md-menu {
            height: 16px;
        }
        .md-select-value {
            height: 16px;
            line-height: 16px;
        }
    }
}
</style>
