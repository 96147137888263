<template>
    <div class="sw-register"></div>
</template>
<script>
export default {
    methods: {
        register() {
            let vm = this;

            if ("serviceWorker" in navigator) {
                navigator.serviceWorker.register("/service-worker.js");
            }
            window.addEventListener("beforeinstallprompt", async function(e) {
                // e.prompt && e.prompt();
                // vm.$store.commit("setMessage", "beforeinstallprompt fired");
                let choiceResult = await e.userChoice;
                if (choiceResult.outcome == "dismissed") {
                    console.log("User cancelled home screen install");
                } else {
                    console.log("User added to home screen");
                }
            });
        }
    },
    created() {
        this.register();
    }
};
</script>
