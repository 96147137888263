<template>
    <div :class="customClassName" class="common-edit">
        <form autocomplete="off" v-on:submit.prevent="save(newRecord)">
            <md-card>
                <md-card-header>
                    <slot :loading="loading" :model="newRecord" name="header">
                        <h2 v-if="title">
                            <span>{{ title }}</span>
                        </h2>
                        <h2 v-if="!title">
                            <span v-if="!id || id === 'new'">Add</span>
                            <span v-else>Edit</span>
                            <span>{{ api | titlecase }}</span>
                        </h2>
                    </slot>
                </md-card-header>
                <md-card-media style="text-align:center;min-height:200px" v-if="hasAvatar">
                    <img :src="avatar | googleimage" style="max-width:200px" />
                </md-card-media>
                <md-card-content>
                    <div class="edit-fields">
                        <template v-for="(f, ix) in usedfields">
                            <slot :f="f" :model="newRecord" :name="f.data">
                                <edit-field :f="f" :key="ix" :model="newRecord" ref="edit-field"></edit-field>
                            </slot>
                        </template>
                    </div>
                </md-card-content>
                <md-card-actions>
                    <slot :loading="loading" :model="newRecord" name="submit">
                        <submit :loading="loading" class="submitButton" icon="save" text="Submit"></submit>
                    </slot>
                </md-card-actions>
            </md-card>
        </form>
    </div>
</template>

<script>
import EditField from "@/components/EditField";
import Submit from "./Submit";
import { mapGetters } from "vuex";
export default {
    props: {
        fs: Array,
        id: { type: String, default: "new" },
        title: String,
        api: String,
        apiGet: String,
        apiUpdate: String,
        apiSave: String,
        customClassName: { type: String, default: "form" },
        customValidate: { type: Function, default: () => true }
    },
    components: {
        EditField,
        Submit
    },
    data() {
        return {
            loading: false,
            newRecord: {}
        };
    },
    computed: {
        ...mapGetters({
            getDefaultHead: "user/getDefaultHead",
            setModel: "setModel",
            getModel: "getModel"
        }),
        avatar() {
            let avatarField = this.hasAvatar;
            if (avatarField) {
                let avatar = this.newRecord[avatarField.data];
                if (avatar) {
                    return avatar;
                }
                if (this.newRecord.name) {
                    return this.getDefaultHead(this.newRecord.name);
                }
            }
            return null;
        },
        hasAvatar() {
            return this.usedfields.find(field => field.data == "avatar");
        },
        usedfields() {
            return this.fs.filter(field => !field.when || field.when == "edit");
        },
        parseApi() {
            return api => {
                let match = api.match(/(.*?):(get|post|put|delete)/);
                let url = (match && match[1]) || api;
                let option = match && match[2];
                return { url, option };
            };
        },
        _apiGet() {
            let url = this.apiSave || this.api;

            let result = this.parseApi(url);
            if (/:id/.test(result.url)) {
                result.url = result.url.replace(/:id/, this.id);
            } else {
                result.url = result.url + "/" + this.id;
            }
            result.option = result.option || "get";
            return result;
        },
        _apiUpdate() {
            let url = this.apiUpdate || this.api;
            let result = this.parseApi(url);
            if (/:id/.test(result.url)) {
                result.url = result.url.replace(/:id/, this.id);
            } else {
                result.url = result.url + "/" + this.id;
            }

            result.option = result.option || "put";
            return result;
        },
        _apiSave() {
            let url = this.apiSave || this.api;

            let result = this.parseApi(url);
            result.option = result.option || "post";

            return result;
        }
    },
    watch: {
        newRecord: {
            deep: true,
            handler(n, o) {
                this.$emit("changed", this.newRecord);
            }
        },
        fs: {
            deep: true,
            handler(a, b) {
                this.load();
            }
        }
    },
    methods: {
        async save(item) {
            let customValidateResult = false;
            let validateResults = [];
            customValidateResult = await this.customValidate();
            validateResults = await Promise.all(
                (this.$refs["edit-field"] || []).map(async comp => {
                    return await comp.$validator.validateAll();
                })
            );

            if (!customValidateResult || validateResults.findIndex(r => !r) > -1) {
                console.info(customValidateResult, validateResults);
                return;
            }
            if (this.id === "new" || !this.id) {
                delete item._id;
                let result = await this.$store.dispatch(`crud/${this._apiSave.option}`, {
                    api: this._apiSave.url,
                    data: item
                });
                this.$store.commit("setMessage", "Save Success");
                this.$emit("saved", result);
            } else {
                let result = await this.$store.dispatch(`crud/${this._apiUpdate.option}`, {
                    api: this._apiUpdate.url,
                    data: item
                });
                this.$store.commit("setMessage", "Save Success");
                this.$emit("saved", result);
            }
        },
        async setDefault() {
            this.usedfields.forEach(field => {
                if (field.default != void 0) {
                    let currentValue = this.getModel(this.newRecord, field.data);
                    this.setModel(this.newRecord, field.data, currentValue || JSON.parse(JSON.stringify(field.default)));
                    // if (currentValue != void 0) {
                    //     this.setModel(this.newRecord, field.data, currentValue);
                    // } else {
                    // }
                }
            });
        },
        async load() {
            if (this.id == "new" || !this.id) {
                this.newRecord = {};
            } else {
                this.newRecord = await this.$store.dispatch(`crud/${this._apiGet.option}`, {
                    api: this._apiGet.url
                });
            }
            this.setDefault();
        }
    },
    async created() {
        this.load();
    }
};
</script>
<style lang="less">
@import "../mixins.less";
.common-edit {
    &.form {
        .form;
    }
    &.form-small {
        .form-small;
    }
    &.form-default {
        .form-default;
    }
}
</style>
