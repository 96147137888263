<template>
    <ul class="admin-megamenu">
        <template v-for="(menu, ix) in menuItems">
            <li
                :class="{ active: activeName === menu.name }"
                :key="ix"
                @click="handleMenuTouch(menu.name)"
                v-if="(!menu.role || hasRole(menu.role)) && (!menu.asRole || asRole(menu.asRole)) && (!menu.permission || hasPermission(menu.permission))"
            >
                <a v-if="!menu.url">{{ menu.name }}</a>
                <router-link :to="menu.url" v-if="menu.url">{{ menu.name }}</router-link>
                <ul @click.stop="handleSubMegamenuTouch(menu.name)" class="sub-megamenu" v-if="menu.children">
                    <template v-for="(item, iix) in menu.children">
                        <li :key="iix" v-if="branchesMenuCheck(item) && (!item.role || hasRole(item.role)) && (!item.asRole || asRole(item.asRole))">
                            <router-link :to="item.url">
                                <md-icon>{{ item.icon }}</md-icon>
                                {{ item.name }}
                            </router-link>
                        </li>
                    </template>
                </ul>
            </li>
        </template>
        <li style="flex:1"></li>
        <li v-if="!vendorMode">
            <router-link to="/admin/versions">Versions</router-link>
        </li>
        <li v-if="hasRole('user')">
            <a @click="logout" href>
                Logout
            </a>
        </li>
    </ul>
</template>
<script>
import VersionMore from "@/components/Version/VersionMore";
import { mapGetters } from "vuex";
import { menuItems, userRoles } from "@/config";

export default {
    components: {
        VersionMore
    },
    data() {
        return {
            activeName: ""
        };
    },
    computed: {
        ...mapGetters({
            user: "user/user",
            hasRole: "user/hasRole",
            asRole: "user/asRole",
            hasPermission: "user/hasPermission",
            menuItems: "menuItems",
            vendorMode: "vendorMode"
        }),
        branchesMenuCheck() {
            return menu => {
                if (menu.name == "Manage Branches") {
                    if (this.user.role == "vendor" && this.user.master_vendor_id) {
                        return false;
                    }
                }
                return true;
            };
        }
    },
    methods: {
        logout(e) {
            e.preventDefault();
            this.$store.dispatch("user/logout");
        },
        handleMenuTouch(name) {
            this.activeName = "";
        },
        handleSubMegamenuTouch(name) {
            this.activeName = name;
        }
    },
    async created() {}
};
</script>
<style lang="less">
.admin-megamenu {
    z-index: 9;
    display: flex;
    height: 50px;
    margin: 0 auto;
    padding: 0;
    list-style: none;
    color: white;
    background: #0a0461;
    flex-wrap: nowrap;
    align-items: center;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 1px 1px rgba(0, 0, 0, 0.14), 0 1px 1px -2px rgba(0, 0, 0, 0.12);
    -webkit-overflow-scrolling: touch;

    li {
        flex-shrink: 0;
        margin-top: 0 !important;
        position: relative;
        cursor: pointer;
        display: inline-block;
        flex-shrink: 0;
        transition: background-color linear 0.1s;
        color: white;
        &.get-financing {
            margin-right: 50px;
        }
        &:hover {
            background: rgba(255, 255, 255, 0.2);
        }
        &:hover > a {
            text-decoration: none !important;
            color: white;
        }
        &:hover .sub-megamenu {
            display: block;
        }
        a {
            font-size: 1em;
            line-height: 40px;
            display: block;
            height: 40px;
            padding: 0 15px;
            text-decoration: none;
            transition: color linear 0.1s;
            white-space: nowrap;
            color: white;
        }
    }

    .sub-megamenu {
        position: absolute;
        z-index: 15;
        top: 100%;
        left: 0;
        display: none;
        width: 400px;
        height: auto;
        min-height: 200px;
        padding: 0;
        white-space: normal;
        // box-shadow: 0 0 1px rgba(0, 0, 0, 0.12);
        // border-top: 1px solid orange;
        // background: white;
        li {
            display: inline-flex;
            margin: 0;
            padding: 0 10px;
            background: white;
            // border-top: 1px solid orange;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            width: 200px;
            a {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                height: 45px;
                color: #666;
            }
            i {
                margin-right: 5px;
                cursor: pointer;
            }

            &:hover {
                // background: rgba(255, 165, 0, 0.8);
                background: rgba(153, 153, 153, 1);
                a {
                    color: white;
                }
                i {
                    color: white;
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .admin-megamenu {
        overflow-x: scroll;
        overflow-y: hidden;
        li {
            position: inherit;
        }

        &::-webkit-scrollbar {
            width: 4px;
            height: 5px;
            cursor: pointer;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background: rgba(0, 0, 0, 0.2);
            cursor: pointer;
        }
        &::-webkit-scrollbar-track {
            border-radius: 0;
            /* background: rgba(0, 0, 0, 0.1); */
            cursor: pointer;
        }

        .sub-megamenu {
            top: auto;
            left: 0;
            width: 100vw;
            box-shadow: 0 0 1px rgba(0, 0, 0, 0.12);
            border-top: 1px solid orange;
            background: white;
            min-height: inherit;
            li {
                width: 50vw;
                border-top: none;
                border-bottom: none;
                position: static;
            }
        }

        li.active:hover .sub-megamenu {
            display: none;
        }
    }
}

.vendorMode {
    .admin-megamenu {
        color: #38f;
        li {
            color: #38f;
            &:hover {
                background: white;
            }
            &:hover > a {
                color: #38f;
            }
            a {
                color: #38f;
            }
        }
        .sub-megamenu {
            li {
                a {
                    color: #38f;
                }
                &:hover {
                    background: white;
                    a,
                    i {
                        color: #38f;
                    }
                }
            }
        }
    }
}
</style>
