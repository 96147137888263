import { Client as ConversationsClient } from "@twilio/conversations";

const state = {
    client: null,
    token: "",
    identity: "",
    time: ""
};

const getters = {
    client(state, getters) {
        return state.client;
    },
    identity(state) {
        return state.identity;
    }
};

const actions = {
    async getToken({ dispatch, commit, state, rootState }) {
        let result = await dispatch(
            "crud/get",
            {
                api: "conversation/token"
            },
            { root: true }
        );
        commit("setToken", result.token);
        commit("setIdentity", result.identity);
        commit("setTime", +new Date());
    },
    async init({ dispatch, commit, state, rootState }, payload) {
        const conversationsClient = await ConversationsClient.create(state.token);
        commit("setClient", conversationsClient);
    },
    shutdown({ dispatch, commit, state, rootState }) {
        commit("setClient", null);
        commit("setToken", "");
        commit("setIdentity", "");
        commit("setTime", "");
        state.client && state.client.shutdown();
    },
    isExpired({ dispatch, commit, state, rootState }) {
        if (!state.time) return true;

        const now = +new Date();
        return now - state.time > 3600000;
    }
};

const mutations = {
    setToken(state, payload) {
        state.token = payload;
    },
    setIdentity(state, payload) {
        state.identity = payload;
    },
    setClient(state, payload) {
        state.client = payload;
    },
    setTime(state, payload) {
        state.time = payload;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
